import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import * as firebase from "firebase/app";
import { 
  RadioGroup, FormControlLabel, Radio, Grid, Container
} from '@material-ui/core';
import { Form, ListGroup, Jumbotron} from 'react-bootstrap'
import { H1, H2, P, Button2, BodyWrapper, ImageStyle, Centered, Input, Form2} from "../../containers";
import { contentClient, CONTENT } from "../../data/contentful";
import LaunchScreen from '../LaunchScreen';
import Auth from "../../services/authentication";
import './style.css';

export default function FriendshipQuestionPage() {
  const history = useHistory();
  const [relationType, setRelationType] = useState('');
  const [isSubmitted, setIsSubmit] = useState(false);
  const [connection, setConnection] = useState(null);

  const [contentfulValues, setContentfulValues] = useState({
    assessmentFriendshipQuestion: '',
    friendshipTypes: [],
    youWereInvitedBy: ''
  });

  const checkReferenceStatus = () => {
    Auth.checkReferralStatus(true).then(result => {
      if (result) {
        let connection = localStorage.getItem('referral_connection');
        if (connection) {
          setConnection(JSON.parse(connection));
        } else {
          history.push('/');
        }
      } else {
        history.push('/survey');
      }
    });
  }

  useEffect(() => {
    async function fetchData() {
      const { fields } = await contentClient.getEntry(CONTENT.INVITE_TO_COMPARE_MODULE.id);
      setContentfulValues(fields);
    }
    fetchData();
    checkReferenceStatus();
  }, []);

  const handleKeyDown = (event) => {
    console.log(event)
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      // callback();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (!relationType) {
      return;
    }
    const currentEmail = firebase.auth().currentUser?.email;
    const currentUID = firebase.auth().currentUser?.uid;
    // call API
    const db = firebase.firestore();
    if (connection.receiver == 'not_yet_generated') {
      let id = connection.sender + '_' + currentEmail;
      db.collection('connections').doc(id).delete();
    }

    const documentIdentifierOld = connection.sender + '_' + currentEmail;
    const documentIdentifier = connection.sender + '_' + currentUID;

    const docReffriendOld = db.collection("connections").doc(documentIdentifierOld);
    docReffriendOld.delete().then(res => {
      console.log('Old Temp Doc Deleted!', res);
    });

    const docReffriend = db.collection("connections").doc(documentIdentifier);
    const data = {
      ...connection,
      uid_group: [ connection.sender, currentUID],
      receiver: currentUID,
      receiver_relation: relationType,
      receiver_activated: 'true',
      status_receiver: 'Approved',
    };
    docReffriend.set(data, { merge: true })
    .then(() => {
      setIsSubmit(false);
      localStorage.removeItem("referral_connection")
      // localStorage.removeItem("referral")
      // go to assessment
      setRelationType('');
      history.push('/survey');
    })
  }

  const handleType = value => {
    setRelationType(value);
  }

  if (!connection) {
    return <LaunchScreen />
  }

  return (
    <Container spacing={2} maxWidth="md" className="friendship-question">
      <Grid item xs={12}>
        <div className="question-title">
          <H2 className="question-header">{contentfulValues.assessmentFriendshipQuestion}</H2>
        </div>
        <Form id="relation-form" onSubmit={handleSubmit} onKeyDown={e => { handleKeyDown(e) }}>
          <div className="question-body">
            <Form.Group>
              <RadioGroup
                onChange={(e) => handleType(e.target.value)}
                value={relationType}
                inputProps={{
                  required: true
                }} name="relationType">
                  {contentfulValues.friendshipTypes.map(x => 
                    <FormControlLabel value={x} control={<Radio color="primary"/>} label={x} />
                  )}
              </RadioGroup>
            </Form.Group>
            {isSubmitted && !relationType ? 
              <P fontSize={12} marginBottom={12} color={'red'}> Please select your relationship.</P>
            : null}
          </div>
          <div className="question-footer">
            <div>{contentfulValues.youWereInvitedBy} <span className="sender-email">{connection.sender_email}</span></div>
            <div className="btn-wrapper"><input type="submit" className="next_btn" /></div>
          </div>
        </Form>
      </Grid>
    </Container>
  )
}
