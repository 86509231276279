import React, { useEffect, useState, useContext } from 'react';
import VizulyRadarChart from "../vizuly/VizulyRadarChart";
import radarData from "../data/radarchart_data_simple";
import * as d3 from "d3";
import Spinner from 'react-spinner-material';
import { isMobile } from 'react-device-detect';

import * as firebase from "firebase/app";
import "../../../firebase";
import jsonQuery from "json-query";

import "../xylaradar.css";

import { XylaContext } from '../../../contexts/xylaContext';



export default function RadarChartTest() {

	
		const xylacontextdata = useContext(XylaContext);

		useEffect(() => {
			const db = firebase.firestore();
			const docRef = db.collection("survey_complete").doc(firebase.auth().currentUser.uid);
			docRef.get().then((doc) => {
				if (doc.exists) {
					let data = doc.data();
					var tempvar = jsonQuery('surveyscored', {data: data}).value
		
					xylacontextdata.sets1(((JSON.parse(tempvar)).s1[0]) + 1)
					xylacontextdata.sets2(((JSON.parse(tempvar)).s2[0]) + 1)
					xylacontextdata.sets3(((JSON.parse(tempvar)).s3[0]) + 1)
					xylacontextdata.sets4(((JSON.parse(tempvar)).s4[0]) + 1)
					xylacontextdata.sets5(((JSON.parse(tempvar)).s5[0]) + 1)
					xylacontextdata.sets6(((JSON.parse(tempvar)).s6[0]) + 1)
					xylacontextdata.sets7(((JSON.parse(tempvar)).s7[0]) + 1)
					xylacontextdata.sets8(((JSON.parse(tempvar)).s8[0]) + 1)
					xylacontextdata.sets9(((JSON.parse(tempvar)).s9[0]) + 1)
					xylacontextdata.sets10(((JSON.parse(tempvar)).s10[0]) + 1)
					xylacontextdata.sets11(((JSON.parse(tempvar)).s11[0]) + 1)
					xylacontextdata.sets12(((JSON.parse(tempvar)).s12[0]) + 1)
							
				} else {
					console.log("No such document!");
				}
			}).catch(function (error) {
				console.log("Error getting document:", error);

			});  
		
			const docRef2 = db.collection("users").doc(firebase.auth().currentUser.uid);
			docRef2.get().then((doc) => {
				if (doc.exists) {
					let data = doc.data();
					console.log("Document data for xylacolor:", data);
					var tempvarcolor = jsonQuery('xylacolor', {data: data}).value
					xylacontextdata.setxylacolor(tempvarcolor);
				} else {
					console.log("xyla user color does not exist!");
				}
			}).catch(function (error) {
				console.log("Error getting document:", error);
			});  
		}, []);


	const size = {
		width: 700,
		height: 700
	}

	if (isMobile) {
		size.width = '100%';
		size.height = 500;
	}
	return (
			<div className="Vizuly-container">
				{/* <div className="Vizuly-title">Radar Chart</div> */}
				<div className="Vizuly-component">
					<VizulyRadarChart
					   props={[
						   {'width': size.width},
						   {'height': size.height},
						   // {'data': radarData},
						  
						   {'data': 

							   [
								[
								{ "axis": "Xyla Category S1",  "value": xylacontextdata.s1, "category": "Feeling to Thinking" },
								{ "axis": "Xyla Category S2", "value": xylacontextdata.s2, "category": "Friendship Roots: "},
								{ "axis": "Xyla Category S3", "value": xylacontextdata.s3, "category": "Friendship Roots: "},
								{ "axis": "Xyla Category S4", "value": xylacontextdata.s4, "category": "Friendship Roots: "},
								{ "axis": "Xyla Category S5", "value": xylacontextdata.s5, "category": "Friendship Roots: "},
								{ "axis": "Xyla Category S6", "value": xylacontextdata.s6, "category": "Friendship Roots: "},
								{ "axis": "Xyla Category S7", "value": xylacontextdata.s7, "category": "Friendship Styles: "},
								{ "axis": "Xyla Category S8", "value": xylacontextdata.s8, "category": "Friendship Styles: "},
								{ "axis": "Xyla Category S9", "value": xylacontextdata.s9, "category": "Friendship Styles: "},
								{ "axis": "Xyla Category S10", "value": xylacontextdata.s10, "category": "Friendship Styles: "},
								{ "axis": "Xyla Category S11", "value": xylacontextdata.s11, "category": "Friendship Styles: "},
								{ "axis": "Xyla Category S12", "value": xylacontextdata.s12, "category": "Friendship Styles: "}
								]	
							],
							
				    
						   },
						   {'y': function (d,i) { return d.value; }},
						   {'x': function (d,i) { return String(d.axis).toUpperCase() }},
						   {'seriesLabel': function (d,i) { return d.category; }},
						   {'yTickFormat': function (d) { return d3.format('.0f')(d*100) + '%';}}

					   ]}
					   styles={[
						   {'background-opacity': '0',},
						   {'area-fill': xylacontextdata.xylacolor},
						   {'line-stroke': xylacontextdata.xylacolor},

						]}
					//    events={[
					// 	   {'mouseover': function () { console.log('RadarChart.onMouseOver')}},
					// 	   {'mouseout': function () { console.log('RadarChart.onMouseOut')}}
					//    ]}
					//    watchers={[
					// 	   {'height': function () { console.log('RadarChart.onChange - height')}},
					// 	   {'data': function () { console.log('RadarChart.onChange - data')}}
					//    ]}
					/>
				</div>
			</div>
		   );
		
};


