import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';





class ElementsCustom3 extends Component {

  render() {
    return (
    <div className="Styled-my-2"   >
      <Typography variant="h4" gutterBottom={true} align='center' > {this.props.messageA} </Typography>
    </div>
    );
}
};

export default ElementsCustom3;
