import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import './friendfinder.css';

import * as firebase from "firebase/app";
import "../../firebase";

import FriendTable from "../friendTable/friendTable3";

import StickyFooter from "../Footer/Footer";


import { Grid, Button, TextField } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MessageIcon from '@material-ui/icons/Message';
import ListIcon from '@material-ui/icons/List';

// Example taken from https://github.com/PrestonElliott/React-Hooks/blob/master/react-hooks/src/Components/Search/search.jsx



export default function Search() {

    // SET INITIAL STATE FOR query AND jokes
    // CREATE REF FOR SEARCH INPUT
    const [query, setQuery] = useState('')
    const [friend, setFriend] = useState('')
    const [result, setResults] = useState([])
    const focusSearch = useRef(null)

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(true)

    const handleKeyDown = (event, callback) => {
        if (event.key === 'Enter' && event.shiftKey === false) {
          event.preventDefault();
          callback();
        }
      };

    const testFunction = () => {
        console.log('email/user to be linked via our backend process: ', query)
      }

    const invitenewFriend = () => {
        console.log('value to be submitted to INVITE page: ', query)
        let link1 = `/invite?friendemail=` + query
        window.location.href = link1;
      }

    const gotoFriendpage = () => {
        let link1 = `/friends` 
        window.location.href = link1;
      }

    // useEffect - FOCUS ON SEARCH INPUT
    useEffect(() => {focusSearch.current.focus()}, [])


    const db3 = firebase.firestore();

    const getResults = async (query) => {
        const results = await db3.doc(`searchUsers/${query}`).get()
        
        const userExists = results.exists.toString();

        const receiverUID = db3.doc(`searchUsers/${query}`)
        receiverUID.get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                const friendTemp = doc.data()
                const friend = friendTemp
                console.log('friendID: ', friend)
                setFriend(friend)
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
            return friend;
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

        console.log('doesUserexist: ', userExists)
        const userExists2 = {query, userExists}
        console.log('response :', userExists2)

        return userExists2

    } 

    // this is a good supporing doc -> https://firebase.googleblog.com/2018/08/better-arrays-in-cloud-firestore.html

    const firestoreSEND = async () => {
        
        const senderUID = firebase.auth().currentUser.uid;
        const senderEmail = firebase.auth().currentUser.email;

        console.log('senderUID = ', senderUID)
        console.log('receiverUID = ', friend.uid)

        //CHECK FOR FORMAT 1 - SENDER to RECEIVER 
        const requestAformat = senderUID + '_' + friend.uid
        const resultsA = await db3.doc(`friends/${requestAformat}`).get()
        const checkA = resultsA.exists.toString();
        console.log('checkA processed - Sender to Receiver:', checkA);

        //CHECK FOR FORMAT 2 - RECEIVER TO SENDER 
        const requestBformat = friend.uid + '_' + senderUID
        const resultsB = await db3.doc(`friends/${requestBformat}`).get()
        const checkB = resultsB.exists.toString();
        console.log('checkB processed - Receiver to Sender:', checkB);

        const timestamp = Date.now();
        const timestamp2 = Date().toLocaleString();

        if (checkA == 'false' & checkB == 'false') {
            db3.collection("friends")  
            .doc(requestAformat)
            .set(
                {
                    sender: senderUID,
                    sender_email: senderEmail,
                    receiver: friend.uid,
                    receiver_email: friend.email,
                    uid_group: [senderUID, friend.uid],
                    status: 'Processing',
                    status_receiver: 'Processing',
                    status_sender: 'Approved',
                    timestamp_computer: timestamp,
                    timestamp_human: timestamp2,
                },
                { merge: true }
                )
                .then(console.log('🔥 FRIEND REQUEST SUCCESFULLY STORED🔥'))
        } else if (checkA == 'false' & checkB == 'true')  {
            alert("🔥 Looks like that user has already sent you a friend request 🔥")
        } else if (checkA == 'true' & checkB == 'false' )  {
            alert("🔥 Looks like you have already sent a friend request to that user 🔥")
        } else {
            console.log('🔥 we have not yet configured this scenario.... 🔥')
            alert("🔥 scenario not yet configured 🔥")
        }
      
  

        // const results = await db3.doc(`friends/${query}`).get()
        // const userExists = results.exists.toString();
        // console.log('doesUserexist: ', userExists)
        // const userExists2 = {query, userExists}
        // console.log('response :', userExists2)
        
        return 'its all good in the hood'
    } 


    // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    // useEffect - ONLY RERENDERS WHEN query IS CHANGED
    useEffect(() => {
        let currentQuery = true
        const controller = new AbortController()

        const loadResults = async () => {
            if (!query) return setResults([])
            await sleep(350) 
            if (currentQuery) {
                const result = await getResults(query, controller)
                setResults(result)
            }
        }

        loadResults()

        return () => {
            currentQuery = false
            controller.abort()
        }
    }, [query])


    const FriendRequesttoUser = () => (
        <div>
            <Grid container justify="center">
                <Button variant="contained" onClick={firestoreSEND} endIcon={<CloudUploadIcon />}> Submit Friend Request to Xyla User</Button> 
            </Grid>
        </div>
    )

    const InviteFriendtoXyla = () => (
        <div>
           <Grid container justify="center">
            <Button variant="contained" onClick={invitenewFriend} endIcon={<MessageIcon />}> User does not exist, please invite them to join first!</Button>        
            </Grid>
        </div>
    )

    const FriendButtonDisplay = () => {
        if (result.userExists == 'false' | result.query == userEmail) {
            return (
                InviteFriendtoXyla()
            )
        } else if (result.userExists == 'true') {
            return (
                FriendRequesttoUser()
            )
        } else if (result.length == 0) {
            return (
                <p> </p>
            )
        } 
    }

    const userEmail = firebase.auth().currentUser.email;


    // RENDER COMPONENT
    return (
        <>

                <Form id="search-form" onKeyDown={e => { handleKeyDown(e, testFunction) }}>


                    <Form.Group>
                         {/* <h1> Search Users </h1>  */}
                        <Form.Control 
                                type="email" 
                                placeholder="name@example.com" 
                                ref={focusSearch}
                                onChange={(e) => setQuery(e.target.value)}
                                id="search-input"
                        />
                        <br />
                        <Form.Text className="text-muted">
                        Please enter the email of the existing Xyla user you wish to connect with.
                        </Form.Text>
                    </Form.Group>

                    {/* <div>
                        <p> <strong> The user queried: </strong>  {result.query} </p> 
                        <p> <strong> Does the user exists in system: </strong> {result.userExists} </p> 
                    </div> */}

                    <br />
                    <br />
                    {/* type="submit" */}

                    {/* <Button variant="contained" disabled={result.userExists != 'true' | result.query == userEmail} onClick={firestoreSEND} endIcon={<CloudUploadIcon />}> Submit Friend Request</Button> 
                    <br />  
                    <br />
                    <Button variant="contained" disabled={result.userExists != 'false'} onClick={invitenewFriend} endIcon={<MessageIcon />}> Invite Friend to Xyla</Button>
                    <br />
                    <br />
                    <Button variant="contained" color="primary" onClick={gotoFriendpage} endIcon={<ListIcon />} > See your existing friends</Button> */}

                
                    
                </Form>     


            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: '-2em'}}>
                        <FriendButtonDisplay />
            </div>    


            {/* <div id="search-form" style={{marginTop:'2em', marginBottom:'-2em'}}>
                <h1> Friends </h1> 
            </div> */}

            <FriendTable />

            <StickyFooter />

        </>
    )
}