import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import SmsIcon from '@material-ui/icons/Sms';

import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as firebase from "firebase/app";
import "../../firebase";

import {
  makeStyles,
  createTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";


const useStyles = makeStyles({
  button: {
    textTransform: "none",
    fontFamily: 'Montserrat',
  }
});

const defaultTheme = createTheme();
const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
      fontFamily: 'Montserrat',
    }
  }
});



//
// NEED TO INSERT SIMPLE MODAL WINDOW / DIALOGUE 
// https://daveceddia.com/open-modal-in-react/
//




class ShareButton extends Component {

  constructor(props) {
    super(props);
  }




  handleClick = () => {
    const urlreferraltext = '/?referralcode=' + firebase.auth().currentUser.uid;
    const urlreferralmessage = process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
    console.log('this is a click');
    //the below navigator piece is for the mobile sharing options with text/sms/slack/etc...
    if (navigator.share) {
      navigator.share({
        title: 'Xyla Friends',
        text: 'Join me on Xyla to learn about why friends are friends!',
        url: urlreferraltext
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(err => {
        console.log(`Couldn't share because of`, err.message);
      });
    } else {
      alert(`Your Xyla sharing link is copied to your clipboard!`);
      console.log('web share not supported');
      

    }
  };





  render() {


    const urlreferral = process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;


    return (
      <div>
      {/* <div> <EmptyState image={<CabinIllustration />} size="medium" /> </div> */}
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', paddingTop: "5px", }}> 
        <MuiThemeProvider theme={defaultTheme}>
            <MuiThemeProvider theme={theme}>
            <CopyToClipboard text={urlreferral}>
              <Button
                    style={{ borderRadius: 50, background: 'linear-gradient(180deg, #4191F1 0%, #812CC9 100%)', color: 'white', height: 48}}
                    onClick={this.handleClick}
                    variant="contained"
                    color="primary"
                    size="large"  
                  >
                  {this.props.title}
                </Button>

                      



              </CopyToClipboard>
            </MuiThemeProvider>
        </MuiThemeProvider>  

        </div>

      </div>


    );

  }
}



export default ShareButton;