import React, { Component }  from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledSummary = withStyles((theme) => ({
  content: {
    // justifyContent: 'center'
  }
}))(AccordionSummary);

class AccoridianXyla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      // divStyle: {
      //   root: {
      //       width: '100%',
      //     },
      //     heading: {
      //       fontSize: typography.pxToRem(15),
      //       fontWeight: typography.fontWeightRegular,
      //     },
      // }
    };
  }

  render() {
    return (
      <div>
        <Accordion>
          <StyledSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1"> {this.props.accordian1} </Typography>
          </StyledSummary>
          <AccordionDetails>
            <Typography variant="p" align='left'>
              {this.props.accordian1details}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <StyledSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body1" align='left'> {this.props.accordian2} </Typography>
          </StyledSummary>
          <AccordionDetails>
            <Typography variant="p" align='left'>
            {this.props.accordian2details}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <StyledSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body1" align='left'> {this.props.accordian3} </Typography>
          </StyledSummary>
          <AccordionDetails>
            <Typography variant="p" align='left'>
            {this.props.accordian3details}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <StyledSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body1" align='left'> {this.props.accordian4} </Typography>
          </StyledSummary>
          <AccordionDetails>
            <Typography variant="p" align='left'>
            {this.props.accordian4details}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <StyledSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body1" align='left'> {this.props.accordian5} </Typography>
          </StyledSummary>
          <AccordionDetails>
            <Typography variant="p" align='left'>
            {this.props.accordian5details}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <StyledSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body1" align='left'> {this.props.accordian6} </Typography>
          </StyledSummary>
          <AccordionDetails>
            <Typography variant="p" align='left'>
            {this.props.accordian6details}
            </Typography>
          </AccordionDetails>
        </Accordion>
    
      </div>
    );
  }
}


export default AccoridianXyla;