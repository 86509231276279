import React from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import "./index.css";
import App from "./components/App";
// import { debugContextDevtool } from 'react-context-devtool';
import {XylaProvider} from "../src/contexts/xylaContext";

const container = document.getElementById("root");

ReactDOM.render(
    <XylaProvider> 
        <App />
    </XylaProvider>, 
    container);

// serviceWorker.register();
// debugContextDevtool(container);
