import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as firebase from "firebase/app";
import "../../firebase";

import { H1, H2, P, BodyWrapper, ImageStyle, ImageStyle2, Centered } from "../../containers";

// import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from "react-recaptcha";
import * as yup from 'yup';

import './Waitlist.css';
import '../InviteFriend/invitefriend.css';


const homelink = "Home >"
const waitlink = "Back >"

class WaitlistFormik extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          name: null,
          lastName: null,
          email: null,
          error: null,
          loading: null,
          recaptcha: null,
        };

        this.first = this.first.bind(this);
        this.second = this.second.bind(this);
        this.third = this.third.bind(this);
        this.forth = this.forth.bind(this);

      }

      componentDidMount() {
        const script = document.createElement("script");
        script.src =
          "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
      
    
    onCaptchaChange(value) {
        console.log("Captcha value:", value);
      }

 

    mySubmitHandler2 = (values) => {
            this.setState({
                name: values.name,
                email: values.email,
                recaptcha: values.recaptcha

            })
            this.first()
            }
      

    refreshPage = () => { 
        window.location.reload(); 
      }

    backtotop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }



    first = () => {
        this.backtotop()
        this.setState({loading: 'part1done'})
        const waitlistFirstname = this.state.name
        const waitlistEmail = this.state.email
        console.log("FirstnameSTATE= " + waitlistFirstname)
        console.log("EmailSTATE= " + waitlistEmail)
        
        setTimeout(()=> {
            const timestamp = Date.now();
            const timestamp2 = Date().toLocaleString();
            const db = firebase.firestore();
        
            db.collection("waitlist")  
                .doc("waitlist")
                .set(
                {
                    [timestamp] : 
                    {
                        waitlist_firstname: waitlistFirstname,
                        // waitlist_lastname: waitlistLastname,
                        waitlist_email: waitlistEmail,
                        readable_timestamp: timestamp2,
                    }
                },
                { merge: true }
                )
                .then(console.log('🔥SUCCESSFUL SUBMISSION TO EMAIL_SHARES FIRESTORE 🔥'))

            this.setState({loading: null})
            this.second()
            }, 1500)
        }



        second = () => {
            this.setState({loading: 'part2done'})
            setTimeout(()=> {
                  console.log("its at part 2.2 - firebase backup is starting up");
                  console.log("Second");
                  const waitlistFirstname2 = this.state.name
                  const waitlistEmail2 = this.state.email
                    fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/contacts10k?email=${waitlistEmail2}&first_name=${waitlistFirstname2}&last_name=${waitlistFirstname2}`,
                        {method: "put"}).catch(err => console.error(err))
                    console.log("Completed push to sendgrid!")
                    console.log("Succesfully cleared form")
                    this.setState({loading: null})
                    this.third()
              }, 1500)
            }



        third = () => {
                this.setState({loading: 'part3done'})
                setTimeout(()=> {
                      console.log("its at part 3.3 - firebase backup is starting up");
                      console.log("Third");
                      this.setState({loading: null})
                  }, 15000000)
                }
                
        
        forth = () => {
                  this.setState({loading: 'error'})
                  setTimeout(()=> {
                        console.log("Error");
                        console.log("Forth");
                        this.setState({loading: null})
                    }, 10000)
                  }           

        fifth = () => {
                    this.setState({loading: 'error_email'})
                    setTimeout(()=> {
                          console.log("Error");
                          console.log("Fifth");
                          this.setState({loading: null})
                      }, 10000)
                    }     

  
    
    
    render() {


      const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        
        if (this.state.loading === 'part1done' || this.state.loading ==='part2done') {
            return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #4191F1 0%, #812CC9 100%)', color: 'white'}}> <BodyWrapper> <H1> <div style={{textAlign: "center"}}> Hang on... </div> </H1> </BodyWrapper> </div>
                }


  
        if (this.state.loading === 'part3done') {
        return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)',  color: 'white'}}> 
                <div className="specialform2">
                    <H1> <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> Thank you.</div></H1>
                    <P> 
                        <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> 
                            We’ve added you to our wait list and will reach out as soon as a slot opens up. We look forward to having you as part of our journey. <br /> <br /> 
                                    <a href='https://www.xylafriends.com' style={{ color: 'inherit', textDecoration: 'inherit'}}> {homelink}  
                                    </a>  
                        </div>
                    </P> 
                       </div>
                </div>                   
                }

        if (this.state.loading === 'error') {
        return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)',  color: 'white'}}> 
        <div className="specialform2">
                    <H1> <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> Please try again</div></H1>
                    <P> 
                        <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> 
                                You must enter a valid email address to be added to the wait list.  <br /> <br />   <a href='https://app.xylafriends.com/waitlist' style={{ color: 'inherit', textDecoration: 'inherit'}}> {waitlink}  
                                    </a>                                       
                        </div>
                    </P> 
                    </div>
                </div>                                                  
                }

        if (this.state.loading === 'error_email') {
        return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)', color: 'white'}}> 
        <div className="specialform2">
                    <H1> <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> Please try again</div></H1>
                    <P> 
                        <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> 
                                You must enter a valid email address to be added to the wait list.  <br /> <br />  <a href='https://app.xylafriends.com/waitlist' style={{ color: 'inherit', textDecoration: 'inherit'}}> {waitlink}  
                                    </a>                                            
                        </div>
                    </P> 
                </div>
                </div>     
                    }


      
      
      return (

        <div>
       
          <P> <div style={{textAlign: 'center' , display: 'flex', margin: "0 15%", paddingTop: '5%' }}> 
          Thank you for your curiosity about personality and friendship. Due to current limitations, we’ve started a wait list. 
          To take the assessment, please sign up. We’ll notify you when a spot opens up.
            </div> 
            </P>


        <div className="specialform">
              <Formik
                initialValues={{ email: "", name: "", recaptcha: "", }}

                validationSchema={yup.object().shape({
                    // name: yup.string().required(),
                    email: yup
                      .string()
                      .email()
                      .required(),
                    // recaptcha: yup.string().required()
                  })}

                onSubmit={(values) => {
                      this.mySubmitHandler2(values);
                      
                  }}
              >
                {({ values, touched, errors, isSubmitting, handleSubmit, setFieldValue}) => (


                  <Form onSubmit={handleSubmit}>
         
                    <div className="specialform">
                        <br />
                      <label htmlFor="name" className="speciallabel">Your first name (optional)</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        className={`specialinput ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>
                    
                    <div className="specialform">
                      <label htmlFor="email" className="speciallabel">Email</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className={`specialinput ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="specialform">
                        <label htmlFor="recaptcha" className="speciallabel">Recaptcha Validation</label>
                        <Recaptcha
                            sitekey="6LcnjNkZAAAAACI6rnDAPpUZ5CCBlOe17ZiD_c1y"
                            verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                        />
                        {errors.recaptcha 
                            && touched.recaptcha && (
                         <p>{errors.recaptcha}</p>
                )}
                    </div>

                    <button
                      type="submit"
                      className="specialinput"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Join wait list"}
                    </button>
                  </Form>
                )}
              </Formik>
          </div>


    </div>

      );
    }
  }

export default WaitlistFormik;