import React, { Component } from "react";
import * as firebase from "firebase/app";
import "../../firebase";
import StickyFooter from "../Footer/Footer";
import { H1, H2, P } from "../../containers";
import Grid from '@material-ui/core/Grid';
import { SetSurveyState } from '../../services/localState';
import Header3 from '../headers/Header3';
import StructureDiv from '../__structures/StructureDiv';
import ElementsCustom2 from '../../components-custom/ElementsCustom2';
import ElementsCustom3 from '../../components-custom/ElementsCustom3';
import XylaAddFriend from "../xylaAddFriend/xylaAddFriend2.js";
import Image1 from '../../illustrations/300_300_xylagraph.svg';
import XylaAccordian from "../XylaResults/XylaResultsAccordian";
import { contentClient } from "../../data/contentful";
import FeedbackModal from "../Feedback/FeedbackModal";

const finishlink = "Finish assessment >"

class XylaResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      xylauserdata: "",
      xylaquiz: "",
      loading: "",
      contentfulresponse: [],
      altModalOpen: false,
      user: {}
    }
  }

  componentDidMount = () => {
    contentClient.getEntries({
      content_type: "resultsPage",
    }).then(json => {
      this.setState({
        contentfulresponse: json.items[0].fields
      });
    })
  }

  GettingUserData = () => {
    const db2 = firebase.firestore();

    const docRef = db2.collection("users").doc(firebase.auth().currentUser.uid);
    docRef.get()
    .then((doc) => {
      if (doc.exists) {
        let data = doc.data();
        console.log("Document User data:", data);
        this.setState({
          xylauserdata: "Loaded",
          user: data
        });
        const docRef2 = db2.collection("survey_complete").doc(firebase.auth().currentUser.uid);
        return docRef2.get()
      } else {
        console.log("Not yet connected to Xyla member data...");
      }
    })
    .then((doc) => {
      if (doc.exists) {
        SetSurveyState('COMPLETE');
        let altModalOpen = false;
        if (!this.state.user.alt_invite_sent) {
          altModalOpen = true;
        }
        this.setState({
          xylaquiz: "completed",
          altModalOpen
        });
        if (altModalOpen) {
          this.onAltModalSubmit();
        }
        console.log("xyla survey completed")
      } else {
        SetSurveyState('NOT_COMPLETE');
        this.setState({
          xylaquiz: "notcompleted"
        })
        console.log("xyla survey not completed")
      }
    })
  };

  onAltModalSubmit = () => {
    const db2 = firebase.firestore();

    const docRef = db2.collection("users").doc(firebase.auth().currentUser.uid);
    docRef.set({
      alt_invite_sent: true
    }, {
      merge: true
    });
  }

  render() {
    const { contentfulresponse, altModalOpen } = this.state

    if (this.state.xylauserdata == "") {
      this.GettingUserData();
    }

    if (this.state.xylauserdata == "Loaded" && this.state.xylaquiz =="notcompleted" ) {
      return (
        <div>
          <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)',  color: 'white'}}> 
            <div className="specialform2">
              <H1> <div style={{textAlign: "center", color: "white", textDecoration: "none"}}> Psst...</div></H1>
              <P> 
                <div style={{textAlign: "center", color: "white", textDecoration: "none", minWidth: 280, maxWidth: '30%', margin: 'auto', lineHeight: '200%'}}> 
                  Your XylaGraph will be ready as soon as you complete the assessment. We're excited for you to see it! 
                  <br /> 
                  <br /> 
                  <a href='/survey' style={{ color: 'white', textDecoration: 'inherit'}}> {finishlink} </a>  
                </div>
              </P> 
            </div>
          </div>  
          <StickyFooter />
        </div>
      )
    }

    return (
      <React.Fragment>

        <XylaAddFriend isAlt={true} noButton={true} open={altModalOpen}/>

        <div style={{ marginTop: '3em' }}> 
          <div>
            <div> 
              <div className="specialform2">
                <H1 center> 
                  {contentfulresponse.header1}
                </H1>
              </div>
            </div>  
          </div>
          <Header3
            content={{
              header: contentfulresponse.header2,
              description: contentfulresponse.text1}}
          />
          <div>
            <div className="container" style={{paddingTop: 0}}> 
              <div className="specialform3" style={{marginTop: 0}}>
                <P>
                  {contentfulresponse.text1longA}
                </P>
                <P>
                  {contentfulresponse.closingParagraph2New}&nbsp;
                  <a href="/compare">{contentfulresponse.ctaHyperlink2} </a> 
                </P>
              </div>
            </div>  
          </div>
        </div>

        <StructureDiv
          bucket1={[
            <ElementsCustom2 />,
            <ElementsCustom3 />,
            <div> 
              <div className="container" style={{color: 'black', marginTop: '-7em'}}> 
                <div className="specialform3">
                  <H2 center size={28} weight={500}>
                    {contentfulresponse.bottomHeader1} 
                  </H2>
                  <br />
                  <P> 
                    {contentfulresponse.bottomBody1} 
                  </P>
                </div>
              </div>  
            </div>,
            // <div style={{alignItems:'center', justifyContent:'center', display: 'flex', marginTop: '3em', marginBottom: '3em'}}>
            //   <img src={ImageKey} alt='logo' style={{width:'43vh'}} />
            // </div>,
            <div> 
              <Grid item xs={6} alignContent={'center'} style={{ margin:'auto'}}>
                <div>
                  <H2 center weight={400} color={'#812cc9'}> 
                    {contentfulresponse.listTitle1} 
                  </H2>
                  <XylaAccordian 
                    accordian1={contentfulresponse.list1a}
                    accordian2={contentfulresponse.list1b}
                    accordian3={contentfulresponse.list1c}
                    accordian4={contentfulresponse.list1d}
                    accordian5={contentfulresponse.list1e}
                    accordian6={contentfulresponse.list1f}
                    
                    accordian1details={contentfulresponse.list1adetails}
                    accordian2details={contentfulresponse.list1bdetails}
                    accordian3details={contentfulresponse.list1cdetails}
                    accordian4details={contentfulresponse.list1ddetails}
                    accordian5details={contentfulresponse.list1edetails}
                    accordian6details={contentfulresponse.list1fdetails}
                  />
                </div>
                <br />
                <div>
                  <H2 center weight={400} color={'#ff6715'}> 
                    {contentfulresponse.listTitle2} 
                  </H2>
                  <XylaAccordian 
                    accordian1={contentfulresponse.list2a}
                    accordian2={contentfulresponse.list2b}
                    accordian3={contentfulresponse.list2c}
                    accordian4={contentfulresponse.list2d}
                    accordian5={contentfulresponse.list2e}
                    accordian6={contentfulresponse.list2f}    

                    accordian1details={contentfulresponse.list2adetails}
                    accordian2details={contentfulresponse.list2bdetails}
                    accordian3details={contentfulresponse.list2cdetails}
                    accordian4details={contentfulresponse.list2ddetails}
                    accordian5details={contentfulresponse.list2edetails}
                    accordian6details={contentfulresponse.list2fdetails}
                  />
                </div>
              </Grid>
            </div>
          ]}
        />
        <div> 
          <div className="container"> 
            <div className="specialform3">
              <P> 
                {contentfulresponse.closingParagraph} 
                <XylaAddFriend 
                  Button={<span className="link link-launch">{contentfulresponse.ctaHyperlink1}</span>} 
                />
              </P> 
              <P> 
                {contentfulresponse.closingParagraph3} 
              </P> 
              <P> 
                <div>{contentfulresponse.sIgnature1}</div>
                <div>{contentfulresponse.signature2}</div>
              </P>
              <FeedbackModal page="result" />
            </div>
          </div>  
        </div>
        <StickyFooter />
      </React.Fragment>
    );
  }
}

export default XylaResults;

