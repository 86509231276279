import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
    <div className="Styled-my-4">
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
        </Grid>
        <Grid item xs={12} lg={4}>
          <hr />
        </Grid>
        <Grid item xs={12} lg={4}>
        </Grid>
      </Grid>
    </div>
  );
}