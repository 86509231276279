import React from "react";
import * as firebase from "firebase/app";
import "../../firebase";
import axios from 'axios';

import * as Survey from "survey-react";
import "survey-react/survey.css";
import './surveyJS.css';
import surveyQuestions from './surveyquestions';

// import { BodyWrapper } from "../components";
import jsonQuery from "json-query";
import initCustomSlider from "../survey/slider.js";

import {
    H1,
    H2,
    P,
    BodyWrapper,
    BodyWrapper2,
    ImageStyle,
    Centered
} from "../../containers";

import StickyFooter from "../../components/Footer/Footer";

import MetaData from "../../metadata.json";
import {useHistory} from "react-router-dom";
import ReferralFriendRequest from "../../components/ReferralCapture/ReferralFriendRequest2";
import Auth from "../authentication";
import LaunchScreen from "../../components/LaunchScreen";

const timestamp = Date.now();

function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
        end = new Date().getTime();
    }
}

const SimpleSurvey = () => {
    const history = useHistory();
    const [loading,
        setLoading] = React.useState(true);

    React.useEffect(() => {
        Auth
            .checkReferralStatus()
            .then(result => {
                if (result) {
                    history.push('/friendship');
                } else {
                    Auth
                        .checkSurveyStatus()
                        .then(result => {
                            if (result) {
                                history.push('/');
                            } else {
                                setLoading(false);
                            }
                        });
                }
            })
        Survey.surveyLocalization.locales[Survey.surveyLocalization.defaultLocale].requiredError = "Please respond to continue.";
    }, []);

    initCustomSlider(Survey);
    const json = surveyQuestions;
    var survey = new Survey.Model(json);

    const surveyversion = JSON.parse(JSON.stringify(MetaData));
    const surveyversion_name = ('Build Version ' + surveyversion.buildMajor + '.' + surveyversion.buildMinor + '.' + surveyversion.buildRevision + ' ' + surveyversion.buildTag)

    if (window.localStorage.surveyJSON) {
        let _survey = JSON.parse(window.localStorage.surveyJSON);
        survey.currentPageNo = Number(_survey.currentPageNo);
        survey.data = _survey.data;
    } else {
        survey.currentPageNo = JSON.parse(localStorage.getItem('temppagenumber'));
        survey.data = JSON.parse(localStorage.getItem('tempdata'));
    }

    console.log('PRELOADED DATA', survey.data)
    console.log('PRELOADED PAGE', survey.currentPageNo)

    const customCSSclass = (survey, options) => {

        var classes = options.cssClasses

        classes.mainRoot += " sv_qstn";
        classes.root = "sq-root";
        classes.title += " sq-title"
        classes.item += " sq-item";
        classes.label += " sq-label";

        if (options.question.isRequired) {
            classes.title += " sq-title-required";
            classes.root += " sq-root-required";
        }

        if (options.question.getType() === "checkbox") {
            classes.root += " sq-root-cb";
        }

    }

    const onCurrentPageChanged = survey => {
        document
           .querySelector('#surveyResult')
           .innerHTML = `${survey.currentPageNo+1} / ${survey.pageCount}`;
    }

    const onValueChanged = (survey) => {
        var res = {
            currentPageNo: survey.currentPageNo,
            data: survey.data
        };
        // setCurrentPageNo(survey.currentPageNo)
        window
            .localStorage
            .setItem('surveyJSON', JSON.stringify(res));
        const timestamp = Date.now();
        const timestamp2 = Date().toLocaleString();
        const db = firebase.firestore();
        db
            .collection("survey_temp")
            .doc(firebase.auth().currentUser.uid)
            .set({
                surveyversion: surveyversion_name,
                surveydata: localStorage.getItem('surveyJSON'),
                surveyname: localStorage.getItem('firebase_display'),
                surveytimes: {
                    [timestamp]: {
                        pagenumber: survey.currentPageNo,
                        readable_timestamp: timestamp2
                    }
                }
            }, {merge: true})
            .then(console.log('🔥SUCCESSFUL SUBMISSION TO TEMP FIRESTORE 🔥'));;
    };

    const onComplete = (survey) => {
        var res = {
            currentPageNo: survey.currentPageNo,
            data: survey.data
        };
        window
            .localStorage
            .setItem('surveyJSON', JSON.stringify(res));

        const db = firebase.firestore();

        //SEND FINAL COPY TO WORKING DATASET
        db
            .collection("survey_complete")
            .doc(firebase.auth().currentUser.uid)
            .set({
                surveyversion: surveyversion_name,
                datetimesubmitted: timestamp,
                surveydata: localStorage.getItem('surveyJSON'),
                surveyname: localStorage.getItem('firebase_display')
            }, {merge: true})
            .then(console.log('🔥SUCCESSFUL SUBMISSION TO NON-HISTORICAL FIRESTORE 🔥'))
        console.log('🔥SUCCESSFUL 🔥')

        //SEND FINAL COPY TO WORKING DATASET FOR HISTORICAL TRACKING
        db
            .collection("survey_historical")
            .doc(firebase.auth().currentUser.uid)
            .set({
                [timestamp]: {
                    surveyversion: surveyversion_name,
                    datetimesubmitted: timestamp,
                    surveydata: localStorage.getItem('surveyJSON'),
                    surveyname: localStorage.getItem('firebase_display')
                }
            }, {merge: true})
            .then(console.log('🔥SUCCESSFUL SUBMISSION TO HISTORICAL FIRESTORE 🔥'))
        console.log('🔥SUCCESSFUL 🔥')

        // SEND DATA TO AWS API / LAMBDA FUNCTION
        const datatosend = {};
        const searchable_results = {};
        datatosend.uuid = firebase
            .auth()
            .currentUser
            .uid;
        //  datatosend.results = localStorage.getItem('surveyJSON');
        datatosend.json = res.data;
        console.log("Survey results sent: " + JSON.stringify(datatosend));
        axios
            .post(`https://afxq49tmw1.execute-api.us-east-2.amazonaws.com/dev`, {datatosend})
            .then(res2 => {
                console.log("Returned from AWS - Type: " + res2);
                console.log("Returned from AWS - Data: " + res2.data);
                console.log("Returned from AWS - Data Body Only: " + res2.data.body);
                const temptest = JSON.parse(res2.data.body);
                console.log("Returned from AWS - SEARCHABLE RESULTS TEST: DOMAIN 1: " + temptest.s1[0]);
                // const survey_results = res2.data.body; searchable_results =
                // JSON.parse(survey_results); THEN.....pass this scored result INTO FIREBASE
                // 🔥🔥
                const db = firebase.firestore();
                db
                    .collection("survey_complete")
                    .doc(firebase.auth().currentUser.uid)
                    .set({
                        surveyversion: surveyversion_name,
                        surveyscored: res2.data.body
                    }, {merge: true})
                    .then(console.log('🔥SUCCESSFUL SUBMISSION OF XYLA SCORED FROM AWS FIRESTORE 🔥'));

                // NAVIGATE TO HOME PAGE
                const xylalink = '/xylagraph/' + firebase
                    .auth()
                    .currentUser
                    .uid;
                window.location.href = xylalink;

            })
            .catch((err) => {})

    };

    if (loading) {
        return <LaunchScreen/>
    }

    return (

        <BodyWrapper2>
            <div>
                <Survey.Survey
                    model={survey}
                    onComplete={onComplete}
                    onUpdateQuestionCssClasses={customCSSclass}
                    showCompletedPage={false}
                    onValueChanged={onValueChanged}
                    onCurrentPageChanged={onCurrentPageChanged}
                    requiredText={''}/>
            </div>
            <P marginTop={0} id="surveyResult" color="#6d7072" className="text-center">{survey.currentPageNo+1} / {survey.pageCount}</P>
            {/* <StickyFooter /> */}
        </BodyWrapper2>

    );
};

export default SimpleSurvey;