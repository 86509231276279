import React, { useContext } from 'react';
import VizulyRadarChart from "../../services/xylagraph/vizuly/VizulyRadarChart";
import radarData from "../../services/xylagraph/data/radarchart_data_simple.json";
import * as d3 from "d3";

import { XylaContext } from '../../contexts/xylaContext';
import { isMobile } from 'react-device-detect';


export default function RadarChartTest() {

	
	const xylacontextdata = useContext(XylaContext);

	const datafield = xylacontextdata.resultsingle;

	const colorfield = xylacontextdata.xylacolor2;
	const colorone = colorfield

	console.log('datafield: ', datafield);
	console.log('colorone: ', colorone);

	const size = {
		width: 700,
		height: 700
	}

	if (isMobile) {
		size.width = '100%';
		size.height = 500;
	}

	return (
			<div className="Vizuly-container">
				<div className="Vizuly-component">
					<VizulyRadarChart
					   props={[
							{'width': size.width},
							{'height': size.height},
								{'data': datafield}, 
						   {'y': function (d,i) { return d.value; }},
						   {'x': function (d,i) { return String(d.axis).toUpperCase() }},
						   {'seriesLabel': function (d,i) { return d.category; }},
						   {'yTickFormat': function (d) { return d3.format('.0f')(d*100) + '%';}}
							
					   ]}
					   styles={[
						   {'background-opacity': '0',},
						//    {'area-fill': function (d,i) { return colorone[colorone.length - 1][0].color; }},
						{'area-fill': function (d, i) {
							return d3.interpolateViridis(i/2);
						}},
						// {'line-stroke': function (d,i) { return colorone[colorone.length - 1][0].color; }},
						//    {'area-fill': colorone[colorone.length - 1][0].color},
						//    {'line-stroke': colorone[colorone.length - 1][0].color},
						//    {'line-stroke': "#"},
						]}
	
					/>
				</div>
			</div>
		   );
		
};


