const surveylist = {
  questionTitleTemplate: "{title} {require}",
    showProgressBar: "",
    goNextPageAutomatic: false,
    showNavigationButtons: true,
    pagePrevText: '',
    showPageNumbers: false,
    showPageTitles: false,
    showQuestionNumbers: "off",
    requiredErrorText: "requiredErrorText",



    pages: [



      {
        questions: [

          {
            type: "radiogroup",
            name: "q_1",
            title: "My favorite part of a party is",
            isRequired: true,
            choices: [
              "anticipating the party", "arriving at the party", "staying and playing at the party", "leaving the party"
            ]
          },
        ],
        name: "page1"
      },




      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_2",
            title: "Being alone is an",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            leftText: 'energy gain',
            rightText: 'energy drain'

          },
        ],
        name: "page2"
      },


      {
        questions: [

          {
            type: "radiogroup",
            name: "q_3",
            title: "I have had a near-death experience (i.e. a life-threatening illness or accident)",
            isRequired: true,
            choices: [
              "yes",
              "no",
            ]
          },
        ],
        name: "page3"
      },


      {
        questions: [

          {
            type: "radiogroup",
            name: "q_4",
            title: "When making decisions I generally listen to",
            isRequired: true,
            choices: [
              "my gut",
              "my head",
              "not sure, I can be pretty indecisive"
            ]
          },
        ],
        name: "page4"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_5",
            title: "Looking back, the home I grew up in was generally",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'stable',
            leftText: 'unstable'
          },
        ],
        name: "page5"
      },



      {
        questions: [

          {
            type: "radiogroup",
            name: "q_6",
            title: "I feel really good when I am",
            isRequired: true,
            choices: [
              "by myself",
              "with one important person",
              "in a small group",
              "in a large group"
            ]
          },
        ],
        name: "page6"
      },




      {
        questions: [

          {
            type: "radiogroup",
            name: "q_7",
            title: "When I feel stressed out, I tend to feel",
            isRequired: true,
            choices: [
              "fearful",
              "angry",
              "worried about what people are thinking and saying"
            ]
          },
        ],
        name: "page7"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_8",
            title: "When growing up, I generally felt",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "protected and cared about",
            leftText: "unsafe and vulnerable",
          },
        ],
        name: "page8"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_9",
            title: "I tend to expect",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "the worst",
            leftText: "the best",
          },
        ],
        name: "page9"
      },



      {
        questions: [

          {
            type: "radiogroup",
            name: "q_10",
            title: "Choose one of the following worries. I am most afraid of",
            isRequired: true,
            choices: [
              "being imperfect",
              "being unloved",
              "being unaccomplished or underachieving",
              "being misunderstood or average",
              "being incapable or helpless",
              "being without support or guidance",
              "missing out on life or being in pain",
              "being harmed or controlled",
              "being lost or separate from others",

            ]
          },
        ],
        name: "page10"
      },





      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_11",
            title: "I see the world as",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "fairly black and white",
            leftText: "many shades of grey",
          },
        ],
        name: "page11"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_12",
            title: "I tend to make decisions based on",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "what I am thinking",
            leftText: "how I am feeling",
          },
        ],
        name: "page12"
      },



      {
        questions: [

          {
            type: "radiogroup",
            name: "q_13",
            title: "When faced with life's troubles, I tend to get",
            isRequired: true,
            choices: [
              "depressed",
              "anxious",
              "both"
            ]
          },
        ],
        name: "page13"
      },




      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_14",
            title: "In general, I give greater importance to",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'inner thoughts and feelings',
            leftText: 'other people and the outside world',

          },
        ],
        name: "page14"
      },





      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_15",
            title: "I grew up in a home with alcoholism or drug use",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'minimal to none',
            leftText: 'yes, a lot',
          },
        ],
        name: "page15"
      },




      {
        questions: [

          {
            type: "radiogroup",
            name: "q_16",
            title: "When I feel stressed, I prefer to",
            isRequired: true,
            choices: [
              "relax at home",
              "relax by going out and seeing friends",
            ]
          },
        ],
        name: "page16"
      },




      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_17",
            title: "I experienced conflict at home when growing up",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'not much, mostly cooperative and peaceful',
            leftText: 'a lot, things were tough',
          },
        ],
        name: "page17"
      },





      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_18",
            title: "When I am in a group of people, I try",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'to march to the beat of my own drum',
            leftText: 'really hard to fit in',
          },
        ],
        name: "page18"
      },





      {
        questions: [

          {
            type: "radiogroup",
            name: "q_19",
            title: "When coming to an important decision, I like to",
            isRequired: true,
            choices: [
              "talk it over with friends",
              "sit and figure it out myself"
            ]
          },
        ],
        name: "page19"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_20",
            title: "In general, I tend towards being",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'optimistic',
            leftText: 'pessimistic',
          },
        ],
        name: "page20"
      },




      {
        questions: [

          {
            type: "radiogroup",
            name: "q_21",
            title: "When I have a setback at work, I",
            isRequired: true,
            choices: [
              "recover quickly",
              "take some time to regain confidence"
            ]
          },
        ],
        name: "page21"
      },



      {
        questions: [

          {
            type: "radiogroup",
            name: "q_22",
            title: 'When facing a challenge, I',
            isRequired: true,
            choices: [
              "gather opinions from others",
              "gather information",
              "trust my gut"
            ]
          },
        ],
        name: "page22"
      },




      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_23",
            title: "When I meet new people, I trust fairly",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: 'slowly, who are you?',
            leftText: 'quickly, people are great!',
          },
        ],
        name: "page23"
      },




      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_24",
            title: "I tend to share how I am feeling with friends",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "eww, that's scary",
            leftText: "often, people really help",
          },
        ],
        name: "page24"
      },




      {
        questions: [

          {
            type: "radiogroup",
            name: "q_25",
            title: "I like to connect with my friends through",
            isRequired: true,
            choices: [
              "time spent together",
              "words of support and kindness",
              "planning and performing actions",
              "gift giving",
              "physical affection"
            ]
          },
        ],
        name: "page25"
      },





      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_26",
            title: "I communicate best through",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "actions",
            leftText: "words",
          },
        ],
        name: "page26"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_27",
            title: "In a friendship, I need a lot of",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "space, we can hang out infrequently",
            leftText: "time spent in person together",
          },
        ],
        name: "page27"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_28",
            title: "To me, friendship is all about",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "giving freely, with no expectations",
            leftText: "reciprocity, we each give and get about the same",
          },
        ],
        name: "page28"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_29",
            title: "When I have a conflict with a friend, I tend to",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "avoid the situation",
            leftText: "talk it through",
          },
        ],
        name: "page29"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_30",
            title: "When I achieve a goal, I take",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "a moment to celebrate doing a good job",
            leftText: "a look at what could have been done differently",

          },
        ],
        name: "page30"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_31",
            title: "For me, work is",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "pretty competitive",
            leftText: "all about collaboration",

          },
        ],
        name: "page31"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_32",
            title: "When a friend and I disagree, I",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "am pretty sure I am right",
            leftText: "admit I could be wrong",

          },
        ],
        name: "page32"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_33",
            title: "I tend to share my feelings with others",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "almost never, mind your own business",
            leftText: "a lot, I share everything"

          },
        ],
        name: "page33"
      },

      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_34",
            title: "With a friend, I prefer to",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "keep things light and carefree",
            leftText: "go deep and intense",

          },
        ],
        name: "page34"
      },

      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_35",
            title: "If I rated myself on being a great friend, I",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "could do a lot better",
            leftText: "am a champ",

          },
        ],
        name: "page35"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_36",
            title: "When I pick a friend, I",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "care if they are similar to me",
            leftText: "prefer having a diverse friend group"

          },
        ],
        name: "page36"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_37",
            title: "In general, I would say I am",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "easy to get to know",
            leftText: "hard to get to know",

          },
        ],
        name: "page37"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_38",
            title: "In general, I like to plan things out ahead of time",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "yes, a lot",
            leftText: "no way, let things unfold"

          },
        ],
        name: "page38"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_39",
            title: "I would rate my self-confidence as",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "high, I feel awesome",
            leftText: "low, I need help believing in myself",

          },
        ],
        name: "page39"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_40",
            title: "I am stubborn",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "as a mule",
            leftText: "nah, I am flexible",

          },
        ],
        name: "page40"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_41",
            title: "I define myself through my work",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "a lot, my career is my identity",
            leftText: "not a lot, I identify mostly with life outside the office"

          },
        ],
        name: "page41"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_42",
            title: "Finish this thought:  Success comes from",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "our innate intelligence and talent",
            leftText: "a lot of effort and hard work"

          },
        ],
        name: "page42"
      },

      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_43",
            title: "In my world, reality is",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "fixed, what you see is what you get",
            leftText: "fluid, what is reality anyway?",

          },
        ],
        name: "page43"
      },

      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_44",
            title: "If friends share my political views, I care",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "a lot, it is very important",
            leftText: "a little, it does not matter"

          },
        ],
        name: "page44"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_45",
            title: "Friends sharing my spiritual/religious views is",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "very important",
            leftText: "not important"

          },
        ],
        name: "page45"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_46",
            title: "After I have earned enough money for my basic needs, I continue to want more money",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "not that important",
            leftText: "yes, super important"

          },
        ],
        name: "page46"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_47",
            title: "When I have money, I tend to",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "save it for a rainy day",
            leftText: "you only live once, let's spend it"

          },
        ],
        name: "page47"
      },



      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_48",
            title: "It is important to me that my friends are physically attractive",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "yes, I run with a good-looking crew",
            leftText: "nah, it is their soul that matters"

          },
        ],
        name: "page48"
      },




      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_49",
            title: "I would care if a friend had a criminal record",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "probably a deal breaker",
            leftText: "no, we are ride or die"

          },
        ],
        name: "page49"
      },





      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_50",
            title: "In general, I tend to",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "exercise self control and be practical",
            leftText: "indulge my desires"

          },
        ],
        name: "page50"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_51",
            title: "I would describe myself as",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "realistic",
            leftText: "idealistic"

          },
        ],
        name: "page51"
      },


      {
        questions: [

          {
            type: "bipolarslider",
            name: "q_52",
            title: "By the end of the week, I often have",
            isRequired: true,
            min: 0,
            max: 10,
            defaultValue: 5,
            rightText: "goofed off, the dog ate my homework",
            leftText: "worked too hard, what is my daughter’s name again?",

          },
        ],
        name: "page52"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_1",
          title: "Please rate the following activity: spending time indoors",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_1"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_2",
          title: "Please rate the following activity: spending time outdoors",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_2"
      },

      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_3",
          title: "Please rate the following activity: doing physical activities",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_3"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_4",
          title: "Please rate the following activity: doing mental activities",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_4"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_5",
          title: "Please rate the following activity: competing (e.g. games or sports)",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_5"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_6",
          title: "Please rate the following activity: observing (e.g. movies, shows, plays)",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_6"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_7",
          title: "Please rate the following activity: making things (e.g. building, DIY, knitting)",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_7"
      },



      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_8",
          title: "Please rate the following activity: interacting with others online",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_8"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_10",
          title: "Please rate the following activity: interacting with others in person",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_10"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_11",
          title: "Please rate the following activity: exploring (e.g. traveling, learning)",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_11"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_12",
          title: "Please rate the following activity: collecting (e.g. gathering, organizing, preserving)",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_12"
      },


      {
        questions: [{
          type: "bipolarslider",
          name: "q_53_13",
          title: "Please rate the following activity: shopping",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "a little",
          leftText: "a lot"
        }, ],
        name: "q_53_13"
      },










      {
        questions: [{
          type: "bipolarslider",
          name: "q_54",
          title: "My current use of alcohol or drugs is",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "I never use any",
          leftText: "I like to party"
        }, ],
        name: "page54"
      },



      {
        questions: [{
          type: "bipolarslider",
          name: "q_55",
          title: "I like to try and learn new things",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "never",
          leftText: "every minute of every day"
        }, ],
        name: "page55"
      },




      {
        questions: [{
          type: "bipolarslider",
          name: "q_56",
          title: "I would describe my level of activity as",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "couch potato",
          leftText: "when's my next flight"
        }, ],
        name: "page56"
      },








      {
        questions: [{
          type: "bipolarslider",
          name: "q_57",
          title: "It is important that my friends share my sense of humor",
          isRequired: true,
          min: 0,
          max: 10,
          defaultValue: 5,
          rightText: "yes, extremely important",
          leftText: "nah, we can connect on other things"
        }, ],
        name: "page57"
      },








      {
        questions: [

          {
            type: "radiogroup",
            name: "q_58",
            title: "My age is",
            isRequired: true,
            choices: [
              "18-25",
              "25-34",
              "35-44",
              "45-54",
              "55-64",
              "65+",
              "Prefer not to say"
            ]
          },
        ],
        name: "page58"
      },


      {
        questions: [

          {
            type: "radiogroup",
            name: "q_59",
            title: "I identify as",
            isRequired: true,
            choices: [
              "Male",
              "Female",
              "Prefer not to say",
              "Other"
            ]
          },
        ],
        name: "page59"
      },


      {
        questions: [

          {
            type: "radiogroup",
            name: "q_60",
            title: "My relationship status is",
            isRequired: true,
            choices: [
              "Married",
              "Divorced",
              "Single",
              "Widow/Widower",
              "Partnered",
              "Other"
            ]
          },
        ],
        name: "page60"
      },


      {
        questions: [

          {
            type: "radiogroup",
            name: "q_61",
            title: "My current living situation is best described as",
            isRequired: true,
            choices: [
              "live with parents or other family",
              "live with roommates",
              "live with wife/husband/romantic partner",
              "live alone",
              "live with partner and children",
              "live with children",
              "unhomed"
            ]
          },
        ],
        name: "page61"
      },

      {
        questions: [

          {
            type: "radiogroup",
            name: "q_62",
            title: "My current religious or spiritual affiliation is",
            isRequired: true,
            choices: [
              "Spiritual, but not religious",
              "Agnostic",
              "Christian",
              "Judaism",
              "Sikhism",
              "Shintoism",
              "Islam",
              "Buddhism",
              "Hinduism",
              "None",
              "Other"
            ]
          },
        ],
        name: "page62"
      },

      {
        questions: [

          {
            type: "text",
            name: "q_63",
            title: "Complete this sentence: In a friend, I look for",
            isRequired: true,
            placeHolder: " ",
          },
        ],
        name: "page63"
      },

      {
        questions: [

          //   {
          //     type: "panel",
          //     elements: [
          //         {
          //             type: "html",
          //             name: "income_intro",
          //             html: "<article class='intro'>    <h1 class='intro__heading intro__heading--income title'>                     Values              </h1>    <div class='intro__body wysiwyg'>       <p>Some important values in my family of origin are: </p>          </div> </article>"
          //         }
          //     ],
          //     name: "q64_instructions"
          // },

          {
            type: "text",
            name: "q_64a",
            title: "Please list up to three:  Some important values in my family of origin were",
            isRequired: true,
          },
          // {
          //   type: "text",
          //   name: "q_64b",
          //   title: "2",
          //   isRequired: false,
          // },
          // {
          //   type: "text",
          //   name: "q_64c",
          //   title: "3",
          //   isRequired: false,
          // },
        ],
        name: "page64"
      },

      {
        questions: [


          //   {
          //     type: "panel",
          //     elements: [
          //         {
          //             type: "html",
          //             name: "income_intro",
          //             html: "<article class='intro'>    <h1 class='intro__heading intro__heading--income title'>                     Deal Breakers              </h1>    <div class='intro__body wysiwyg'>       <p>Some deal breakers for me in a friendship are: </p>          </div> </article>"
          //         }
          //     ],
          //     name: "q65_instructions"
          // },

          {
            type: "text",
            name: "q_65a",
            title: "Please list up to three:  Some deal breakers for me in a friendship are",
            isRequired: true,
          },
          // {
          //   type: "text",
          //   name: "q_65b",
          //   title: "2",
          //   isRequired: false,
          // },
          // {
          //   type: "text",
          //   name: "q_65c",
          //   title: "3",
          //   isRequired: false,
          // },
        ],
        name: "page65"
      },

      {
        questions: [

          {
            type: "text",
            name: "q_66",
            title: "I would describe my current life stage as",
            isRequired: true,
            placeHolder: " "
          },
        ],
        name: "page66"
      },

      {
        questions: [

          {
            type: "radiogroup",
            name: "q_67",
            title: "The amount of meaningful friendships that I need is",
            isRequired: true,
            choices: [
              "1-2",
              "3-4",
              "5-10",
              "10+",
            ]
          },
        ],
        name: "page67"
      },

      {
        questions: [

          {
            type: "checkbox",
            name: "q_68",
            colCount: 1,
            title: "The 5 values from this list that are most important to me and how I live my life are",
            isRequired: true,
            choices: [
              "Acceptance",
              "Achievement",
              "Adventurousness",
              "Altruism",
              "Autonomy",
              "Caring",
              "Challenge",
              "Change",
              "Comfort",
              "Commitment",
              "Community",
              "Compassion",
              "Consistency",
              "Contentment",
              "Cooperation",
              "Creativity",
              "Curiosity",
              "Dependability",
              "Determination",
              "Discretion",
              "Diversity",
              "Efficiency",
              "Expertise",
              "Fairness",
              "Faith",
              "Family-orientedness",
              "Fitness",
              "Flexibility",
              "Forgiveness",
              "Freedom",
              "Fun",
              "Generosity",
              "Growth",
              "Happiness",
              "Health",
              "Honesty",
              "Hope",
              "Humility",
              "Humor",
              "Integrity",
              "Intuition",
              "Leadership",
              "Love",
              "Loyalty",
              "Moderation",
              "Order",
              "Originality",
              "Pleasure",
              "Positivity",
              "Practicality",
              "Security",
              "Self-acceptance",
              "Self-control",
              "Spirituality",
              "Wealth"
            ]
          },
        ],
        name: "page68"
      },

      {
        questions: [

          {
            type: "checkbox",
            name: "q_69",
            colCount: 1,
            title: "The 5 values from this list that I look for in a friend are",
            isRequired: true,
            choices: [
              "Acceptance",
              "Achievement",
              "Adventurousness",
              "Altruism",
              "Autonomy",
              "Caring",
              "Challenge",
              "Change",
              "Comfort",
              "Commitment",
              "Community",
              "Compassion",
              "Consistency",
              "Contentment",
              "Cooperation",
              "Creativity",
              "Curiosity",
              "Dependability",
              "Determination",
              "Discretion",
              "Diversity",
              "Efficiency",
              "Expertise",
              "Fairness",
              "Faith",
              "Family-orientedness",
              "Fitness",
              "Flexibility",
              "Forgiveness",
              "Freedom",
              "Fun",
              "Generosity",
              "Growth",
              "Happiness",
              "Health",
              "Honesty",
              "Hope",
              "Humility",
              "Humor",
              "Integrity",
              "Intuition",
              "Leadership",
              "Love",
              "Loyalty",
              "Moderation",
              "Order",
              "Originality",
              "Pleasure",
              "Positivity",
              "Practicality",
              "Security",
              "Self-acceptance",
              "Self-control",
              "Spirituality",
              "Wealth"
            ]
          },
        ],
        name: "page69"
      },


      {
        questions: [

          {
            type: "text",
            name: "q_70",
            title: "What I appreciate most about having a friend is",
            isRequired: true,
          },
        ],
        name: "page70"
      },


      {
        questions: [

          {
            type: "text",
            name: "q_71",
            title: "What I enjoy most about being a friend is",
            isRequired: true,
          },
        ],
        name: "page71"
      },

      {
        questions: [

          {
            type: "text",
            name: "q_72",
            title: "Something I did not get to say about friendships is",
            isRequired: true,
          },
        ],
        name: "page72"
      },










    ]

          }



export default surveylist;