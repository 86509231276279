import React, { useEffect } from 'react'      

export default function MailTest() {
    useEffect(() => {
    const sendEmail = async() => {
      const msg = {
        to: 'hantsawilliams@gmail.com',
        from: 'welcome@xylafriends.com',
        subject: 'This is a test mail',
        text: 'and easy to do anywhere, even with Node.js',
        html: '<strong>and easy to do anywhere, even with Node.js</strong>',
      }; 

      const response = await fetch(
        'https://us-central1-xylafriends.cloudfunctions.net/app/sendemailtest', {
        method: 'POST',
        body: JSON.stringify(msg),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log("response", response);
    }

    sendEmail();
  }, []);

  return <h1>Email Sending Page</h1>
}