import React, {useState, useRef, useEffect} from 'react';
import { 
  Grid, Button, FormLabel, Input, Radio, RadioGroup, Typography, IconButton,
  FormControl, FormControlLabel, Select, MenuItem, OutlinedInput,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle as MuiDialogTitle
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap'

import * as firebase from "firebase/app";
import { P } from '../../containers';
import "../../firebase";
import XylaButton from "../XylaButton/XylaButton";
import { contentClient, CONTENT } from "../../data/contentful";
import Auth from '../../services/authentication';
import { toLower } from '../../services/utils';
import "./style.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function XylaAddFriend(props) {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [contentfulValues, setContentfulValues] = useState({ page: {}, modal: {} });
  const [friend, setFriend] = useState('');
  const [isValidForm, setValidForm] = useState(false);
  const [result, setResults] = useState([]);

  const [email, setEmail] = useState('');
  const [inviteType, setInviteType] = useState('');
  const [relation, setRelation] = useState('');

  const focusSearch = useRef(null)

  const testFunction = () => {
    console.log('email/user to be linked via our backend process: ', query)
  }

  const db3 = firebase.firestore();
  // const invitenewFriend = async () => {
  const invitenewFriend = () => {
    const _query = toLower(query);
    console.log('value to be submitted to INVITE page: ', _query);
    var emailSent = true;

    Auth.checkIfEmailRegistered(_query)
    .then(async existed => {
      let existedInvite = await checkIfExistedOnConnection();

      if ((existed && inviteType == 'only') || existedInvite) {
        emailSent = false;
      }
      if (emailSent) {
        setTimeout(()=> {
          console.log("its at part 2.2 - firebase backup is starting up");
          console.log("Second");
          const invitememberName2 = firebase.auth().currentUser.email
          const invitefriendEmail2 = _query;
          const urlreferralmessage = `http://` + process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
          fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/send-email-comparepage?recipient=${invitefriendEmail2}&friendrequest=${invitememberName2}&urlreferralmessage=${urlreferralmessage}&newmember=${invitememberName2}`, {method: "put"}) // ,{mode: 'cors'}
          .catch(err => console.error(err))
          console.log("Completed push to sendgrid!")
          console.log("Succesfully cleared form")
        }, 1500);
      }
      // setOpen2(true);
      cleanForm();
      invitenewFriendtable();
    });
  }

  const checkIfExistedOnConnection = async () => {
    const futuresenderUID = firebase.auth().currentUser.uid;
    const _query = toLower(query);

    const futurerequestAformat = futuresenderUID + '_' + _query
    const futureresultsA = await db3.doc(`connections/${futurerequestAformat}`).get()
    return futureresultsA.exists;
  }

  const invitenewFriendtable = async () => {
    // ADD THIS NEW INFORMATION TO THE FRIEND_FUTURE TABLE 
    const futuresenderUID = firebase.auth().currentUser.uid;
    const futuresenderEmail = firebase.auth().currentUser.email;
    const _query = toLower(query);

    console.log('futuresenderUID = ', futuresenderUID)
    console.log('futurereceiverUID = ', toLower(_query))

    //CHECK FOR FORMAT 1 - SENDER to RECEIVER 
    const futurerequestAformat = futuresenderUID + '_' + _query
    const futureresultsA = await db3.doc(`connections/${futurerequestAformat}`).get()
    const futurecheckA = futureresultsA.exists.toString();
    console.log('futurecheckA processed - Sender to Receiver:', futurecheckA);

    //CHECK FOR FORMAT 2 - RECEIVER TO SENDER 
    const futurerequestBformat = _query + '_' + futuresenderUID
    const futureresultsB = await db3.doc(`connections/${futurerequestBformat}`).get()
    const futurecheckB = futureresultsB.exists.toString();
    console.log('checkB processed - Receiver to Sender:', futurecheckB);

    const futuretimestamp = Date.now();
    const futuretimestamp2 = Date().toLocaleString();

    if (futurecheckA == 'false' & futurecheckB == 'false') {
      const data = {
        sender: futuresenderUID,
        sender_email: futuresenderEmail,
        receiver: 'not_yet_generated',
        receiver_email: _query,
        uid_group: [futuresenderUID, _query],
        timestamp_computer: futuretimestamp,
        timestamp_human: futuretimestamp2,
        inviteType: inviteType,
        sender_relation: relation
      };

      db3.collection("connections")  
      .doc(futurerequestAformat)
      .set(
        {
          ...data,
          receiver_activated: 'false',
          status: 'Pending',
        },
        { merge: true }
      )
      .then(
        console.log('🔥 FRIEND REQUEST SUCCESFULLY STORED IN CONNECTIONS'),
        setOpen2(true)
      )

      db3.collection("connections_log")  
      .doc(futurerequestAformat)
      .set(
        {
          ...data
        },
        { merge: true }
      )
      .then(console.log('🔥 SUCCESFULLY STORED TO CONNECTIONS_LOG🔥'))
    } else if (futurecheckA == 'false' & futurecheckB == 'true')  {
      alert("🔥 Looks like that user has already sent you a friend request 🔥")
    } else if (futurecheckA == 'true' & futurecheckB == 'false' )  {
      const resultsAData = futureresultsA.data();
      if (resultsAData.inviteType == 'only' && inviteType == 'share') {
        db3.collection("connections")  
        .doc(futurerequestAformat)
        .set(
          {
            inviteType: inviteType,
            sender_upgrade_relation: relation
          },
          { merge: true }
        )
        .then(
          setOpen2(true)
        )

        db3.collection("connections_log")  
        .doc(futurerequestAformat)
        .set(
          {
            inviteType: inviteType,
            sender_upgrade_relation: relation
          },
          { merge: true }
        );
        if (inviteType !== 'only') {
          const invitememberName22 = firebase.auth().currentUser.email
          const invitefriendEmail22 = friend.email
          const urlreferralmessage2 = `http://` + process.env.REACT_APP_HOMEPAGE;
          fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/send-email-comparepage-existinguser?recipient=${invitefriendEmail22}&friendrequest=${invitememberName22}&urlreferralmessage=${urlreferralmessage2}&newmember=${invitememberName22}`, {method: "put"}) // ,{mode: 'cors'}
          .catch(err => console.error(err))
        }
      } else {
        alert("🔥 Looks like you have already sent a friend request to that user 🔥")
      }
    } else {
      console.log('🔥 we have not yet configured this scenario.... 🔥')
      alert("🔥 scenario not yet configured 🔥")
    }
  }

  const getResults = async (query) => {
    const results = await db3.doc(`searchUsers/${query}`).get()
    const userExists = results.exists.toString();
    const receiverUID = db3.doc(`searchUsers/${query}`)
    receiverUID.get().then(function(doc) {
      if (doc.exists) {
        console.log("Document data:", doc.data());
        const friendTemp = doc.data()
        const friend = friendTemp
        console.log('friendID: ', friend)
        setFriend(friend)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      return friend;
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });

    console.log('doesUserexist: ', userExists)
    const userExists2 = {query, userExists}
    console.log('response :', userExists2)

    return userExists2
  } 

  // this is a good supporing doc -> https://firebase.googleblog.com/2018/08/better-arrays-in-cloud-firestore.html

  const firestoreSEND = async () => {
    const senderUID = firebase.auth().currentUser.uid;
    const senderEmail = firebase.auth().currentUser.email;

    console.log('senderUID = ', senderUID)
    console.log('senderEmail = ', senderEmail)
    console.log('receiverUID = ', friend.uid)
    console.log('receiverEmail', friend.email)

    // remove old connection if there is when inviter was not created.
    var oldRecordExists = false;
    const oldFormat = senderUID + '_' + friend.email;
    const oldDoc = db3.doc(`connections/${oldFormat}`);
    const oldResult = await oldDoc.get()
    if (oldResult.exists) {
      await oldDoc.delete();
      oldRecordExists = true;
    }

    //CHECK FOR FORMAT 1 - SENDER to RECEIVER 
    const requestAformat = senderUID + '_' + friend.uid
    const resultsA = await db3.doc(`connections/${requestAformat}`).get()
    const checkA = resultsA.exists.toString();
    console.log('checkA processed - Sender to Receiver:', checkA);

    //CHECK FOR FORMAT 2 - RECEIVER TO SENDER 
    const requestBformat = friend.uid + '_' + senderUID
    const resultsB = await db3.doc(`connections/${requestBformat}`).get()
    const checkB = resultsB.exists.toString();
    console.log('checkB processed - Receiver to Sender:', checkB);

    const timestamp = Date.now();
    const timestamp2 = Date().toLocaleString();

    if (checkA == 'false' & checkB == 'false') {
      const data = {
        sender: senderUID,
        sender_email: senderEmail,
        receiver: friend.uid,
        receiver_email: friend.email,
        uid_group: [senderUID, friend.uid],
        timestamp_computer: timestamp,
        timestamp_human: timestamp2,
        inviteType: inviteType,
        sender_relation: relation
      }

      db3.collection("connections")  
      .doc(requestAformat)
      .set(
        {
          ...data,
          status: 'Pending',
          status_receiver: 'Pending',
          status_sender: 'Approved',
        },
        { merge: true }
      )
      .then(() => {
        if (!oldRecordExists) {
          console.log('🔥 SUCCESFULLY STORED TO FRIENDS🔥');
          setOpen2(true)
        }
      })

      db3.collection("connections_log")  
      .doc(requestAformat)
      .set(
        {
          ...data
        },
        { merge: true }
      )
      .then(console.log('🔥 SUCCESFULLY STORED TO FRIENDS_LOG🔥'))

      if (!oldRecordExists) {
        if (inviteType !== 'only') {
          const invitememberName22 = firebase.auth().currentUser.email
          const invitefriendEmail22 = friend.email
          const urlreferralmessage2 = `http://` + process.env.REACT_APP_HOMEPAGE;
          fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/send-email-comparepage-existinguser?recipient=${invitefriendEmail22}&friendrequest=${invitememberName22}&urlreferralmessage=${urlreferralmessage2}&newmember=${invitememberName22}`, {method: "put"}) // ,{mode: 'cors'}
          .catch(err => console.error(err))
        }
        console.log("Completed push to sendgrid! - sending off email to alert the existing member of a new friend request")
        console.log("Succesfully cleared form")
      } else {
        alert("🔥 Looks like that user has already sent you a friend request 🔥");
      }
    } else if (checkA == 'false' & checkB == 'true')  {
        alert("🔥 Looks like that user has already sent you a friend request 🔥")
    } else if (checkA == 'true' & checkB == 'false' )  {
        const resultsAData = resultsA.data();
        if (resultsAData.inviteType == 'only' && inviteType == 'share') {
          db3.collection("connections")  
          .doc(requestAformat)
          .set(
            {
              inviteType: inviteType,
              sender_upgrade_relation: relation
            },
            { merge: true }
          )
          .then(
            setOpen2(true)
          )

          db3.collection("connections_log")  
          .doc(requestAformat)
          .set(
            {
              inviteType: inviteType,
              sender_upgrade_relation: relation
            },
            { merge: true }
          );
          if (inviteType !== 'only') {
            const invitememberName22 = firebase.auth().currentUser.email
            const invitefriendEmail22 = friend.email
            const urlreferralmessage2 = `http://` + process.env.REACT_APP_HOMEPAGE;
            fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/send-email-comparepage-existinguser?recipient=${invitefriendEmail22}&friendrequest=${invitememberName22}&urlreferralmessage=${urlreferralmessage2}&newmember=${invitememberName22}`, {method: "put"}) // ,{mode: 'cors'}
            .catch(err => console.error(err))
          }
        } else {
          alert("🔥 Looks like you have already sent a friend request to that user 🔥")
        }
    } else {
        console.log('🔥 we have not yet configured this scenario.... 🔥')
        setOpen2(true)
    }
    // const results = await db3.doc(`friends/${query}`).get()
    // const userExists = results.exists.toString();
    // console.log('doesUserexist: ', userExists)
    // const userExists2 = {query, userExists}
    // console.log('response :', userExists2)
    return 'its all good in the hood'
  } 


  // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  // useEffect - ONLY RERENDERS WHEN query IS CHANGED
  useEffect(() => {
    let currentQuery = true
    const controller = new AbortController()

    const loadResults = async () => {
      if (!query) return setResults([])
      await sleep(350);

      if (currentQuery) {
        const result = await getResults(toLower(query), controller)
        setResults(result)
      }
    }

    loadResults();
    return () => {
      currentQuery = false;
      controller.abort();
    }
  }, [query]);

  useEffect(() => {
    if (props.noButton) {
      cleanForm();
      setOpen(props.open);
    }
  }, [props.open])

  
  useEffect(() => {
    if (email && relation && inviteType && ValidateEmail(result.query) == true && result.query != userEmail) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [email, relation, inviteType]);

  const ValidateEmail = (inputText) => {
    // var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
    if(inputText && inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const FriendButtonDisplay = () => {

    //result.query.includes('@') == true && result.query.includes('.') == true
    if (result.userExists == 'false' && result.query != userEmail && ValidateEmail(result.query) == true) {
        // return InviteFriendtoXyla();
    } else if (result.userExists == 'true' && result.query != userEmail && ValidateEmail(result.query) == true)   {
        // return FriendRequesttoUser();
    } else if (result.query == userEmail) {
      return 'Thats your email silly! Please enter in a friends email';
    } else if (result.length == 0) {
      // return (
      //   <P/>
      // )
    } else if (ValidateEmail(result.query) == false) {  
      return (
        <P fontSize={12} marginBottom={12} color={'#821cc9'}> Please enter a valid email address.</P>
      )
    }
    
    setEmail(result.query);
    return (
      <P/>
    )
  }

  const GetPrintContent = () => {
    let { finePrint } = contentfulValues.modal;
    if (!finePrint) return '';
    return finePrint.content[0].content[0].value;
  }
  
  const GetCopyDeck = () => {
    let { copy1 } = contentfulValues.modal;
    return copy1
  }

  useEffect(() => {
    async function fetchData() {
      const { fields: modalFields } = await contentClient.getEntry(CONTENT.INVITE_TO_COMPARE_MODULE.id);
      const { fields: pageFields } = await contentClient.getEntry(CONTENT.COMPARE_PAGE.id);
      setContentfulValues({ page: pageFields, modal: modalFields });
    }
    fetchData();
  }, []);

  const cleanForm = () => {
    setResults([]);
    setRelation('');
    setInviteType('');
    setQuery('');
    setEmail('');
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    cleanForm();
    if (props.onClose) props.onClose();
  };

  const handleClose2 = () => {
    cleanForm();
    setOpen2(false);
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleClear = () => {
    cleanForm();
    setOpen2(false);
  };

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      callback();
    }
  };

  const handleEmail = value => {
    setQuery(value);
  };

  const handleInviteType = value => {
    setInviteType(value);
  };

  const handleRelation = value => {
    setRelation(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (result.userExists == 'false') {
        return invitenewFriend();
    } else if (result.userExists == 'true') {
        return firestoreSEND();
    }
  }

  const userEmail = firebase.auth().currentUser?.email;

  const FriendRequesttoUser = () => (
    <div>
      <Grid container justify="center">
        {/* <Button variant="contained" onClick={firestoreSEND} endIcon={<CloudUploadIcon />}> SUBMIT! (TEMP DESCRIPTION: existing user path)</Button>  */}
        <XylaButton 
          title="Submit!" 
          buttonfunction={firestoreSEND} 
          buttonbackground="linear-gradient(180deg, #4191F1 0%, #812CC9 100%)"
        />
      </Grid>
    </div>
  )

  const InviteFriendtoXyla = () => (
    <div>
      <Grid container justify="center">
        {/* <Button variant="contained" onClick={invitenewFriend} endIcon={<MessageIcon />}> SUBMIT! (TEMP DESCRIPTION: non-user path)</Button>   */}
        <XylaButton 
          title="Submit!" 
          buttonfunction={invitenewFriend} 
          buttonbackground="linear-gradient(180deg, #4191F1 0%, #812CC9 100%)"
        />
      </Grid>
    </div>
  )
  
  const { Button: PropButton, noButton, isAlt } = props;

  const useStyles = makeStyles((theme) => ({
    outlineInput: {
      width: '50%',
      minWidth: 200
    }
  }));

  const classes = useStyles();

  return (
    <div className={PropButton ? "inline-content" : noButton ? "" : "invite-button"}>
      {/* <button type='button' className="specialinput" onClick={handleClickOpen}>+ Add More Friends</button> */}
      {!noButton ? 
        <Button color="inherit" onClick={handleClickOpen}>
          {PropButton ? PropButton : contentfulValues.page.buttonAdd}
        </Button>
      : null}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" onClose={handleClose}>{contentfulValues.modal.headline1}</DialogTitle>
        <DialogContent id="form-dialog-content">
          <Form id="invite-form" onSubmit={handleSubmit} onKeyDown={e => { handleKeyDown(e, testFunction) }}>
            <Form.Group>
              {isAlt ? 
                <FormLabel className="alt-content">{GetCopyDeck()}</FormLabel>
              : null}

              <FormLabel>{contentfulValues.modal.emailQuestion}</FormLabel>
              <OutlinedInput 
                fullWidth
                margin='dense'
                placeholder={contentfulValues.modal.emailLabel}
                inputProps={{ 
                  'aria-label': 'description',
                  type: 'email',
                  required: true
                }}
                name="email"
                variant="outlined"
                onChange={(e) => handleEmail(e.target.value)}
                value={query}
                className={classes.outlineInput}
              />
              <div>
                <FriendButtonDisplay />
              </div>  
            </Form.Group>

            <Form.Group>
              <FormLabel>{contentfulValues.modal.selectorQuestion}</FormLabel>
              <RadioGroup
                onChange={(e) => handleInviteType(e.target.value)}
                value={inviteType}
                inputProps={{
                  required: true
                }} name="question">
                <FormControlLabel value="share" control={<Radio />} label={contentfulValues.modal.option1} />
                <FormControlLabel value="only" control={<Radio />} label={contentfulValues.modal.option2} />
              </RadioGroup>
            </Form.Group>

            <Form.Group>
              <FormLabel>{contentfulValues.modal.friendshipQuestion}</FormLabel>
              <FormControl>
                <Select
                  value={relation}
                  onChange={e => handleRelation(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {(contentfulValues.modal.friendshipTypes || []).map(x => 
                    <MenuItem className="friend-option" dense={true} value={x}>{x}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </Form.Group>

            <div className="dialog-action">
              <Button disabled={!isValidForm} type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </div>

            <div className="fine-print" dangerouslySetInnerHTML={{__html: GetPrintContent()}}></div>
          </Form>

        </DialogContent>
      </Dialog>


      <Dialog open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">🔥SUCCESSFUL</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Looks like it worked!
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} color="primary">
            Send Another?
          </Button>
          <Button onClick={handleClose2} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}