import React, { useState, useEffect } from "react";

import "../XylaResults/XylaResults.css";
import { useParams, Link } from "react-router-dom";
import { firestore } from "../../firebase";
import firebase, { auth } from "../../firebase";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";
import WhiteCircle from "../../illustrations/whitecircle.svg";
import XylaGraph from "../../services/xylagraph/test/RadarChartTest3.jsx";
import { Avatar } from "@material-ui/core";
import useWindowSize from 'react-use/lib/useWindowSize'
import { isMobile } from 'react-device-detect';


import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import XylaButton from "../XylaButton/XylaButton";

import ImageKey from '../../illustrations/xylaGraph_labels_only_3c.svg';


const useStyles = makeStyles((theme) => ({
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  },
  secondaryAction: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  contentBox: {
    maxWidth: theme.breakpoints.values['md'],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: 'auto',
    fontFamily: 'Montserrat',
    marginRight: 'auto',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values['lg'] / 2,
      maxHeight: 512,
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(16),
      marginRight: 0,
      textAlign: 'left',
    }
  },
  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: 'cover',
    height: 600,
    width: '100%',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    background: 'white',

  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function ShareClick () {
  console.log('this is a click');
  window.location.href='/invite';
};





export default function Header(props) {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const { width, height } = useWindowSize()

  useEffect(() => {
    return firestore
      .collection("users")
      .doc(userId)
      .onSnapshot(
        (snapshot) => {
          setLoading(false);
          setUser(snapshot.data());
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  }, [userId]);



  const classes = useStyles();

  const content = {
    'header': 'The New Internet',
    'description': 'The PiperNet is on it\'s way to revolutionize every smartphone, PC, and smart-fridge near you.',
    'primary-action': 'Sign up for free',
    'secondary-action': 'Read more',
    'image': 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
    ...props.content
  };

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user"
        description="Something went wrong when trying to retrieve the requested user"
        // button={
        //   <Fab
        //     variant="extended"
        //     color="primary"
        //     onClick={() => window.location.reload()}
        //   >
        //     <Box clone mr={1}>
        //       <RefreshIcon />
        //     </Box>
        //     Retry
        //   </Fab>
        // }
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <EmptyState
        image={<NoDataIllustration />}
        title="User doesn’t exist"
        description="The requested user doesn’t exist"
        // button={
        //   <Fab variant="extended" color="primary" component={Link} to="/">
        //     <Box clone mr={1}>
        //       <HomeIcon />
        //     </Box>
        //     Home
        //   </Fab>
        // }
      />
    );
  }

  const currentUser = auth.currentUser
  const currentUserImg = currentUser.photoURL

  var graphHeight = 610;
  var lineWidth = 500;
  if (isMobile) {
    graphHeight = 480;
    lineWidth = 430;
  }

  return (
    <section>

      <Grid container spacing={0}>
        

      <Grid item xs={12} lg={1}>
      </Grid>

        <Grid item xs={12} lg={10}>
          <Box position="flex" height={graphHeight}>
              <div className="container chart-container"> 
                <img className="chart-axis" src={ImageKey} alt='logo' style={{width: lineWidth}} />
                <XylaGraph />
                {/* <div className="imgcontainer" style={{zIndex:1}}>  
                   <Avatar alt="xylaface" src={WhiteCircle} style={{background: 'white', width:'4.2em', height:'4.0em'}}  />
                </div> */}
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} lg={1}> </Grid>

      </Grid>


    </section>
  );
}