import * as firebase from "firebase/app";
import "../../firebase";

const ReferralCapture = () => {
    const urlparams = new URLSearchParams(window.location.search).get('referralcode') || null;
    const referralcode = urlparams
    localStorage.setItem("referral", referralcode);

    if (localStorage.getItem("referral") != null && firebase.auth().currentUser) {
        const db = firebase.firestore();
        db.collection("users")
        .doc(firebase.auth().currentUser.uid)
            .update({
            referraluid: localStorage.getItem('referral')
            })

    };        
};


export default ReferralCapture;