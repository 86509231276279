import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class WaitlistComplete extends Component {



render() {

    return(

        <div className="container" >
            <h3> Thanks! This is a place holder for the wordpress page that will exist... </h3>
        </div>
    )
}

}

export default WaitlistComplete; 
