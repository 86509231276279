import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as firebase from "firebase/app";
import "../../firebase";

import { H1, H2, P, BodyWrapper, ImageStyle, ImageStyle2, Centered } from "../../containers";

// import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from "react-recaptcha";
import * as yup from 'yup';

import '../Waitlist/Waitlist.css';
import '../InviteFriend/invitefriend.css';

import ElementImage from '../../components/__elements/ElementImage';
import Image1 from '../../illustrations/300_300_xylagraph.svg';

import ShareButton from "../ShareButton/ShareButton";

import { contentClient } from "../../data/contentful";



const homelink = "Back >"
const waitlink = "Back >"


// FOR LOADING IN FRIEND RECOMMENDATION EMAIL // 
const friendinsert = new URLSearchParams(window.location.search).get('friendemail') || null;
console.log('your friend email to add: ', friendinsert)


const imageload1 = Image1

class InviteFriendFormik extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          name: null,
          yourname: null,
          lastName: null,
          email: null,
          error: null,
          loading: null,
          recaptcha: null,
          contentfulresponse: [],
        };

        this.first = this.first.bind(this);
        this.second = this.second.bind(this);
        this.secondpart2 = this.secondpart2.bind(this);
        this.third = this.third.bind(this);
        this.forth = this.forth.bind(this);

      }

      componentDidMount() {

        contentClient.getEntries({
          content_type: "inviteFriends",
        }).then(json => {
          this.setState({ contentfulresponse: json.items[0].fields});
        })

        const script = document.createElement("script");
        script.src =
          "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
      
    
    onCaptchaChange(value) {
        console.log("Captcha value:", value);
      }

 

    mySubmitHandler2 = (values) => {
            this.setState({
                name: values.name,
                yourname: values.yourname,
                email: values.email,
                recaptcha: values.recaptcha

            })
            this.first()
            }
      

    refreshPage = () => { 
        window.location.reload(); 
      }

    backtotop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }



    first = () => {
        this.backtotop()
        this.setState({loading: 'part1done'})
        const invitefriendName = this.state.name
        const invitememberName = this.state.yourname
        const invitefriendEmail = this.state.email
        
        setTimeout(()=> {
            const timestamp = Date.now();
            const timestamp2 = Date().toLocaleString();
            const db = firebase.firestore();
            db.collection("shares")  
            .doc(firebase.auth().currentUser.uid)
            .set(
                {
                    [timestamp] : 
                    {
                        invite_friendName: invitefriendName,
                        invite_memberName: invitememberName,
                        invite_friendEmail: invitefriendEmail,
                        readable_timestamp: timestamp2,
                    }
                },
                { merge: true }
                )
                .then(console.log('🔥SUCCESSFUL SUBMISSION TO EMAIL_SHARES FIRESTORE 🔥'))
            this.setState({loading: null})
            this.second()        
            }, 2200)
        }



        second = () => {
            this.setState({loading: 'part2done'})
            setTimeout(()=> {
                console.log("its at part 2.2 - firebase backup is starting up");
                console.log("Second");
                const invitefriendName2 = this.state.name
                const invitememberName2 = this.state.yourname
                const invitefriendEmail2 = this.state.email
                const urlreferralmessage = `http://` + process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
                //Send Email - Sendgrid 
                //https://us-central1-xylafriends.cloudfunctions.net/app/
                fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/send-email?recipient=${invitefriendEmail2}&friendrequest=${invitememberName2}&urlreferralmessage=${urlreferralmessage}&newmember=${invitefriendName2}`, {method: "put"}) // ,{mode: 'cors'}
                  .catch(err => console.error(err))
                console.log("Completed push to sendgrid!")
                console.log("Succesfully cleared form")
                this.setState({loading: null})
                this.secondpart2()
            }, 1000)
            }


            secondpart2 = () => {
              this.setState({loading: 'part2done'})
              setTimeout(()=> {
                  console.log("its at part 2.2 - firebase backup is starting up");
                  console.log("Second");
                  const invitefriendName2 = this.state.name
                  const invitememberName2 = this.state.yourname
                  const invitefriendEmail2 = this.state.email
                  const urlreferralmessage = `http://` + process.env.REACT_APP_HOMEPAGE + '/?referralcode=' + firebase.auth().currentUser.uid;
                  //Save Invite - Sendgrid 
                  fetch(`https://us-central1-xylafriends.cloudfunctions.net/app/invite?email=${invitefriendEmail2}&first_name=${invitememberName2}&urlreferralmessage=${urlreferralmessage}`,
                    {method: "put"}).catch(err => console.error(err))
                  
                  
      
                  console.log("Completed push to sendgrid!")
                  console.log("Succesfully cleared form")
                  this.setState({loading: null})
                  this.third()
              }, 1000)
              }



        third = () => {
                this.setState({loading: 'part3done'})
                setTimeout(()=> {
                      console.log("its at part 3.3 - firebase backup is starting up");
                      console.log("Third");
                      this.setState({loading: null})
                  }, 15000000)
                }
                
        
        forth = () => {
                  this.setState({loading: 'error'})
                  setTimeout(()=> {
                        console.log("Error");
                        console.log("Forth");
                        this.setState({loading: null})
                    }, 10000)
                  }           

        fifth = () => {
                    this.setState({loading: 'error_email'})
                    setTimeout(()=> {
                          console.log("Error");
                          console.log("Fifth");
                          this.setState({loading: null})
                      }, 10000)
                    }     

  
    
    
    render() {

      const { contentfulresponse } = this.state


      const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        
        if (this.state.loading === 'part1done' || this.state.loading ==='part2done') {
            return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #4191F1 0%, #812CC9 100%)', color: 'white'}}> <BodyWrapper> <H1> <div style={{textAlign: "center"}}> Hang on... </div> </H1> </BodyWrapper> </div>
                }


  
        if (this.state.loading === 'part3done') {
        return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)',  color: 'white'}}> 
                <div className="specialform2">
                    <H1> <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> Invitation sent</div></H1>
                    <P> 
                        <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> 
                                        Your invitation to {this.state.email} is on its way! <br /> <br /> 
                                    <a href='/invite' style={{ color: 'inherit', textDecoration: 'inherit'}}> {homelink}  
                                    </a>  
                        </div>
                    </P> 
                       </div>
                </div>                   
                }

        // if (this.state.loading === 'error') {
        // return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)',  color: 'white'}}> 
        // <div className="specialform2">
        //             <H1> <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> Please try again</div></H1>
        //             <P> 
        //                 <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> 
        //                         You must enter a valid email address to be added to the wait list.  <br /> <br />   <a href='https://app.xylafriends.com/invite' style={{ color: 'inherit', textDecoration: 'inherit'}}> {waitlink}  
        //                             </a>                                       
        //                 </div>
        //             </P> 
        //             </div>
        //         </div>                                                  
        //         }

        // if (this.state.loading === 'error_email') {
        // return <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)', color: 'white'}}> 
        // <div className="specialform2">
        //             <H1> <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> Please try again</div></H1>
        //             <P> 
        //                 <div style={{textAlign: "center", color: "inherit", textDecoration: "none"}}> 
        //                         You must enter a valid email address to be added to the wait list.  <br /> <br />  <a href='https://app.xylafriends.com/waitlist' style={{ color: 'inherit', textDecoration: 'inherit'}}> {waitlink}  
        //                             </a>                                            
        //                 </div>
        //             </P> 
        //         </div>
        //         </div>     
        //             }


      
      
      return (

        <div>

        <div style = {{width: '20%', textAlign: 'center' , display: 'center', margin: "0 45%", paddingTop: '3em'}}> 
        <ElementImage
                content={{
                  image: imageload1,
                }}
              />
        </div>
       
        <H1> <div style={{textAlign: "center"}}> {contentfulresponse.headlineTitle} </div> </H1> 
        <H2> <div style={{textAlign: "center"}}> {contentfulresponse.subheader} </div> </H2> 


        <P>  <div style={{display: 'flex',  textAlign: "left", justifyContent:'center', textJustify: 'center', alignItems:'center', margin: "0 15%"}}> 
               {contentfulresponse.body1a} 
            </div>
        </P>

        <ShareButton title="Send Invitation"/>

        <br /> <br />

        <H2> <div style={{textAlign: "center"}}> {contentfulresponse.or} </div> </H2> 

        <P> 
            <div style={{textAlign: 'center' , display: 'center', margin: "0 15%", }}> 
                  {contentfulresponse.body2a}  
            </div> 
        </P>


        <div className="specialform">
              <Formik
                initialValues={{ email: friendinsert, name: "", yourname: "", recaptcha: "", }}

                validationSchema={yup.object().shape({
                    // name: yup.string().required(),
                    email: yup
                      .string()
                      .email()
                      .required(),
                    name: yup
                      .string()
                      .required(),
                    yourname: yup
                      .string()
                      .required(),
                    // recaptcha: yup
                    //   .string()
                    //   .required(),
                  })}

                onSubmit={(values) => {
                      this.mySubmitHandler2(values);
                      
                  }}
              >
                {({ values, touched, errors, isSubmitting, handleSubmit, setFieldValue}) => (


                  <Form onSubmit={handleSubmit}>
         
                    <div className="specialform">
                        <br />
                      <label htmlFor="name" className="speciallabel">Your friend's name</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        className={`specialinput ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="specialform">
                      <label htmlFor="yourname" className="speciallabel">Your name (as you want your friend to see it)</label>
                      <Field
                        type="text"
                        name="yourname"
                        placeholder="Enter name"
                        className={`specialinput ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>
                    
                    <div className="specialform">
                      <label htmlFor="email" className="speciallabel">Your friend's email</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className={`specialinput ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="specialform">
                        <label htmlFor="recaptcha" className="speciallabel">Recaptcha Validation</label>
                        <Recaptcha
                            sitekey="6LcnjNkZAAAAACI6rnDAPpUZ5CCBlOe17ZiD_c1y"
                            verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                        />
                        {errors.recaptcha 
                            && touched.recaptcha && (
                         <p>{errors.recaptcha}</p>
                )}
                    </div>

                    <button
                      type="submit"
                      className="specialinput"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Invite my friend"}
                    </button>
                  </Form>
                )}
              </Formik>
          </div>


    </div>

      );
    }
  }

export default InviteFriendFormik;