import React, { useState, useEffect, useContext, useRef } from 'react';
import * as firebase from "firebase/app";
import "../../firebase";
import { Chip, Avatar, Container, Select, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core';
import MaterialTable, { MaterialTableProps } from "material-table";
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircle from '@material-ui/icons/CheckCircleOutlined';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit2 from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DeleteIcon from '@material-ui/icons/Delete';
import { XylaContext } from '../../contexts/xylaContext';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import { TablePagination, TablePaginationProps } from "@material-ui/core";



// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js
// https://stackoverflow.com/questions/62596261/cant-turn-on-switch-inside-material-table
// NEED TO CHECK OUT COMPONENT OVERRIDING -> https://material-table.com/#/docs/features/component-overriding 
//  https://medium.com/@domino051/cascading-dropdown-material-table-e9025a424715
// https://stackoverflow.com/questions/61868262/getting-async-data-in-a-react-material-table-component
// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js
// 

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};















export default function AdminTable1() {
    const [todos, setTodos] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState('');
    const [toUpdateId, setToUpdateId] = useState('');
    const [data, setData] = useState([]);
    const [categoryName, setCategoryName] = React.useState([]);
    const xylacontextdata = useContext(XylaContext);

    const classes = makeStyles(iconStyles)();

    function iconStyles() {
      return {
        successIconcolor: {
          color: 'green',
        },
        editIconcolor: {
          color: 'red',
          ":disabled": {
            backgroundColor: 'blue'
          }
        },
        formControl: {
          minWidth: 120,
        }
      }
    }
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <CheckCircle {...props} ref={ref} className={classes.successIconcolor} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className={classes.editIconcolor}  />),
      Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref}  />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit2 {...props} ref={ref}    />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };


    const usertypes = [
      'Real User',
      'Test Account',
      'Xyla Team',
      'Exclude',
      'Investigate'
    ];


    const db = firebase.firestore();

    useEffect(() => {
      db.collection('searchUsers')
      .onSnapshot(snapshot => {
        var friendList = snapshot.docs.map(doc => {
          return {
            id: doc.id,
            xid: doc.data().uid,
            email: doc.data().email,
            fakeaccount: doc.data().fakeaccount,
            accountType: doc.data().accountType,
            accountNotes: doc.data().accountNotes,
            timeCreated: doc.data().timeCreated,
          }
        })
        
        // const friend_list_clean = friendList.slice(0, 3)
        const friend_list_clean = friendList

        setData(friend_list_clean);
      })
    }, []);

    // useEffect(() => {
    //   let _data = null;
    //   if (data.length == 0) {
    //     _data = todos;
    //   } else {
    //     _data = data;
    //   }
    //   setData(_data);
    // }, [todos]);


    const handlePermissionChange = rowData => (event) => {
      var statusUpdate = event.target.value;
      const dataUpdate = [...data];
      const index = rowData.tableData.id;
      dataUpdate[index] = {
        ...rowData,
        fakeaccount: statusUpdate,
      };      
      const idUpdate = rowData.id;
      setData([...dataUpdate]);
      db.collection('searchUsers').doc(idUpdate).update({
        fakeaccount: statusUpdate,
      });
    }

    const handlePermissionChangeAccountNotes = rowData => (event) => {
      var notesUpdate = event.target.value;
      const dataUpdate = [...data];
      const index = rowData.tableData.id;
      dataUpdate[index] = {
        ...rowData,
        accountNotes: notesUpdate,
      };      
      const idUpdate = rowData.id;
      setData([...dataUpdate]);
      db.collection('searchUsers').doc(idUpdate).update({
        accountNotes: notesUpdate,
      });
    }


    const handlePermissionChangeAccountType = rowData => (event) => {

      const {
        target: { value },
      } = event;
      setCategoryName(
        // On autofill we get a the stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      var typeUpdate = event.target.value;
      const dataUpdate = [...data];
      const index = rowData.tableData.id;
      dataUpdate[index] = {
        ...rowData,
        accountType: typeUpdate,
      };      
      const idUpdate = rowData.id;
      setData([...dataUpdate]);
      db.collection('searchUsers').doc(idUpdate).update({
        accountType: typeUpdate,
      });
    }




    const columns_pending = [
      {
        title: "User Email",
        field: "id",
        editable: 'never',
        render: data => ( 
        <Chip 
          label={data.id}
          color='default'
          style={{backgroundColor: '#EFEFEF'}}
          avatar = {<Avatar/>}
        />
        )
      },
      {
        title: "User XID",
        field: "xid",
        editable: 'never',
        render: data => ( 
        <Chip 
          label={data.xid}
          color='default'
          style={{backgroundColor: '#EFEFEF'}}
          avatar = {<Avatar/>}
        />
        )
      },
      {
        title: "Date (last logged in)",
        field: "timeCreated",
        editable: 'never',
        render: data => ( 
        <Chip 
          label={data.timeCreated}
          color='default'
          style={{backgroundColor: '#EFEFEF'}}
          avatar = {<Avatar/>}
        />
        )
      },

      // {
      //   title: "Account Type",
      //   field: "fakeaccount",
      //   defaultSort: "desc",
      //   sorting: false,
      //   editable: "never",
      //   render: data => {
      //       return (
      //         <FormControl className={classes.formControl}>
      //           <Select
      //             value={data.fakeaccount}
      //             onChange={handlePermissionChange(data)}
      //             inputProps={{ 'aria-label': 'Without label' }}
      //             autoWidth
      //             displayEmpty
      //           >
      //             <MenuItem value="undefined" disabled>
      //               (Select One)
      //             </MenuItem>
      //             <MenuItem value={'no'}>No</MenuItem>
      //             <MenuItem value={'yes'}>Yes</MenuItem>

      //           </Select>
      //         </FormControl>
      //       );
      //   },
      //   cellStyle: (e, rowData) => {
      //     if (rowData.fakeaccount == 'yes') {
      //       return {
      //         color: "red",
      //         fontStyle: 'italic'
      //       };
      //     } else {
      //       return {
      //         color: "green",
      //         fontStyle: 'italic'
      //       };
      //     }
      //   },
      // },


      {
        title: "Account Type",
        field: "accountType",
        defaultSort: "desc",
        sorting: true,
        editable: "never",

        render: data => {
            return (
              <FormControl className={classes.formControl} sx={{ m: 1, width: 300 }}>
                <Select
                  value={data.accountType ? data.accountType : []}
                  onChange={handlePermissionChangeAccountType(data)}
                  autoWidth
                  displayEmpty={true}
                  multiple
                  // MenuProps={MenuProps}
                //   >
                // {usertypes.map((name) => (
                //   <MenuItem
                //     key={name}
                //     value={name}
                //   >
                //     {name}
                //   </MenuItem>
                // ))}
                // </Select>
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {usertypes.map((name) => (
                  <MenuItem key={name} value={name} >
                    {name}
                  </MenuItem>
                ))}
              </Select>


              </FormControl>
            );
        }
      },




      {
        title: "Notes",
        field: "accountNotes",
        defaultSort: "desc",
        sorting: false,
        editable: "yes",
        render: data => {
            return (
              <FormControl>
                  <TextField 
                  value={data.accountNotes}
                  onChange={handlePermissionChangeAccountNotes(data)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  autoWidth
                  displayEmpty 
                  id="outlined-basic" 
                  label="account notes" 
                  variant="outlined" />
              </FormControl>
            );
        },
      }
    ]

    const options = {
      filterType: 'checkbox',
      selectableRows: false,
      filter: false,
      download: false,
      paging: true,
      pageSize: 50,
      pageSizeOptions: [10, 50, 100],
      print: false,
      viewColumns: false,
      sorting: true,
      actionsColumnIndex: 3,
    };

 
    
    
    return (
      <div>
        <Container maxWidth="large">
          <MaterialTable
            style={{ width: '100%' }}
            title=" "
            icons={tableIcons}
            columns= {columns_pending}
            options = {options}
            localization={{
                pagination: {},
                toolbar: {},
                header: {
                  actions: ''
                },
                body: {
                  editRow: { deleteText: 'Are you sure?' }
                }
            }}
            data={data}
            cellEditable = {
              {
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                  return new Promise((resolve, reject) => {
                    const dataUpdate = [...data];
                    const index = rowData.tableData.id;
                    const columnModified = columnDef.field;
                    console.log('columnModified: ', columnModified)
                    dataUpdate[index] = {
                      ...rowData,
                      fakeaccount: newValue
                    }; // this is the updated rowID 
                    const statusUpdate = newValue; // this is the updated status 
                    const idUpdate = rowData.id;
                    setData([...dataUpdate]);

                    if (columnModified == 'fakeaccount') {
                      db.collection('searchUsers').doc(idUpdate).update({
                        fakeaccount: statusUpdate
                      });
                    } else if (columnModified == 'accountNotes') {
                      db.collection('searchUsers').doc(idUpdate).update({
                        accountNotes: statusUpdate
                      });
                    } else if (columnModified == 'accountType') {
                      db.collection('searchUsers').doc(idUpdate).update({
                        accountType: statusUpdate
                      });
                    }
                    resolve();
                  });
                }
              }
            }

            editable={{
              isEditable: rowData => (rowData.fakeaccount, rowData.accountNotes, rowData.accountType),
              isEditHidden: rowData => true,
              onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const dataUpdate = [...data];
                const index = oldData.id;
                dataUpdate[index] = newData; // this is the updated rowID 
                const statusUpdate = newData.fakeaccount; // this is the updated status 
                const notesUpdate = newData.accountNotes;
                const typeUpdate = newData.accountType;
                const idUpdate = newData.id;
                setData([...dataUpdate]);
                db.collection('searchUsers').doc(idUpdate).update({
                    fakeaccount: statusUpdate,
                    accountNotes: notesUpdate,
                    accountType: typeUpdate
                });
                resolve();
              }),

          
            }}
          />
        </Container >
      </div>
    );
}
