import React, { Component } from 'react';

// https://stackoverflow.com/questions/43312223/asynchronous-call-in-componentwillmount-finishes-after-render-method

class TestComponent extends React.Component {
    
    constructor(props) {
        super();
    
        console.log('This happens 1st.');
    
        this.state = {
          loading: 'initial',
          data: ''
        };
  
    }
 
  
    
    loadData() {
        var promise = new Promise((resolve, reject) => { 
          setTimeout(() => {
            console.log('This happens 6th (after 3 seconds).');
            resolve('This is my data.');
          }, 3000);
        });
    
        console.log('This happens 4th.');
    
        return promise;
      }


    componentDidMount() {

        console.log('This happens 3rd.');
    
        this.setState({ loading: 'true' });
        this.loadData()
        .then((data) => {
          console.log('This happens 7th.');
          this.setState({
            data: data,
            loading: 'false'
          });
        });

      }  



      first = () => {
        this.setState({loading: 'part1done'})
        setTimeout(()=> {
          console.log("its at part 1.2 - firebase backup is starting up");
          console.log("First");
          this.setState({loading: null})
          this.second()
          }, 1500)
        }
      
        second = () => {
          this.setState({loading: 'part2done'})
          setTimeout(()=> {
                console.log("its at part 2.2 - firebase backup is starting up");
                console.log("Second");
                this.setState({loading: null})
                this.third()
            }, 1500)
          }
      
       third = () => {
        this.setState({loading: 'part3done'})
        setTimeout(()=> {
              console.log("its at part 3.3 - firebase backup is starting up");
              console.log("Third");
              this.setState({loading: null})
          }, 1500)
        }



      render () {


		if (this.state.loading === 'initial') {
            console.log('This happens 2nd - after the class is constructed. You will not see this element because React is still computing changes to the DOM.');
            return <h2>Intializing...</h2>;
                }


        if (this.state.loading === 'true') {
            console.log('This happens 5th - when waiting for data.');
            return <h2>Loading...</h2>;
            }

        console.log('This happens 8th - after I get data.');



		if (this.state.loading === 'part1done') {
			return <h2>Part 1 is doing stuff...</h2>;
		  }

        if (this.state.loading === 'part2done') {
            return <h2>Part 2 is doing stuff...</h2>;
            }
        
        if (this.state.loading === 'part3done') {
            return <h2>Part 3 is doing stuff...</h2>;
            }


        
        return (
            <div>
                <p>Got some data!</p>
                <p>{this.state.data}</p>
            </div>
            );

		}       



}

export default TestComponent;