import * as firebase from "firebase/app";
import "../../firebase";

const XylaColor = () => {

    if (!firebase.auth().currentUser) return '';
    
    const ref = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
    
    ref.get()
        .then(doc => {
        if (!doc.exists) {
            console.log('No such document!');
        } else {
            if(doc.get('xylacolor') != null) {
                console.log('Xyla color data:', doc.data());
            } else {
                console.log('Xyla color data does not exist!');
                var items = ['#FF7F15', '#58C2EF', '#4186F0', '#812CC9', '#E5138E', '#2AFFE2'];  
                var item = items[Math.floor(Math.random() * items.length)];
                const db = firebase.firestore();
                db.collection("users")
                .doc(firebase.auth().currentUser.uid)
                    .update({
                        xylacolor: item
                    })
            }
        }
        })
        .catch(err => {
        console.log('Error getting document', err);
        });


}

export default XylaColor;


