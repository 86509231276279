import React, { Component } from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { spacing } from '@material-ui/system';

import Xyla_logo_white from "../../illustrations/XylaBetaLogo.svg";

import UserAvatar from "../UserAvatar";





class BarWaitlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Properties
    const { performingAction, user, userData, roles } = this.props;

    // Events
    const {
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
    } = this.props;

    const { menu } = this.state;

    const menuItems = [
      
      // {
      //   name: "Profile",
      //   to: user ? `/user/${user.uid}` : null,
      // },
      {
        name: "My Xyla",
        to: user ? `/xylagraph/${user.uid}` : null,
      },
      {
        name: "Assessment",
        to: user ? `/survey` : null,
      },
      {
        name: "Share",
        to: user ? `/share` : null,
      },
      {
        name: "Waitlist",
        to: user ? `/waitlist` : null,
      },
      {
        name: "Settings",
        onClick: onSettingsClick,
      },
      {
        name: "About",
        onClick: onAboutClick,
      },
      {
        name: "Sign out",
        divide: true,
        onClick: onSignOutClick,
      },
    ];


    return (
      <AppBar style={{ backgroundImage: "linear-gradient(180deg, #2AFFE2 0%, #2F82FF 100%)" }} position="static" >
        <Toolbar>

          <Box display="flex" flexGrow={1} pt={1}>
            <Typography color="inherit" variant="h6">
            <a href="https://www.xylafriends.com">
              <   img src={Xyla_logo_white} alt={'Xyla'}/>
              </a>
            </Typography>
          
          </Box>

        </Toolbar>
      </AppBar>
    );
  }
}

BarWaitlist.defaultProps = {
  performingAction: false,
};

BarWaitlist.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default BarWaitlist;
