import React, { Component, useRef, useState, useEffect } from "react";
import FriendTable from "../friendTable/friendTable5";
import StickyFooter from "../Footer/Footer";
import {Button, ButtonGroup, Container} from '@material-ui/core';
import XylaAddFriend from "../xylaAddFriend/xylaAddFriend2.js";
import { H1, P } from "../../containers";
import { Link } from 'react-router-dom';
import "../../firebase";
import { contentClient } from "../../data/contentful";
import FeedbackModal from "../Feedback/FeedbackModal";

export default function Permissions() {
  const myRef = useRef(null)
  const [contentfulValues, setContentfulValues] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const entry = await contentClient.getEntries({
        content_type: "connections"
      });
      var entry2 = entry.items[0].fields;
      setContentfulValues(entry2);
      console.log('testtesttest: ', entry2)
    }
    fetchData();
  }, []);

  const movetocompare = () => {
    console.log('...button clicked')
  }

  return (
    <div >
      <Container maxWidth={'md'}> 
        <H1>{contentfulValues.headline1}</H1>
      </Container>
      <Container maxWidth={'md'}> 
        <P>
          {contentfulValues.paragraph1} {contentfulValues.paragraph2} 
        </P>
      </Container>
      <Container maxWidth={'md'}> 
        <div style={{
          marginRight: "1%",
          marginBottom: "50px",
          alignItems: "center"}}
        >  
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button onClick={movetocompare} component={ Link } to="/compare"> Compare Xylagraphs </Button>
          </ButtonGroup>
        </div>
      </Container>
      <FriendTable />
      <Container maxWidth={'md'}> 
        <FeedbackModal page="permission" />
      </Container>
      <StickyFooter />
    </div>
  );
}
