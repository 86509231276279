import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { contentClient, CONTENT } from "../../data/contentful";







const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '4em',
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '50vh',
    textAlign: 'center',
    fontFamily: 'Montserrat'
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footerContainer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  legalFooter: {
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    paddingLeft: '1em',
    paddingRight: '1em',
    '& > div': {
      maxWidth: 800
    }
  },
  footer: {
    padding: theme.spacing(3, 2),
    backgroundColor: '#4186f0',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
    //   theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  paper: {
    // justifyContent: 'center', 
    // color: theme.palette.text.secondary,
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
  },

  copyright: {
    color: 'white',
    fontWeight: 500,
    fontSize: 12
  },
  
  footerNav: {
    margin: 0,
    width: '100%',
    '& > .MuiGrid-grid-xs-3': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      }
    },
    '& > .MuiGrid-item':  {
      padding: 0,
    }

  }
}));




export default function StickyFooter() {
  const [legalFooterAppeared, setLegalFooterAppeared] = useState(false);
  const [contentfulValues, setContentfulValues] = useState({
    copyright: {},
    footer: {}
  });
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      const entry = await contentClient.getEntries({content_type: "footer"});
      var entry2 = entry.items[0].fields;
      console.log(entry2)
      const footer = await contentClient.getEntry(CONTENT.LEGAL_FOOTER.id);
      setContentfulValues({ copyright: entry2, footer: footer.fields });
    }
    fetchData();
  }, []);

  const checkToShowBanner = () => {
    const path = location.pathname;
    setLegalFooterAppeared(path.includes('xylagraph') || path.includes('compare'));
  };

  useEffect(() => {
    checkToShowBanner();
  }, [location.pathname]);

  const copyLine = () => {
    let defaultLine = `Xylafriends LLC. All rights reserved | New Jersey, USA.`;

    if (contentfulValues.copyright?.footerText) return contentfulValues.copyright?.footerText;
    return defaultLine;
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" className={classes.copyright} >
        {'Copyright © '} 
        {new Date().getFullYear()}
        {' '}
          {copyLine()}
      </Typography>
      
    );
  }


  return (
    <div className={classes.root}>
      <div className={classes.footerContainer}>
        {legalFooterAppeared ? 
          <div className={classes.legalFooter}>
            <Container spacing={2} maxWidth="md">
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{__html: contentfulValues.footer?.footerText}}></div>
              </Grid>
            </Container>
          </div>
        : null}

        <CssBaseline />
        <footer className={classes.footer}>
          <Container maxWidth="sm" style={{flex:1}}>
            <Grid container spacing={10} className={classes.footerNav}>
                <Grid item xs={3} sm={3}>
                  <div className={classes.paper}>
                    <a style={{ color: '#FFF', textDecoration: 'none' }} 
                      href="https://www.xylafriends.com/about"
                      target="_blank"> 
                      About
                    </a>
                  </div>    
                </Grid>
                <Grid item xs={3} sm={3}>
                  <div className={classes.paper}>
                    <a style={{ color: '#FFF', textDecoration: 'none' }}
                      href="https://www.xylafriends.com/terms"
                      target="_blank">
                      Terms
                    </a>
                  </div>    
                </Grid>
                <Grid item xs={3} sm={3}>
                  <div className={classes.paper}>
                    <a style={{ color: '#FFF', textDecoration: 'none' }}
                      href="https://www.xylafriends.com/privacy"
                      target="_blank">
                      Privacy
                    </a>
                  </div>    
                </Grid>
                {/* <Grid item xs={2} sm={2}>
                <div className={classes.paper}> <a style={{ color: '#FFF', textDecoration: 'none' }} href="https://www.xylafriends.com/faqs"> Faqs</a>  </div>    
                </Grid> */}
                <Grid item xs={3} sm={3}>
                  <div className={classes.paper}>
                    <a style={{ color: '#FFF', textDecoration: 'none' }}
                      href="https://www.xylafriends.com/contact"
                      target="_blank">
                      Contact
                    </a>
                  </div>    
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={10} className={classes.footerNav}>
              <Grid item xs={12} sm={12}>
                <Copyright />                            
              </Grid>
            </Grid>
            <br />
          </Container>
        </footer>

      </div>
    </div>
  );
}