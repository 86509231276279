import React, { Component } from "react";

import { Link } from "react-router-dom";

import { Fab } from "@material-ui/core";

import EmptyState from "../EmptyState";

import { H1, H2, P, Button2, BodyWrapper, ImageStyle, ImageStyle2, Centered, Input, Form2} from "../../containers";

import Button from '@material-ui/core/Button';

import {
  makeStyles,
  createTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    fontFamily: 'Montserrat',
  }
});

const defaultTheme = createTheme();
const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
      fontFamily: 'Montserrat',
    }
  }
});






class NotFoundPage extends Component {

  handleClick = () => {
    console.log('this is a click');
  };

  gotoLandingPage = () => {
    window.location.href = "https://app.xylafriends.com"
  }

  render() {
    return (





      <div className="container" style={{height: '100vh', background: 'linear-gradient(180deg, #930EA3 0%, #FD6058 100%)',  color: 'white'}}> 
          <BodyWrapper> 
            
            <H1> 
              <div style={{textAlign: "center", color: 'white'}}> Oops! </div> 
            </H1> 
            <H2> 
              <div style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em", color: 'white'}}> We’re so busy developing the science of friendship that we may have missed this page or something else went wrong. Sorry! </div> 
            </H2> 

            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', paddingTop: "5px", }}> 
              <MuiThemeProvider theme={defaultTheme}>
                  <MuiThemeProvider theme={theme}>
                    <Button
                        style={{ borderRadius: 50, background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)', color: 'black', height: 48 }}
                        onClick={this.handleClick}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.gotoLandingPage}
                      >
                      Back
                    </Button>
                  </MuiThemeProvider>
              </MuiThemeProvider>  
            </div>

          </BodyWrapper> 
      
      </div>                   





    );
  }
}

export default NotFoundPage;
