import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '50%',
    marginTop: '0em',
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function Element(props) {
  const classes = useStyles();

  const content = {
    'image': 'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80',
    ...props.content
  };
 
  return (
    <img 
      src={content['image']} 
      alt="" 
      className={classes.image} 
      style={{ justifyContent:'center', alignItems:'center'}}/>
  );
}