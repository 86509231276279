import React, { useEffect, useState } from 'react';
import {isMobileOnly, isTablet} from 'react-device-detect';
import styled from 'styled-components';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import { P } from '../../containers';

const checkLandscape = () => window.innerWidth > window.innerHeight;

const useScreenOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(checkLandscape());

  const updateOrientation = event => {
    setIsLandscape(checkLandscape());
  };

  useEffect(() => {
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);
  return isLandscape;
};


const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  background: white;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ComponentWithScreenOrientation = () => {
  const landscape = useScreenOrientation()
  if (!isTablet && isMobileOnly && landscape) {
    return (
      <Wrapper>
        <Rotate90DegreesCcwIcon fontSize="large"/>
        <P marginTop={12}>Please rotate your phone to view Xyla.</P>
      </Wrapper>
    )
  }
  return null
}

export default ComponentWithScreenOrientation;