import * as firebase from "firebase/app";
import "../../firebase";
  

//save user type to local storage for RBAC permissins
const RBAC = () => {
    
    if (localStorage.getItem("permissions") === null && firebase.auth().currentUser) {
            const useruid = firebase.auth().currentUser.uid
            const userdisplay = firebase.auth().currentUser.displayName
            localStorage.setItem('firebase_id', useruid);
            localStorage.setItem('firebase_display', userdisplay);
            }
};


export default RBAC;