import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Badge,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SvgIcon from '@material-ui/core/SvgIcon';
import { spacing } from '@material-ui/system';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import Xyla_logo_white from "../../illustrations/XylaBetaLogo.svg";
// import {ReactComponent as XylaGraphSVG} from "../../illustrations/xylaGraph_icon.svg";
import {ReactComponent as XylaGraphSVG} from "../../illustrations/compare_icon.svg";
import UserAvatar from "../UserAvatar";
import GlobalBanner from '../GlobalBanner';
import {
  GetShowBannerState, 
  SetShowBannerState, 
  GetSurveyState
} from '../../services/localState';
import * as firebase from "firebase/app";
import "../../firebase";
import './Bar.css';
import { withStyles } from "@material-ui/styles";
import XylaAddFriend from "../xylaAddFriend/xylaAddFriend2";

const StyledBadge = withStyles((theme) => ({
  dot: {
    padding: 5,
    borderRadius: 10
  },
}))(Badge);

const Bar = props => {
  const [bannerAppeared, setBannerAppeared] = useState(false);
  const [visiblePending, setVisiblePending] = useState(false);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [mailactionRequired, setMailactionRequired] = useState('not set');
  const [menu, setMenu] = useState({
    anchorEl: null
  });
  const location = useLocation();
  
  const db = firebase.firestore();
  const currentUID = firebase.auth().currentUser?.uid;

  const checkToShowBanner = () => {
    const path = location.pathname;
    let globalBannerShowed = GetShowBannerState();
    let surveyCompleted = GetSurveyState();
    setBannerAppeared((globalBannerShowed !== 'CLOSED') && (surveyCompleted == 'COMPLETE') && (path.includes('xylagraph') || path.includes('compare')));
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    checkToShowBanner();
    scrollTop();
  }, [location.pathname]);

  useEffect(() => {
    if (currentUID) {
      db.collection('connections')
      .where("uid_group", 'array-contains', currentUID)
      .orderBy('timestamp_computer', 'desc')
      .onSnapshot(
        snapshot => {
          console.log('----------------------');
          var friendList = snapshot.docs.map(doc => {
            return {
              id: doc.id,
              receiver: doc.data().receiver,
              receiver_email: doc.data().receiver_email,
              sender: doc.data().sender,
              sender_email: doc.data().sender_email,
              status: doc.data().status,
              datatime: doc.data().timestamp_human,
              uid_group: doc.data().uid_group,
              relation: doc.data().sender_relation,
              inviteType: doc.data().inviteType,
            }
          })
          var receiver_list = friendList
          .filter(x => x.inviteType !== 'only')
          .map(element => [element.id, element.receiver_email, element.sender, element.status, element.sender_email])
          .map(function (item) {
            return {
              id: item[0],
              friendlistEmail: item[1],
              sender: item[2],
              status: item[3],
              sender_email: item[4]
            };
          });

          var sender_list = friendList
          .filter(x => x.inviteType !== 'only')
          .map(element => [element.id, element.sender_email, element.sender, element.status, element.sender_email])
          .map(function (item) {
            return {
              id: item[0],
              friendlistEmail: item[1],
              sender: item[2],
              status: item[3],
              sender_email: item[4]
            };
          });

          const friend_list_clean = receiver_list.concat(sender_list);
          let ids = friend_list_clean.map(o => o.friendlistEmail)
          let friend_list_clean2 = friend_list_clean.filter(({
            friendlistEmail
          }, index) => !ids.includes(friendlistEmail, index + 1))

          const currentEmail = firebase.auth().currentUser.email;
          const pending_list = friend_list_clean2
          .filter(element => element.friendlistEmail != currentEmail)
          .filter(x => {
            return x.sender_email != currentEmail && x.status === 'Pending';
          });
          setVisiblePending(pending_list.length > 0);
        },
        error => {
          console.log(error);
        }
      )
    }
  }, [props.user]);

  const openMenu = (event) => {
    const anchorEl = event.currentTarget;
    setMenu({ anchorEl });
  };

  const closeMenu = () => {
    setMenu({ anchorEl: null });
  };

  const onCloseBanner = () => {
    SetShowBannerState('CLOSED');
    setBannerAppeared(false);
  };

  const onInviteClick = () => {
    setIsOpenInviteModal(true);
  }

  const { 
    performingAction, 
    user, 
    userData, 
    roles,
    onAboutClick,
    onSettingsClick,
    onSignOutClick,
    onSignUpClick,
    onSignInClick
  } = props;

  const menuItems = [
    // {
    //   name: "Profile",
    //   to: user ? `/user/${user.uid}` : null,
    // },
    {
      name: "My Xyla",
      to: user ? `/xylagraph/${user.uid}` : null,
    },
    // {
    //   name: "Assessment",
    //   to: user ? `/survey` : null,
    // },
    {
      name: "Invite",
      onClick: onInviteClick,
    },
    {
      name: "Compare",
      to: user ? `/compare` : null,
    },
    {
      name: "Permissions",
      to: user ? `/connections` : null,
    },
    // {
    //   name: "Waitlist",
    //   to: user ? `/waitlist` : null,
    // },
    {
      name: "Settings",
      onClick: onSettingsClick,
    },
    {
      name: "Version",
      onClick: onAboutClick,
    },
    {
      name: "Sign out",
      divide: true,
      onClick: onSignOutClick,
    },
  ];

  const XylaGraphIcon = (
    <SvgIcon component={XylaGraphSVG} />
  )

  return (
    <div className="nav-bar">
      <AppBar style={{ backgroundImage: "linear-gradient(180deg, #2AFFE2 0%, #2F82FF 100%)" }} position="sticky" >
        <Toolbar>
          <Box display="flex" flexGrow={1} pt={1}>
            <Typography color="inherit" variant="h6">
              <a href="/">
                <img src={Xyla_logo_white} alt={'Xyla'}/>
              </a>
            </Typography>
          </Box>

          {user && (
            <>
              {roles.includes("admin") && (
                <Box mr={1}>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/admin"
                    variant="outlined"
                  >
                    Admin
                  </Button>
                </Box>
              )}

              <Button
                title="Invite"
                color="inherit"
                disabled={performingAction}
                onClick={onInviteClick}
              >
                <Badge color="secondary">
                  <PersonAddIcon  />
                </Badge>
              </Button>

              <Button
                title="Compare"
                color="inherit"
                disabled={performingAction}
                component={Link}
                to="/compare"
              >
                <Badge color="secondary">
                  <SvgIcon component={XylaGraphSVG} viewBox="0 0 56.219 56.727" />
                </Badge>
              </Button>

              <Button
                title="Permissions"
                color="inherit"
                disabled={performingAction}
                component={Link}
                to="/connections"
              >
                <StyledBadge color="error" variant="dot" invisible={!visiblePending}>
                  <NotificationsIcon  />
                </StyledBadge>
              </Button>

              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={openMenu}
              >
                {/* <UserAvatar user={Object.assign(user, userData)} /> */}
                <MenuIcon />
              </IconButton>

              <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={closeMenu}
              >
                {menuItems.map((menuItem, index) => {
                  if (
                    menuItem.hasOwnProperty("condition") &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={menuItem.to}
                        onClick={closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          closeMenu();
                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}

          {!user && (
            <>
              <Button className="sign-buttons m-r-2" onClick={onSignUpClick}>Sign up</Button>
              <Button className="sign-buttons" variant="outlined" onClick={onSignInClick}>Sign in</Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      {/* {bannerAppeared && <GlobalBanner onClose={onCloseBanner} />} */}
      {user && (
        <XylaAddFriend 
          noButton={true}
          open={isOpenInviteModal}
          onClose={() => setIsOpenInviteModal(false)}
        />
      )}
    </div>
  );

}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default Bar;
