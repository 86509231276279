
import React, { useState, useEffect, useContext } from "react";
import * as firebase from "firebase/app";
import "../../firebase";

export default function Friendchecker() {


    if (localStorage.getItem("referral") != null && firebase.auth().currentUser) {

        const currentuserEmail = firebase.auth().currentUser.email
        const currentuserId = firebase.auth().currentUser.uid
        const referid = localStorage.getItem('referral') 
        const documentIdentifier = referid + '_' + currentuserEmail
    
        const db = firebase.firestore();
    
        // const receiver_activated = db.collection("friends_future").doc(documentIdentifier);
        // receiver_activated.get().then((doc) => {
        //         if (doc.exists) {
        //             console.log("experimenttest Document data:", doc.data());
        //             var receiver_activated_value = doc.data().receiver_activated;
        //             console.log('receiver_activated_value: ', receiver_activated_value);
        //         } else {
        //             // doc.data() will be undefined in this case
        //             console.log("No such document!");
        //         }
        //     }).catch((error) => {
        //         console.log("Error getting document:", error);
        //     });
    
        const docReffriend = db.collection("connections").doc(documentIdentifier);
        console.log(documentIdentifier);
        docReffriend.get().then((doc) => {
            let data = doc.data();
            console.log("experimenttest Document data:", data);
            if (!data) return;
            const receiver_activated_value = doc.data().receiver_activated;
            console.log('receiver_activated_value: ', receiver_activated_value);
            if (doc.exists) {
                    const status_receiver = data.status_receiver;
                    if (doc.exists && status_receiver == 'Pending') {
                        localStorage.setItem('referral_connection', JSON.stringify(data));
                    }

                    if (receiver_activated_value == 'false' || !receiver_activated_value) {
                        console.log("Testing for Friend Match:", data);
                        console.log("Testing for Friend Match - senderID: ", data.sender);
            
                        const senderUID = data.sender;
                        const senderEmail = data.sender_email;
            
                        const responsesChecks = []
            
                        const requestAAformat = senderUID + '_' + firebase.auth().currentUser.uid
                        const requestBBformat = senderUID + '_' + firebase.auth().currentUser.email
            
                        //CHECK FOR FORMAT 1 - SENDER to RECEIVER 
                        const step1 = async () => {
                                const requestAformat = senderUID + '_' + firebase.auth().currentUser.uid
                                const resultsA = await db.doc(`connections/${requestAformat}`).get()
                                const checkA = resultsA.exists.toString();
                                console.log('checkA processed - Sender to Receiver:', checkA);
                                responsesChecks.checkA = checkA;
                                step2();
                        }
    
            
                        //CHECK FOR FORMAT 2 - RECEIVER TO SENDER 
                        const step2 = async () => {
                                const requestBformat = firebase.auth().currentUser.uid + '_' + senderUID
                                const resultsB = await db.doc(`connections/${requestBformat}`).get()
                                const checkB = resultsB.exists.toString();
                                console.log('checkB processed - Receiver to Sender:', checkB);
                                responsesChecks.checkB = checkB;
                                step3();
            
                        }
            
                        //CHECK FOR FORMAT 2 - RECEIVER TO SENDER 
                        const step3 = async () => {
                                if (responsesChecks.checkA == 'false' & responsesChecks.checkB == 'false') {
                                        const timestamp = Date.now();
                                        const timestamp2 = Date().toLocaleString();
    
                                        db.collection("connections")  
                                        .doc(requestAAformat)
                                        .set(
                                                {
                                                ...data,
                                                sender: senderUID,
                                                sender_email: senderEmail,
                                                receiver: firebase.auth().currentUser.uid,
                                                receiver_activated: true,
                                                receiver_email: firebase.auth().currentUser.email,
                                                uid_group: [senderUID, firebase.auth().currentUser.uid],
                                                status: 'Pending',
                                                status_receiver: 'Pending',
                                                status_sender: 'Approved',
                                                timestamp_computer: timestamp,
                                                timestamp_human: timestamp2,
                                                },
                                                { merge: true }
                                                )
                                                .then(console.log('🔥 SUCCESFULLY STORED TO FRIENDS🔥'))
    
                                        //delete the old temp value
                                        db.collection('connections').doc(documentIdentifier).delete().then(res => {
                                            console.log('Old Temp Doc Deleted!', res);
                                          })
    
    
                                        db.collection("connections_log")  
                                            .doc(requestAAformat)
                                            .set(
                                                {
                                                ...data,
                                                sender: senderUID,
                                                sender_email: senderEmail,
                                                receiver_activated: true,
                                                receiver: firebase.auth().currentUser.uid,
                                                receiver_email: firebase.auth().currentUser.email,
                                                uid_group: [senderUID, firebase.auth().currentUser.uid],
                                                timestamp_computer: timestamp,
                                                timestamp_human: timestamp2,
                                                        },
                                                        { merge: true }
                                                        )
                                            .then(console.log('🔥 SUCCESFULLY STORED TO CONNECTIONS LOG'))
    
    
    
    
                                    //     db.collection("friends_future")  
                                    //         .doc(requestBBformat)
                                    //         .set(
                                    //                 {
                                    //                 receiver: firebase.auth().currentUser.uid, 
                                    //                 receiver_activated: 'true',
                                    //                 status: 'see friend table - user activated',
                                    //                 },
                                    //                 { merge: true }
                                    //             )
                                    //             .then(console.log('🔥 SUCCESFULLY UPDATED FRIENDS_FUTURE🔥'))
    
    
                                        } else if (responsesChecks.checkA == 'false' & responsesChecks.checkB == 'true')  {
                                        console.log("🔥 Looks like that user has already sent you a friend request 🔥")
                                        } else if (responsesChecks.checkA == 'true' & responsesChecks.checkB == 'false' )  {
                                                console.log("🔥 Looks like you have already sent a friend request to that user 🔥")
                                        } else {
                                        console.log('🔥 we have not yet configured this scenario.... 🔥')
                                        console.log("🔥 scenario not yet configured 🔥")
                                        }
                        }
            
            
                        step1(); 
                    }
        
        
        
        
            } else {

                    console.log("No such document!");


            }
            })
            // .catch(function (error) {
            // console.log("Error getting document:", error);
            // }); 
    


    }

}