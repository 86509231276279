const contentful = require("contentful");

export const contentClient = contentful.createClient({
  space: '23ep1apsm9s1',
  accessToken: 'ZlyaZ852Hbb261XzxuXyUAZvPf-7PUaTa6QEAAIcgnk',
});

export const CONTENT = {
  INVITE_TO_COMPARE_MODULE: {
    content_type: 'invite to compare module',
    name: 'Invite to Compare',
    id: '3SPoivVH76wKKMyKM3V4bX'
  },
  COMPARE_PAGE: {
    content_type: 'comparePage',
    name: 'Compare XylaGraphs',
    id: '7y2ElwnV7wsOGHuh5rQEzg'
  },
  PROMO_BANNER: {
    content_type: 'promotionAMZN',
    name: 'For a chance to win a $50 Amazon gift card',
    id: '1KIW8yzLIzX0dNb67qdOP8'
  },
  LEGAL_FOOTER: {
    content_type: 'Legal footer',
    id: 'BLGIAi4O4qS7vwaUMaupq'
  },
  FRIENDSHIP_REPORTING_MODULE: {
    content_type: 'Friendship research',
    id: '7M3p5baHx70MK8QmwSp5eq'
  },
  LANDING_PAGE: {
    id: '27tzCuSP00bemwQOfz03o0',
    content_type: 'Landing Page'
  },
  FEEDBACK_MODAL: {
    id: '49QZrmBFA9Emru9iZcP8QC',
    content_type: 'Feedback modal'
  },
  NEW_WELCOME: {
    id: '7kDWxXDHNL6pc5cqJRXZvL',
    content_type: 'Welcome'
  }
};