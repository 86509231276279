import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import InviteFriendFormik from "../InviteFriend/InviteFriendFormik";


import StickyFooter from "../Footer/Footer";


class SharePage extends Component {

  render() {
          
      return (
        

        <div>

          
          <InviteFriendFormik />

          <StickyFooter />

        </div>


          
        

        )
    }
  };

export default withRouter(SharePage);
