import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { isMobile, BrowserView, isBrowser } from 'react-device-detect';
import XylaDisplay from "./xylaDisplay";
import {Button, ButtonGroup, Grid} from '@material-ui/core';
import { H1, P } from "../../containers";
import jsonQuery from "json-query";
import * as firebase from "firebase/app";
import "../../firebase";
import { XylaContext } from '../../contexts/xylaContext';
import StickyFooter from "../Footer/Footer";
import ImageKey from '../../illustrations/xylaGraph_labels_only_3c.svg';
import WhiteCircle from "../../illustrations/whitecircle.svg";
import { Chip, Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import XylaAddFriend from "../xylaAddFriend/xylaAddFriend2.js";
import chroma from 'chroma-js';
import { contentClient } from "../../data/contentful";
import { Link, useLocation } from "react-router-dom";
import FeedbackModal from "../Feedback/FeedbackModal";
import ElementsCustom2 from "../../components-custom/ElementsCustom2"
// this example was taken from: https://codesandbox.io/s/react-hook-form-custom-input-w4zwg?file=/src/index.js:0-3228
// looping through arrays / go to 2rd answer: https://stackoverflow.com/questions/16626735/how-to-loop-through-an-array-containing-objects-and-access-their-properties
const db = firebase.firestore();
export default function Form2() {
  const [friends, setFriends] = useState([]);
  const currentUID = firebase.auth().currentUser.uid;
  const [values, setReactSelect] = useState({
      selectedOption: []
  });
  const [selectedFriends, setselectedFriends] = useState([]);
  const xylacontextdata = useContext(XylaContext);
  const [contentfulValues, setContentfulValues] = useState([]);
  const location = useLocation();

  useEffect(() => {
    clearState()
  }, [location.pathname]);

  // Need to setup some type of function to reset the state
  const clearState = () => {
    setReactSelect({
      selectedOption: []
    });
    setselectedFriends([]);
    xylacontextdata.setresultsingle([
      [{
          "axis": "Xyla Category S1",
          "value": 0,
          "category": "Feeling to Thinking"
        },
        {
          "axis": "Xyla Category S2",
          "value": 0,
          "category": "Friendship Roots: "
        },
        {
          "axis": "Xyla Category S3",
          "value": 0,
          "category": "Friendship Roots: "
        },
        {
          "axis": "Xyla Category S4",
          "value": 0,
          "category": "Friendship Roots: "
        },
        {
          "axis": "Xyla Category S5",
          "value": 0,
          "category": "Friendship Roots: "
        },
        {
          "axis": "Xyla Category S6",
          "value": 0,
          "category": "Friendship Roots: "
        },
        {
          "axis": "Xyla Category S7",
          "value": 0,
          "category": "Friendship Styles: "
        },
        {
          "axis": "Xyla Category S8",
          "value": 0,
          "category": "Friendship Styles: "
        },
        {
          "axis": "Xyla Category S9",
          "value": 0,
          "category": "Friendship Styles: "
        },
        {
          "axis": "Xyla Category S10",
          "value": 0,
          "category": "Friendship Styles: "
        },
        {
          "axis": "Xyla Category S11",
          "value": 0,
          "category": "Friendship Styles: "
        },
        {
          "axis": "Xyla Category S12",
          "value": 0,
          "category": "Friendship Styles: "
        }
      ]
    ])
    xylacontextdata.setxylacolor2([
      [{
        "color": "#"
      }]
    ])
    console.log('...reset the state')
  }

  // not this line of code below has been remove, display: non here is for the multi-remove value, 
  // it has been inserted into colourStyles
  // const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }
  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white'
    }),
    option: (styles, {
      data,
      isDisabled,
      isFocused,
      isSelected
    }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ?
          null :
          isSelected ?
          data.color :
          isFocused ?
          color.alpha(0.1).css() :
          null,
        color: isDisabled ?
          '#ccc' :
          isSelected ?
          chroma.contrast(color, 'white') > 2 ?
          'white' :
          'black' :
          data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles, {
      data
    }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, {
      data
    }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, {
      data
    }) => ({
      ...styles,
      color: data.color,
      display: 'none',
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    //scroll to top 
    window.scrollTo(0, 0);
    async function fetchData() {
      const entry = await contentClient.getEntries({
        content_type: "comparePage"
      });
      var entry2 = entry.items[0].fields;
      setContentfulValues(entry2);
      console.log('testtesttest: ', entry2)
    }
    fetchData();

    db.collection('connections')
    .where("uid_group", 'array-contains', currentUID)
    .where('status', '==', 'Approved')
    .orderBy('timestamp_computer', 'desc')
    .onSnapshot(async snapshot => {
      const friendList = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          receiver: doc.data().receiver,
          receiver_email: doc.data().receiver_email,
          sender: doc.data().sender,
          sender_email: doc.data().sender_email,
          status: doc.data().status,
          datatime: doc.data().timestamp_human,
          uid_group: doc.data().uid_group,
        }
      });
      if (friendList.length == 0) return;
      const receiver_list = friendList.map(
        element => 
          [element.receiver, element.receiver_email]
      );
      const receiver_list_mod = receiver_list.map(
        function (item) {
          return {
            email_id: item[0],
            email_h: item[1]
          };
        }
      )

      const sender_list = friendList.map(
        element => 
          [element.sender, element.sender_email]
      );
      const sender_list_mod = sender_list.map(
        function (item) {
          return {
            email_id: item[0],
            email_h: item[1]
          };
        }
      );

      const friend_list_clean = receiver_list_mod.concat(sender_list_mod);

      let ids = friend_list_clean.map(o => o.email_id)
      let friend_list_clean2 = friend_list_clean.filter(({
        email_id
      }, index) => !ids.includes(email_id, index + 1))

      // Get suvery status for each user id
      for (let user of friend_list_clean2) {
        const docRef2 = db.collection("survey_complete").doc(user.email_id);
        const doc = await docRef2.get();
        user.completed = doc.exists;
      }
      // except current user email
      let currentUser = friend_list_clean2.filter(x => x.email_id == currentUID)[0];
      currentUser.current = true;
      // Get other users 
      let otherCompletedUsers = friend_list_clean2.filter(x => x.email_id !== currentUID && x.completed);
      let otherInCompletedUsers = friend_list_clean2.filter(x => x.email_id !== currentUID && !x.completed);
      // sort
      otherCompletedUsers.sort((x, y) => x.email < y.email ? 1 : -1);
      otherInCompletedUsers.sort((x, y) => x.email < y.email ? 1 : -1);
      let friend_list = [currentUser, ...otherCompletedUsers, ...otherInCompletedUsers];
      if (!currentUser.completed) {
        friend_list = [...otherCompletedUsers, currentUser, ...otherInCompletedUsers];
      }
      setFriends(friend_list);
      
      
      /// experimental // getting user xyla colors 
      /// experimental // getting user xyla colors 
      const table = friend_list_clean2.map(element => [element.email_id]); //.flat();
      let usercolors = [];

      async function firstFunction() {
        for (let i of table) {
          console.log(i[0])
          db.collection('users').where('__name__', '==', i[0]).onSnapshot(snapshot => {
            let userdetails = snapshot.docs.map(doc => {
              return {
                email_id: doc.id,
                xylacolor: doc.data().xylacolor,
              }
            })
            usercolors.push(userdetails[0])
            // setArray((arraycolors) => [...arraycolors,userdetails[0],]);
          })
        }
        return;
      };

      async function secondFunction() {
        await firstFunction();
        console.log('maybe it worked....')
        console.log('usercolors: ', usercolors);
        var obj = usercolors;
        const vv11 = obj
        console.log('vv11: ', JSON.stringify(vv11))
        // console.log('newcustom: ', arraycolors)
      };

      secondFunction();
    })
  }, []);

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = data => {
    const val1 = data.reactSelect[0].value
    const dataValue = Object.values(data);
    const dataValue2 = dataValue[0]
    const dataValue3 = dataValue2.map(element => element.value);
    const dataValue4 = dataValue2.map(element => element.label);
    setselectedFriends(dataValue4);
    const db = firebase.firestore();
    // NOTES -> will need to loop through the requests that are inserted into the query
    // and UPDATE the state/CONTEXT / - adding to the ARRAY 
    // check out the 'SHAREEMAIL.js' file which has a example of 1) looping through the 
    // requests that need to be pinged 
    // How to update the array -> https://medium.com/javascript-in-plain-english/how-to-add-to-an-array-in-react-state-3d08ddb2e1dc 

    //THIS PART BELOW IS UPDATED SECTION FOR DOING IT CORRECTLY 

    dataValue3.map(item => (
      db.collection("survey_complete").doc(item).get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          console.log("Document data:", data);
          let tempvar = jsonQuery('surveyscored', {
            data: data
          }).value
          console.log('xyla values for that user: ', tempvar)
          let responseXyla = [{
              "axis": "Xyla Category S1",
              "value": (((JSON.parse(tempvar)).s1[0]) + 1),
              "category": "Feeling to Thinking"
            },
            {
              "axis": "Xyla Category S2",
              "value": (((JSON.parse(tempvar)).s2[0]) + 1),
              "category": "Friendship Roots: "
            },
            {
              "axis": "Xyla Category S3",
              "value": (((JSON.parse(tempvar)).s3[0]) + 1),
              "category": "Friendship Roots: "
            },
            {
              "axis": "Xyla Category S4",
              "value": (((JSON.parse(tempvar)).s4[0]) + 1),
              "category": "Friendship Roots: "
            },
            {
              "axis": "Xyla Category S5",
              "value": (((JSON.parse(tempvar)).s5[0]) + 1),
              "category": "Friendship Roots: "
            },
            {
              "axis": "Xyla Category S6",
              "value": (((JSON.parse(tempvar)).s6[0]) + 1),
              "category": "Friendship Roots: "
            },
            {
              "axis": "Xyla Category S7",
              "value": (((JSON.parse(tempvar)).s7[0]) + 1),
              "category": "Friendship Styles: "
            },
            {
              "axis": "Xyla Category S8",
              "value": (((JSON.parse(tempvar)).s8[0]) + 1),
              "category": "Friendship Styles: "
            },
            {
              "axis": "Xyla Category S9",
              "value": (((JSON.parse(tempvar)).s9[0]) + 1),
              "category": "Friendship Styles: "
            },
            {
              "axis": "Xyla Category S10",
              "value": (((JSON.parse(tempvar)).s10[0]) + 1),
              "category": "Friendship Styles: "
            },
            {
              "axis": "Xyla Category S11",
              "value": (((JSON.parse(tempvar)).s11[0]) + 1),
              "category": "Friendship Styles: "
            },
            {
              "axis": "Xyla Category S12",
              "value": (((JSON.parse(tempvar)).s12[0]) + 1),
              "category": "Friendship Styles: "
            }
          ]
          let responseCombined = responseXyla
          xylacontextdata.setresultsingle(resultsingle => [...resultsingle, responseCombined])

        } else {
          console.log("No Xyla graph yet completed for that user");
          alert("No Xyla graph yet completed for that user");
        }
      }).catch(function (error) {
        console.log("No Xyla graph yet completed for that user", error)
      })

    ));

    // THIS PART IS NOT YET INCORPORATED WITH THE XYLA COLORS
    dataValue3.map(item => (
      db.collection("users").doc(item).get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          console.log("Document data for xylacolor:", data);
          var tempvarcolor = jsonQuery('xylacolor', {
            data: data
          }).value
          console.log("ChecktempColor: ", tempvarcolor)
          let colortokeep = [{
            "color": tempvarcolor
          }]
          console.log("savedresponsecolor: ", colortokeep)
          xylacontextdata.setxylacolor2(xylacolor2 => [...xylacolor2, colortokeep])
        } else {
          console.log("xyla user color does not exist!");
        }
      }).catch(function (error) {
        console.log("No Xyla graph yet completed for that user", error)
      })
    ));
  };

  const handleMultiChange = selectedOption => {
      setValue("reactSelect", selectedOption);
      setReactSelect({ selectedOption });
  };

  useEffect(() => {
      register({ name: "reactSelect" });
  }, [register]);

  const DisplayKey1 = () => (
      <div style={{}}>
        <Chip
          label={selectedFriends[0]}
          color='default' 
          style={{backgroundColor:'#8cc5c2'}}
          avatar={<Avatar  />}
        />
      </div>
    // </div>
  )

  const DisplayKey2 = () => (
    <div style={{}}>
      <Chip
        label={selectedFriends[0]}
        color='default' 
        style={{backgroundColor:'#8cc5c2', marginBottom: '1em'}}
        avatar={<Avatar  />}
      />
      <br />
      <Chip
        label={selectedFriends[1]}
        color='default' 
        style={{backgroundColor:'#fbf08e'}}
        avatar={<Avatar  />}
      />
    </div>
  );

  const XylaDisplayKey = () => {
    if (selectedFriends.length == 1) {
      return (
        DisplayKey1()
      )
    } else if (selectedFriends.length == 2) {
      return (
        DisplayKey2()
      )
    } else if (selectedFriends.length < 1) {
      return (
        <p></p>
      )
    } 
  };

  const myRef = useRef(null)
  
  const executeScroll = () => myRef.current.scrollIntoView()

  var graphHeight = 610;
  var lineWidth = 500;
  if (isMobile) {
    graphHeight = 510;
    lineWidth = 430;
  }

  return (
    <div>
      <div> 
        <div className="specialform2">
          <H1 center> 
            {contentfulValues.header1} 
          </H1>
        </div>

        <div className="container"> 
          <div className="specialform3" style={{marginTop: '-8em'}}>
            <P>
              {contentfulValues.copy1} 
            </P>
            <div className="chart-container">
              <img className="chart-axis" src={ImageKey} alt='logo' style={{width: lineWidth}} />
              <XylaDisplay />
              {/* <div className="imgcontainer" style={{zIndex:1}}>  
                <Avatar alt="xylaface" src={WhiteCircle} style={{background: 'white', width:'4.4em', height:'4.4em'}}  />
              </div> */}
            </div>
            <div className="chart-display-user">
              <XylaDisplayKey />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Select
                  className="reactSelect"
                  name="filters"
                  placeholder="Select Friends"
                  value={values.selectedOption}
                  isClearable={false}
                  options={friends.map(friend=>({
                    value: friend.email_id, 
                    label: [friend.email_h.split('@')[0]] + (friend.current ? ' (you)': ''), 
                    color: '#2c3e50',
                    isDisabled: !friend.completed
                  }))}
                  // options={colourOptions}
                  onChange={(v) => (v && v.length <= 2) ? handleMultiChange(v): null}
                  isMulti
                  styles={colourStyles}
                />
              </div>
              <br />
              <div style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "0.5em",
                marginBottom: "50px",
                }}>
                  <Button color="primary" type="submit" variant="contained" disabled={selectedFriends.length >= 1}> {contentfulValues.buttonCompare} </Button>
                  <Button color="primary" onClick={clearState}> {contentfulValues.buttonReset} </Button>
              </div>
            </form>
            <P> 
              <div style={{textAlign: "left", color: "inherit", textDecoration: "none", marginTop: '2em'}}> 
              {contentfulValues.copyPermissions1}
              </div>
            </P>

            <P marginBottom={0}> 
              <div style={{textAlign: "left", color: "inherit", textDecoration: "none", marginTop: '2em'}}> 
              {contentfulValues.copyPermissions2}
              </div>
            </P>
          </div>
        </div>  
      </div>

      <br/>
      <div className="Styled-my-4">
        <Grid container spacing={0}>
          {isBrowser ? 
            <Grid item xs={12} lg={4}>
            </Grid>
          : null}
          <Grid item xs={7} lg={4} style={{ margin: 'auto'}}>
            <hr className='compare-divider' />
          </Grid>
          {isBrowser ?
            <Grid item xs={12} lg={4}>
            </Grid>
          : null}
        </Grid>
      </div>
      <br/>

      
      <div className="specialform3">
        <P>
          <div style={{textAlign: "left", color: "inherit", textDecoration: "none"}}> 
          {contentfulValues.copy2} 
          </div>
        </P>
      </div>

      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"}}
      >  
        <div style={{
          marginLeft: "1%",
          marginRight: "1%",
          martinTop: "2em",
          alignItems: "center"}}
        >  
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button> <XylaAddFriend /> </Button>
            <Button component={Link} to="/connections" variant="outlined"> {contentfulValues.buttonJump}</Button>
          </ButtonGroup>
        </div>
      </div> 
      <div className="specialform3">
        <P> 
          <div style={{textAlign: "left", color: "inherit", textDecoration: "none", paddingTop: '2em'}}> 
          {contentfulValues.copy3} 
          </div>
        </P>
        <FeedbackModal page="compare" />
      </div>
      <StickyFooter />
    </div>
  );
}

