import React, {useState, useRef, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { 
  Grid, Button, FormLabel, Input, Radio, RadioGroup, Typography, IconButton,
  FormControl, FormControlLabel, Select, MenuItem, OutlinedInput, Checkbox,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle as MuiDialogTitle
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Close as CloseIcon } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap'
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, blue } from '@material-ui/core/colors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import DoneIcon from '@material-ui/icons/Done';

import InfoIcon from '@material-ui/icons/Info';
import * as firebase from "firebase/app";
import { P } from '../../containers';
import "../../firebase";
import XylaButton from "../XylaButton/XylaButton";
import { contentClient, CONTENT } from "../../data/contentful";
import "./style.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: 'lightgrey',
  },
  tooltip: {
    borderColor: 'lightgrey',
    borderWidth: 1,
    borderStyle: 'solid',
    color: theme.palette.common.black,
    fontSize: 12.5,
    backgroundColor: theme.palette.common.white
  },
  checkboxLabel: {
    '&> .MuiFormControlLabel-label': {
      fontSize: 14,
      fontFamily: "'Helvetica', sans-serif"
    }
  }
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useButtonStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    marginLeft: 0,
    position: 'relative',
    '&> button': {
      width: 89.89,
      height: 36
    }
  },
  buttonSuccess: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonDone: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: '-12px !important',
  },
  checkbox: {
    '&$checked': {
      color: blue[600],
    },
  }
}));

export default function FeedbackModal(props) {
  const LIMIT_CONTENT_LENGTH = 1000;

  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  // const [unsubscribeSnapshot, setUnsubscribeSnapshot] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [connection, setConnection] = useState({});
  const [connectionList, setConnectionList] = useState([]);
  const [contentfulValues, setContentfulValues] = useState({ modal: {} });
  const [isValidForm, setValidForm] = useState(false);

  const [counter, setCounter] = useState(LIMIT_CONTENT_LENGTH);
  const [text, setText] = useState('');
  const [optIn, setOptIn] = useState(false);
  const [disableTextarea, setDisableTextarea] = useState(false);

  const btnClasses = useButtonStyles();
  const classes = useStylesBootstrap();
  const buttonClassname = clsx({
    [btnClasses.buttonSuccess]: confirmed,
  });

  const location = useLocation();

  const db = firebase.firestore();
  const currentEmail = firebase.auth().currentUser?.email;
  const currentId = firebase.auth().currentUser?.uid;

  // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  // useEffect(() => {
  //   setOpen(props.open);
  // }, [props.open])

  useEffect(() => {
    async function fetchData() {
      const { fields } = await contentClient.getEntry(CONTENT.FEEDBACK_MODAL.id);
      setContentfulValues({ modal: { ...fields } });
    }
    fetchData();
  }, []);
  
  useEffect(() => {
    if (text.length > 0) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [text, optIn]);

  const makeFeedback = async () => {
    const docReffriend = db.collection("users").doc(currentId);
    await sleep(2000);
    const timestamp = Date.now();
    const timestamp2 = Date().toLocaleString();
    
    docReffriend
    .set(
      {
        [timestamp]: {
          source_page: props.page,
          feedback: text,
          contact_ok: optIn,
          date: timestamp2,
        }
      },
      { merge: true }
    )
    .then(async () => {
      setConfirmed(true);
      setUpdating(false);
      await sleep(3000);
      handleClose();
    })
  };

  const checkSamePage = async () => {
    const docReffriend = db.collection("users").doc(currentId);
    let result = await (await docReffriend.get()).data();
    let timestamps = [];
    for (let key in result) {
      if (result[key] && result[key].feedback && result[key].source_page == props.page) {
        timestamps.push(key);
      }
    }
    let latestTimestamp = Math.max(...timestamps);
    const now = Date.now();
    let diff = (now - latestTimestamp) / 1000 / 60 - 3;
    if (diff < 0) {
      setDisableTextarea(true);
    }
  }

  const cleanForm = () => {
    setText('');
    setOptIn(false);
    setConfirmed(false);
  }

  const handleClose = () => {
    // Implement logic for hitting X
    setOpen(false);
    cleanForm();
    if (props.onClose) props.onClose();
  };

  const handleTextChange = event => {
    let counter = LIMIT_CONTENT_LENGTH - event.target.value.length;
    if (counter >= 0) {
      setCounter(counter);
      setText(event.target.value);
    }
  };

  const handleCheckChange = event => {
    setOptIn(event.target.checked);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!updating && !confirmed) {
      setUpdating(true);
      makeFeedback();
    }
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      if (callback) callback();
    }
  };

  const triggerModal = () => {
    setOpen(true);
    // checkSamePage();
  }

  const renderButton = () => {
    return (
      <div className="feedback-trigger">
        <p className="feedback-description"> Got Feedback? <a onClick={triggerModal}>Let us know.</a></p>
      </div>
    )
  }

  return (
    <div>
      {renderButton()}
      <Dialog className="feedback-modal" fullWidth maxWidth="sm" open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" onClose={() => handleClose(true)}>{contentfulValues.modal.title}</DialogTitle>
        <DialogContent id="form-dialog-content">
          <Form id="invite-form" className="feedback-form" onSubmit={handleSubmit} onKeyDown={e => { handleKeyDown(e) }}>
            <Form.Group>
              <FormLabel>{contentfulValues.modal.greeting || ''}</FormLabel>
              <FormControl margin='dense' fullWidth={true}>
                <Form.Control disabled={disableTextarea} value={text} onChange={handleTextChange} as="textarea" rows={10} placeholder={contentfulValues.modal.instructions}/>
                <Form.Text className={`text-right ${counter <=0 ? 'text-danger': ''}`} muted>
                  {counter} characters remaining
                </Form.Text>
              </FormControl>
            </Form.Group>

            <Form.Group>
              <FormControlLabel
                control={
                  <Checkbox
                    className={btnClasses.checkbox}
                    disabled={disableTextarea}
                    checked={optIn}
                    onChange={handleCheckChange}
                  />
                }
                className={classes.checkboxLabel}
                label={contentfulValues.modal.contactOptIn}
              />
            </Form.Group>

            <div className="dialog-action friendship-action">
              <div className={btnClasses.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  className={buttonClassname}
                  disabled={!isValidForm} 
                  type="submit"
                >
                  {confirmed 
                  ? (
                    <IconButton>
                      <DoneIcon size={24} className={btnClasses.buttonDone} />
                    </IconButton>
                  )
                  :updating
                    ? (
                      <IconButton>
                        <CircularProgress size={24} className={btnClasses.buttonProgress} />
                      </IconButton>
                    )
                    : 'Submit'}
                  
                </Button>
              </div>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </div>

            {confirmed ? 
              <div className="friendship-confirm">
                <Alert icon={false} severity="success">Message sent!</Alert>
              </div>
            : null}
          </Form>
          
        </DialogContent>
      </Dialog>
    </div>

  );
}