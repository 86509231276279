import React, { useState, useEffect, useContext, useRef } from 'react';
import * as firebase from "firebase/app";
import "../../firebase";
import { Chip, Avatar, Container, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircle from '@material-ui/icons/CheckCircleOutlined';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit2 from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DeleteIcon from '@material-ui/icons/Delete';
import { XylaContext } from '../../contexts/xylaContext';
import { makeStyles } from '@material-ui/core/styles';

// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js
// https://stackoverflow.com/questions/62596261/cant-turn-on-switch-inside-material-table
// NEED TO CHECK OUT COMPONENT OVERRIDING -> https://material-table.com/#/docs/features/component-overriding 
//  https://medium.com/@domino051/cascading-dropdown-material-table-e9025a424715
// https://stackoverflow.com/questions/61868262/getting-async-data-in-a-react-material-table-component
// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js
// 

export default function FriendTable() {
    const [todos, setTodos] = useState([]);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState('');
    const [toUpdateId, setToUpdateId] = useState('');
    const [data, setData] = useState([]);
    const [mailactionRequired, setmailactionRequired] = useState([]);
    const xylacontextdata = useContext(XylaContext);

    const classes = makeStyles(iconStyles)();

    function iconStyles() {
      return {
        successIconcolor: {
          color: 'green',
        },
        editIconcolor: {
          color: 'red',
          ":disabled": {
            backgroundColor: 'blue'
          }
        },
        formControl: {
          minWidth: 120,
        }
      }
    }
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <CheckCircle {...props} ref={ref} className={classes.successIconcolor} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className={classes.editIconcolor}  />),
      Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref}  />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit2 {...props} ref={ref}    />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const db = firebase.firestore();

    const currentUID = firebase.auth().currentUser.uid;
    const statusOptions = {
      'Pending': '(Select One)',
      'Approved': 'Approved',
      'Ignore': 'Not Approved'
    }

    const loadMailboxstatus = () => {
      const db = firebase.firestore();
      const currentUID = firebase.auth().currentUser.uid;
      console.log('userID: ', currentUID)
      var docRef = db.collection("users").doc(currentUID);
      docRef.get().then((doc) => {
        if (doc.exists) {
          setmailactionRequired(doc.data().friendactionRequired);
          xylacontextdata.setmailboxiconStatus(doc.data().friendactionRequired);
          console.log('testing - permission mailicon reload VALUE STATUS: ', doc.data().friendactionRequired)
        } else {
          console.log('testing - permission mailicon reload - nothing there')
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
      console.log('testing - permission mailicon reload FINISHED')
    }
    useEffect(() => {
      db.collection('connections')
      .where("uid_group", 'array-contains', currentUID)
      .orderBy('timestamp_computer', 'desc')
      .onSnapshot(snapshot => {
        var friendList = snapshot.docs.map(doc => {
          return {
            id: doc.id,
            receiver: doc.data().receiver,
            receiver_email: doc.data().receiver_email,
            sender: doc.data().sender,
            sender_email: doc.data().sender_email,
            status: doc.data().status,
            datatime: doc.data().timestamp_human,
            uid_group: doc.data().uid_group,
            relation: doc.data().sender_relation,
            inviteType: doc.data().inviteType,
          }
        })
        friendList = friendList.filter(x => x.inviteType !== 'only');
        const receiver_list = friendList.map(element => [element.id, element.receiver_email, element.sender, element.status, element.sender_email]);
        const receiver_list_mod = receiver_list.map(function (item) {
          return {
            id: item[0],
            friendlistEmail: item[1],
            sender: item[2],
            status: item[3],
            sender_email: item[4]
          };
        })

        const sender_list = friendList.map(element => [element.id, element.sender_email, element.sender, element.status, element.sender_email]);
        const sender_list_mod = sender_list.map(function (item) {
          return {
            id: item[0],
            friendlistEmail: item[1],
            sender: item[2],
            status: item[3],
            sender_email: item[4]
          };
        })

        const friend_list_clean = receiver_list_mod.concat(sender_list_mod);

        let ids = friend_list_clean.map(o => o.friendlistEmail)
        let friend_list_clean2 = friend_list_clean.filter(({
          friendlistEmail
        }, index) => !ids.includes(friendlistEmail, index + 1))

        const currentEmail = firebase.auth().currentUser.email;
        const friend_list_clean3 = friend_list_clean2.filter(element => element.friendlistEmail != currentEmail);
        const friend_list_clean4 = friend_list_clean3

        var length = friend_list_clean4.length;
        for (var i = 0; i < length; i++) {
          if (friend_list_clean4[i].sender_email === firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Pending') {
            friend_list_clean4[i].sortIndex = 0;
            friend_list_clean4[i].displayField = 'Waiting on friend';
            friend_list_clean4[i].displayField2 = 'Waiting on friend';
          } else if (friend_list_clean4[i].sender_email != firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Pending') {
            friend_list_clean4[i].sortIndex = 3;
            friend_list_clean4[i].displayField = 'Waiting for you!';
            friend_list_clean4[i].displayField2 = 'Approved';
          } else if (friend_list_clean4[i].status === 'Approved') {
            friend_list_clean4[i].sortIndex = 2;
            friend_list_clean4[i].displayField = 'Connected';
            friend_list_clean4[i].displayField2 = 'Approvied';
          } else if (friend_list_clean4[i].status === 'Denied') {
            friend_list_clean4[i].sortIndex = 0;
            friend_list_clean4[i].displayField = 'Waiting on friend';
            friend_list_clean4[i].displayField2 = 'Denied';
          } else if (friend_list_clean4[i].sender_email != firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Ignore') {
            friend_list_clean4[i].sortIndex = 1;
            friend_list_clean4[i].displayField = 'Not Connected';
            friend_list_clean4[i].displayField2 = 'Not Connected';
          } else if (friend_list_clean4[i].sender_email == firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Ignore') {
            friend_list_clean4[i].sortIndex = 0;
            friend_list_clean4[i].displayField = 'Waiting on friend';
            friend_list_clean4[i].displayField2 = 'Waiting on friend';
          }
        }

        // SETTING UP THE STATE TO SHOW IF THE RED ICON OVER MAIL BOX SHOULD BE DISPLAYED 
        if (friend_list_clean4.filter(function (e) {
            return e.displayField === 'Approvide / Decline';
          }).length > 0) {
          console.log('Mailbox Icon: it contains a pending value that should display the red icon over mail box!')
          db.collection('users').doc(currentUID).update({
            friendactionRequired: 'yes'
          });
        } else {
          console.log('Mailbox Icon: it does not contain a value that warrants the icon to be shown!')
          db.collection('users').doc(currentUID).update({
            friendactionRequired: 'no'
          });
        }
        setTodos(friend_list_clean4);
      })
    }, []);

    useEffect(() => {
      let _data = null;
      if (data.length == 0) {
        _data = todos.sort((x, y) => x.sortIndex < y.sortIndex ? 1 : -1);
      } else {
        _data = data.map(x => {
          let item = todos.find(y => x.id == y.id);
          if (!item) return null;
          return { ...item, sortIndex: x.sortIndex }
        }).filter(x => x);
        if (data.length < todos.length) {
          const [item, ...results] = todos.filter(t => !_data.some(x => t.id === x.id));
          _data.unshift({ ...item, sortIndex: item.displayField == 'Waiting on friend' ? 0 : 3 });
        }
      }
      setData(_data);
    }, [todos]);

    const deleteTodo = (id) => {
      db.collection('friends').doc(id).delete().then(res => {
        console.log('Deleted!', res);
      });
    }

    const openUpdateDialog = (todo) => {
      setOpen(true);
      setToUpdateId(todo.id);
      setUpdate(todo.status);
    }

    const openUpdateDialog2 = (id, status) => {
      setOpen(true);
      setToUpdateId(id);
      setUpdate(status);
    }

    const editTodo = () => {
      db.collection('friends').doc(toUpdateId).update({
        status: update
      });
      setOpen(false);
    }

    const handleClose = () => {
      setOpen(false);
    };

    const handlePermissionChange = rowData => (event) => {
      var statusUpdate = event.target.value;
      const dataUpdate = [...data];
      const index = rowData.tableData.id;
      dataUpdate[index] = {
        ...rowData,
        status: statusUpdate
      };      
      const idUpdate = rowData.id;
      setData([...dataUpdate]);
      db.collection('connections').doc(idUpdate).update({
        status: statusUpdate
      });
    }

    const columns_pending = [
      {
        title: "Friend's Email",
        field: "friendlistEmail",
        editable: 'never',
        render: data => ( 
        <Chip 
          label={data.friendlistEmail}
          color='default'
          style={{backgroundColor: '#EFEFEF'}}
          avatar = {<Avatar/>}
        />
        )
      },
      {
        title: "Status",
        field: "sortIndex",
        // defaultSort: "desc",
        editable: 'never',
        render: data => ( 
          <Chip 
            label={data.displayField}
            color = 'default'
            style = {
              data.status === 'Approved' ? {
                backgroundColor: '#78e08f'
              } : data.displayField === 'Waiting for you!' ? {
                backgroundColor: '#E8E8E8',
                color: 'red',
                fontStyle: 'italic'
              } : data.displayField === 'Not Connected' ? {
                backgroundColor: '#58C2EF',
                fontStyle: 'italic'
              } : {
                backgroundColor: '#f7d794'
              }
            }
          />
        ),
      },
      {
        title: "Permission to share",
        field: "status",
        defaultSort: "desc",
        sorting: false,
        editable: "never",
        render: data => {
          if (data.sender != currentUID) {
            // return statusOptions[data.status];
            return (
              <FormControl className={classes.formControl}>
                <Select
                  value={data.status}
                  onChange={handlePermissionChange(data)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  autoWidth
                  displayEmpty
                >
                  <MenuItem value="Pending" disabled>
                    (Select One)
                  </MenuItem>
                  <MenuItem value={'Approved'}>Approved</MenuItem>
                  <MenuItem value={'Ignore'}>Not Approved</MenuItem>
                </Select>
              </FormControl>
            )
          }
          return;
        },
        // lookup: statusOptions,
        cellStyle: (e, rowData) => {
          if (rowData.status == 'Pending') {
            return {
              color: "red",
              fontStyle: 'italic'
            };
          } else {
            return {
              color: "black",
              fontStyle: 'italic'
            };
          }
        },
      },
    ]

    const options = {
      filterType: 'checkbox',
      selectableRows: false,
      filter: false,
      download: false,
      paging: false,
      pageSize: 50,
      pageSizeOptions: [5, 25, 50],
      print: false,
      viewColumns: false,
      sorting: true,
      actionsColumnIndex: 3
    };
    
    return (
      <div>
        <Container maxWidth="md">
          <MaterialTable
            title=" "
            icons={tableIcons}
            // columns= { rowData => rowData.status === 'Approved' ? columns_pending : columns_notpending }
            columns= {columns_pending}
            // options={{actionsColumnIndex: -1}}
            options = {options}
            localization={{
                pagination: {},
                toolbar: {},
                header: {
                  actions: ''
                },
                body: {
                  editRow: { deleteText: 'Are you sure?' }
                }
            }}
            data={data}
            cellEditable = {
              {
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                  return new Promise((resolve, reject) => {
                    const dataUpdate = [...data];
                    // const index = oldData.tableData.id;
                    const index = rowData.tableData.id;
                    dataUpdate[index] = {
                      ...rowData,
                      status: newValue
                    }; // this is the updated rowID 
                    const statusUpdate = newValue; // this is the updated status 
                    const idUpdate = rowData.id;
                    setData([...dataUpdate]);
                    db.collection('connections').doc(idUpdate).update({
                      status: statusUpdate
                    });
                    resolve();
                  });
                }
              }
            }

            editable={{
              isEditable: rowData => (rowData.sender != currentUID),
              isEditHidden: rowData => true,
              isDeletable: rowData => (rowData.sender === currentUID || rowData.sender != currentUID),
              deleteTooltip: rowData => 'Remove friend',

              onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const dataUpdate = [...data];
                const index = oldData.id;
                dataUpdate[index] = newData; // this is the updated rowID 
                const statusUpdate = newData.status; // this is the updated status 
                const idUpdate = newData.id;
                setData([...dataUpdate]);
                db.collection('connections').doc(idUpdate).update({
                  status: statusUpdate
                });
                resolve();
              }),

              onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => { // check out this - potentially remove 
                  const dataDelete = [...data];
                  const firebaseIndex = oldData.id;

                  // NEED TO ADD IN THE BIT HERE NOW TO ACCOUNT FOR DELETING A PERSON THAT WENT THROUGH
                  // FRIEND REQUEST ROUTE -- e.g., function in referallID could potentially re-instate 
                  // request automatically 
                  // Need to come back here and look at the ReferralCapture.js code to bring over potentially
                  // lines between 26-40 - somewhere around there 
                  const hashId = oldData.tableData.id;
                  const hashId2 = oldData.id;
                  console.log({
                    'hashId2': hashId2,
                    'hashId': hashId
                  })
                  const numericIndex = dataDelete.findIndex(d => d.id === hashId2);
                  dataDelete.splice(numericIndex, 1);
                  db.collection('connections').doc(firebaseIndex).delete().then(res => {
                    console.log('Deleted!', res);
                  });
                  setData([...dataDelete]);
                  resolve();
                }, 1000)
              }),
            }}
          />
        </Container >
      </div>
    );
}
