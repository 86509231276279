import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import { spacing } from "@material-ui/system";

import {
    makeStyles,
    createTheme,
    MuiThemeProvider
  } from "@material-ui/core/styles";




const useStyles = makeStyles({
    button: {
      textTransform: "none",
    }
  });
  
const defaultTheme = createTheme();
const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      }
    }
  });


class XylaButton extends Component {


    constructor(props) {
        super(props);
      }


      // background: linear-gradient(180deg, #2AFFE2 0%, #2F82FF 100%);
      // color: white;
      // /* text-transform: uppercase; */
      // border: none;
      // margin-top: 40px;
      // padding: 20px;
      // font-size: 17px;
      // font-weight: 500;
      // letter-spacing: 0px;
      // border-radius: 15px;
      // font-family: 'Montserrat';



    render() {
        return (
            <div style={{paddingTop: '5px'}}>
                <MuiThemeProvider theme={defaultTheme}>
                    <MuiThemeProvider theme={theme}>
                                <Button
                                    style={{ 
                                          borderRadius: 50, 
                                          background: this.props.buttonbackground, 
                                          color: 'white', 
                                          fontFamily: 'Montserrat',
                                          fontSize: 17,
                                          height: 48, }}
                                    onClick={this.props.buttonfunction}
                                    variant="contained"
                                    color="primary"
                                    size="large"  

                                >
                                    {this.props.title}
                                </Button>
                    </MuiThemeProvider>
                </MuiThemeProvider>
            </div>
        );
  }
}


  export default XylaButton;
