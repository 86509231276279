import styled from "styled-components";
import { metrics } from "../../themes";

const P = styled.div`
  margin-top: ${p => p.marginTop || 0}px;
  margin-bottom: ${p => p.marginBottom == undefined ? 30 : p.marginBottom}px;
  color: ${p => p.color || '#000'};
  font-family: ${p => p.fontFamily || 'Montserrat'};
  font-weight: 400;
  line-height: 2;
  font-size: ${p => p.fontSize || 16}px;
  line-height: 25px;
  @media (max-width: 480px) {
    font-size: ${p => p.fontSize || 16}px;
  }
`;

export default P;