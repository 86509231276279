import styled from "styled-components";
import { metrics } from "../../themes";

const H2 = styled.h2`
  font-size: ${p => p.size || metrics.H2}px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  margin: 0;
  margin-bottom: ${metrics.baseUnit * 2}px;
  font-weight: ${p => p.weight || 500};
  position: relative;
  color: ${p => p.color || '#4F4F4F'};
  text-align: ${p => p.center ? 'center' : 'left'};
  @media (max-width: 480px) {
    font-size: ${metrics.H2}px;
  }
`;

export default H2;