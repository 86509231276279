import React, {useState, useRef, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { 
  Grid, Button, FormLabel, Input, Radio, RadioGroup, Typography, IconButton,
  FormControl, FormControlLabel, Select, MenuItem, OutlinedInput,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle as MuiDialogTitle
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Close as CloseIcon } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap'
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';


import InfoIcon from '@material-ui/icons/Info';
import * as firebase from "firebase/app";
import { P } from '../../containers';
import "../../firebase";
import XylaButton from "../XylaButton/XylaButton";
import { contentClient, CONTENT } from "../../data/contentful";
import "./style.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: 'lightgrey',
  },
  tooltip: {
    borderColor: 'lightgrey',
    borderWidth: 1,
    borderStyle: 'solid',
    color: theme.palette.common.black,
    fontSize: 12.5,
    backgroundColor: theme.palette.common.white
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useButtonStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    marginLeft: 0,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonDone: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: '-12px !important',
  },
}));

export default function FriendshipModal(props) {
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  // const [unsubscribeSnapshot, setUnsubscribeSnapshot] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [connection, setConnection] = useState({});
  const [connectionList, setConnectionList] = useState([]);
  const [contentfulValues, setContentfulValues] = useState({ page: {}, modal: {} });
  const [isValidForm, setValidForm] = useState(false);

  const [email, setEmail] = useState('');
  const [relation, setRelation] = useState('');

  const btnClasses = useButtonStyles();
  const buttonClassname = clsx({
    [btnClasses.buttonSuccess]: confirmed,
  });

  const location = useLocation();

  const db = firebase.firestore();
  const currentEmail = firebase.auth().currentUser?.email;
  const currentUID = firebase.auth().currentUser?.uid;

  // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const checkIfViewed = () => {
    const path = location.pathname;
    return (path.includes('xylagraph') || path.includes('compare'));
  }

  // useEffect(() => {
  //   setOpen(props.open);
  // }, [props.open])

  useEffect(() => {
    async function fetchData() {
      const { fields: modalFields } = await contentClient.getEntry(CONTENT.INVITE_TO_COMPARE_MODULE.id);
      const { fields: pageFields } = await contentClient.getEntry(CONTENT.FRIENDSHIP_REPORTING_MODULE.id);
      setContentfulValues({ page: {
        ...pageFields,
        intro_text_begin: pageFields.introTextBegin.content[0].content[0].value,
        intro_text_end: pageFields.introTextEnd.content[0].content[0].value,
        info_tooltip: pageFields.infoTooltipCopy.content[0].content[0].value,
      }, modal: modalFields });
    }
    fetchData();
  }, []);
  
  useEffect(() => {
    if (!checkIfViewed()) {
      return;
    }
    fetchFriendships();
  }, [location.pathname]);

  useEffect(() => {
    if (relation) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [relation]);

  const fetchFriendships = () => {
    getCurrentUserData()
    .then(() => fetchCancelledTime())    
    .then(date => {
      let fetched = false;
      if (!date) fetched = true;
      else {
        let now = new Date().getTime();
        // 24 hours
        // let diff = (now - date) / 1000 / 60 / 60 / 24;

        // 5 mins
        let diff = (now - date) / 1000 / 60;
        if (diff >= 5) fetched = true;
      }

      if (!fetched) {
        return;
      }

      const docRef = db.collection("connections")
        // .orderBy('receiver_activated', 'asc')
        // .where('receiver_activated', '!=', 'true')
        .where('receiver_email', '==', currentEmail)
        .orderBy('timestamp_computer', 'desc')
      
      let unsubscribeSnapshot = docRef.onSnapshot(snapshot => {
        if (updating) return;
        var connectionList = snapshot.docs.map(doc => doc.data()).filter(x => x.inviteType == 'share' && x.receiver_activated !== 'true');
        console.log('connectionList', connectionList.reverse())
        if (connectionList.length > 0) {
          setConnectionList(connectionList);
          setConnection(connectionList[currentIndex]);
          setOpen(true);
          unsubscribeSnapshot();
        }
      });
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const makeFriendship = async () => {
    if (connection.receiver == 'not_yet_generated') {
      let id = connection.sender + '_' + currentEmail;
      await db.collection('connections').doc(id).delete();
    }
    const documentIdentifier = connection.sender + '_' + currentUID;
    const docReffriend = db.collection("connections").doc(documentIdentifier);
    const data = {
      ...connection,
      uid_group: [ connection.sender, currentUID],
      receiver: currentUID,
      receiver_relation: relation,
      receiver_activated: 'true',
      status_receiver: 'Approved',
    };
    await sleep(2000);

    docReffriend.set(data, { merge: true })
    .then(() => {
      setConfirmed(true);
      setUpdating(false);
      setTimeout(() => {
        setConfirmed(false);
        let index = currentIndex;
        index++;
        makeCancelled(null);
        if (connectionList[index]) {
          setCurrentIndex(index)
          setConnection(connectionList[index]);
        } else {
          setOpen(false);
        }
        cleanForm();
      }, 3000);
    })
  };

  const getCurrentUserData = () => {
    const docRef = db.collection("users").doc(firebase.auth().currentUser.uid);
    return docRef.get()
    .then((doc) => {
      if (doc.exists) {
        let data = doc.data();
        // if (data.alt_invite_sent) {
        if (data) {
          return Promise.resolve(true)
        }
        return Promise.reject(null);
      }
    })
  }

  const makeCancelled = (time) => {
    const docRef = db.collection("searchUsers").doc(currentEmail);
    docRef.set({
      friendship_cancelled_at: time
    },{ merge: true });
  }

  const fetchCancelledTime = () => {
    const docRef = db.doc(`searchUsers/${currentEmail}`);
    return new Promise((resolve, reject) => {
      docRef.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          resolve(data.friendship_cancelled_at);
        }
        reject(null);
      });
    })
  }

  const cleanForm = () => {
    setRelation('');
  }

  const handleClose = (clickedX) => {
    if (!clickedX) return;
    // Implement logic for hitting X
    if (clickedX) makeCancelled(new Date().getTime());
    setOpen(false);
    cleanForm();
    if (props.onClose) props.onClose();
  };

  const handleRelation = value => {
    setRelation(value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!updating && !confirmed) {
      setUpdating(true);
      makeFriendship();
    }
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      if (callback) callback();
    }
  };

  if (!checkIfViewed()) {
    return <div></div>
  }

  return (
    <Dialog className="friendship-modal" open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" onClose={() => handleClose(true)}>{contentfulValues.page.headline1}</DialogTitle>
      <DialogContent id="form-dialog-content">
        <Form id="invite-form" onSubmit={handleSubmit} onKeyDown={e => { handleKeyDown(e) }}>
          <Form.Group>
            <FormLabel className="friendship-intro">{contentfulValues.page.intro_text_begin || ''} <a className="reporter-email">{connection.sender_email || ''}</a> {contentfulValues.page.intro_text_end || ''}</FormLabel>
            <FormLabel>{contentfulValues.modal.friendshipQuestion || ''}</FormLabel>
            <FormControl>
              <Select
                value={relation}
                onChange={e => handleRelation(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {(contentfulValues.modal.friendshipTypes || []).map(x => 
                  <MenuItem className="friend-option" dense={true} value={x}>{x}</MenuItem>
                  )}
              </Select>
            </FormControl>
          </Form.Group>

          <div className="dialog-action friendship-action">
            <div className={btnClasses.wrapper}>
              <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                disabled={!isValidForm} 
                type="submit"
              >
                {confirmed 
                  ? (
                    <IconButton>
                      <DoneIcon size={24} className={btnClasses.buttonDone} />
                    </IconButton>
                  )
                  :updating
                    ? (
                      <IconButton>
                        <CircularProgress size={24} className={btnClasses.buttonProgress} />
                      </IconButton>
                    )
                    : 'Submit'}
              </Button>
            </div>
              <BootstrapTooltip 
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={tooltipOpened}
                title={contentfulValues.page.info_tooltip}>
                <InfoIcon onClick={() => setTooltipOpened(!tooltipOpened)}/>
              </BootstrapTooltip>
          </div>
          
          {confirmed ? 
            <div className="friendship-confirm">
              <Alert icon={false} severity="success">Got it!</Alert>
            </div>
          : null}
        </Form>

      </DialogContent>
    </Dialog>

  );
}