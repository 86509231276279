import React, { Component, useState } from "react";
import Button from '@material-ui/core/Button';
import EmptyState from "../EmptyState";
import * as firebase from "firebase/app";
import "../../firebase";
import { isEmpty } from "validate.js";

import Spinner from 'react-spinner-material';

import AdminDataLoad from "./AdminDataLoad";

import AdminTable1 from "./AdminTable2";

class AdminPage extends Component {

	constructor(props) {
	  super();
	    console.log('This happens 1st.');
	  this.state = {
      loading: 'initial',
      data: ''
	  };
  
	}
    

loadData() {
		var promise = new Promise((resolve, reject) => { 
		  AdminDataLoad();
		  setTimeout(() => {
			console.log('This happens 6th (after 3 seconds).');
			resolve('This is my data.');
		  }, 1000);
		});
		console.log('This happens 4th.');
		return promise;
	  }


componentDidMount() {
      console.log('This happens 3rd.');
      this.setState({ loading: 'true' });
      this.loadData()
      .then((data) => {
        console.log('This happens 7th.');
        this.setState({
            data: data,
            loading: 'complete'
          });
      });
      } 


  
  render() {


    if (localStorage.getItem("xylaadminstatus") == 'yes' && this.state.loading === 'initial') {
			console.log('This happens 2nd - after the class is constructed. You will not see this element because React is still computing changes to the DOM.');
			return <h2>Intializing...</h2>;
		  }

		if (localStorage.getItem("xylaadminstatus") == 'yes' && this.state.loading === 'true') {
        console.log('This happens 5th - when waiting for data.');
        return (
          <div>
            {/* <Spinner radius={300} color={"#97769E"} stroke={10} visible={true} /> */}
          </div>
        )
        }

    if (localStorage.getItem("xylaadminstatus") == 'yes' && this.state.loading === 'complete') {
          console.log('Things loaded.');
          return (
            <div>
              <h3>  </h3>
              <AdminTable1 />
            </div>


          )



          

          }
    

    else return <div>
        <h1> yo dog sorry</h1>
      </div>




  }

}

export default AdminPage;
