import React, { useEffect, useState } from 'react';
import {
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import { contentClient, CONTENT } from "../../data/contentful";
import XylaAddFriend from "../xylaAddFriend/xylaAddFriend2";

import "./style.css"

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    padding: 5,
    color: 'white'
  },
}));

const GlobalBanner = (props) => {
  const [contentfulValues, setContentfulValues] = useState({});
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      const entry = await contentClient.getEntry(CONTENT.PROMO_BANNER.id);
      setContentfulValues(entry.fields);
      console.log('contentful response: ', entry)
    }
    fetchData();
  }, []);

  const onClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <div className="global-banner">
      <p style={{width: '80%', margin:'auto'}}>
        <span>{contentfulValues.globalnav1 || ''} </span> 
        <span><XylaAddFriend Button={<span className="link-launch">{contentfulValues.globalnav2 || ''}</span>} /></span>
        <span> {contentfulValues.globalnav3 || ''}</span>
      </p>
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  )
};

export default GlobalBanner;