
export const SetLocalState = (key, value) => window.localStorage.setItem(key, value);

export const GetLocalState = key => window.localStorage.getItem(key);

export const RemoveLocalState = key => window.localStorage.removeItem(key);

export const GetJSONLocalState = key => JSON.parse(GetLocalState(key));

export const SetShowBannerState = value => SetLocalState('GLOBAL_BANNER', value);

export const GetShowBannerState = () => GetLocalState('GLOBAL_BANNER');

export const SetSurveyState = value => SetLocalState('SURVEY_COMPLETE', value);

export const GetSurveyState = () => GetLocalState('SURVEY_COMPLETE');