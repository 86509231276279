// import noUiSlider from 'nouislider';
// import 'nouislider/distribute/nouislider.css';


// https://plnkr.co/edit/LBGcb6xZt7mUQ5FP?preview


function init(Survey) {

    var widget = {
      //the widget name. It should be unique and written in lowcase.
      name: "bipolarslider",
      //the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
      title: "Bipolar slider",
      //the name of the icon on the toolbox. We will leave it empty to use the standard one
      iconName: "",
      //SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
      isFit: function (question) {
          //we return true if the type of question is textwithbutton
          return question.getType() === 'bipolarslider';
          //the following code will activate the widget for a text question with inputType equals to date
          //return question.getType() === 'text' && question.inputType === "date";
      },
      //Use this function to create a new class or add new properties or remove unneeded properties from your widget
      //activatedBy tells how your widget has been activated by: property, type or customType
      //property - it means that it will activated if a property of the existing question type is set to particular value, for example inputType = "date" 
      //type - you are changing the behaviour of entire question type. For example render radiogroup question differently, have a fancy radio buttons
      //customType - you are creating a new type, like in our example "textwithbutton"
      activatedByChanged: function (activatedBy) {
          //we do not need to check acticatedBy parameter, since we will use our widget for customType only
          //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
          Survey.JsonObject.metaData.addClass("bipolarslider", [], null, "text");
    
          //Add new property(s)
          //For more information go to https://surveyjs.io/Examples/Builder/?id=addproperties#content-docs
          Survey.JsonObject.metaData.addProperties("bipolarslider", [
              { name: "leftText", default: "Text to the left" }
          ]);
          Survey.JsonObject.metaData.addProperties("bipolarslider", [
              { name: "rightText", default: "Text to the right" }
          ]);
    
          // Defines the min value for the range slider
          Survey.JsonObject.metaData.addProperties("bipolarslider", [
              { name: "min", default: 0 }
          ]);
    
          // Defines the max value for the range slider
          Survey.JsonObject.metaData.addProperties("bipolarslider", [
              { name: "max", default: 100 }
          ]);
    
          // Defines the steps for the range slider
          Survey.JsonObject.metaData.addProperties("bipolarslider", [
              { name: "step", default: .01 }
          ]);
      },
      //If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our htmlTemplate
      isDefaultRender: false,
      //You should use it if your set the isDefaultRender to false
      //htmlTemplate: "<div><input /><button></button></div>",
      htmlTemplate: " <div class='sjs_right'>    <span class=' sjs_bipolarslider_text sjs_bipolarslider_right_text'></span>  </div>     <div class='sjs_bipolarslider_container'>   <input class='sjs_bipolarslider_input' type='range'/>   </div>       <div class='sjs_left'> <span class='sjs_bipolarslider_text sjs_bipolarslider_left_text '></span>   </div>    ",
      //The main function, rendering and two-way binding
      afterRender: function (question, el) {
          var slider = el.getElementsByTagName("input")[0];
          // Set the minimum and maximum of the slider
          slider.setAttribute('min', question.min);
          slider.setAttribute('max', question.max);
          slider.setAttribute('step', question.step);
    
          // Set the caption for left and right
          var left = el.getElementsByTagName("span")[0];
          left.innerHTML = question.leftText;
          var right = el.getElementsByTagName("span")[1];
          right.innerHTML = question.rightText;
    
          //set the changed value into question value
          slider.onchange = function () {
              question.value = slider.value;
          }
    
          this.onValueChangedCallback = function () {
            slider.value = question.value ? question.value : question.defaultValue;
          }
    
          this.onReadOnlyChangedCallback = function() {
            if (question.isReadOnly) {
              slider.setAttribute('disabled', 'disabled');
            } else {
              slider.removeAttribute("disabled");
            }
          };
          
          question.readOnlyChangedCallback = this.onReadOnlyChangedCallback;
          question.valueChangedCallback = this.onValueChangedCallback;
          
          //set initial value
          this.onValueChangedCallback();
    
          //make elements disabled if needed
          this.onReadOnlyChangedCallback();
          if (!question.value) {
            setTimeout(() => {
              question.value = question.defaultValue;
            }, 10)
          }
        //   question.value = question.defaultValue;
      },
      //Use it to destroy the widget. It is typically needed by jQuery widgets
      willUnmount: function (question, el) {
      }
    }
    
    
        // Adds Custom Widget
        Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
        }
        // if (typeof Survey !== "undefined") {
    
        // init(Survey);
    
        // }
        
        export default init;
    
    
    