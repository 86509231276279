import React, { createContext, useState } from "react";

export const XylaContext = createContext();

// https://scotch.io/courses/10-react-challenges-beginner/use-context-to-pass-data
// This context provider is passed to any component requiring the context

export const XylaProvider = ({ children }) => {
  const [s1, sets1] = useState("5");
  const [s2, sets2] = useState("5");
  const [s3, sets3] = useState("5");
  const [s4, sets4] = useState("5");
  const [s5, sets5] = useState("5");
  const [s6, sets6] = useState("5");
  const [s7, sets7] = useState("5");
  const [s8, sets8] = useState("5");
  const [s9, sets9] = useState("5");
  const [s10, sets10] = useState("5");
  const [s11, sets11] = useState("5");
  const [s12, sets12] = useState("5");
  
  const [resultsingle, setresultsingle] = useState(
        [
          [
            { "axis": "Xyla Category S1", "value": 0, "category": "Feeling to Thinking" },
            { "axis": "Xyla Category S2", "value": 0, "category": "Friendship Roots: "},
            { "axis": "Xyla Category S3", "value": 0, "category": "Friendship Roots: "},
            { "axis": "Xyla Category S4", "value": 0, "category": "Friendship Roots: "},
            { "axis": "Xyla Category S5", "value": 0, "category": "Friendship Roots: "},
            { "axis": "Xyla Category S6", "value": 0, "category": "Friendship Roots: "},
            { "axis": "Xyla Category S7", "value": 0, "category": "Friendship Styles: "},
            { "axis": "Xyla Category S8", "value": 0, "category": "Friendship Styles: "},
            { "axis": "Xyla Category S9", "value": 0,"category": "Friendship Styles: "},
            { "axis": "Xyla Category S10", "value": 0, "category": "Friendship Styles: "},
            { "axis": "Xyla Category S11", "value": 0, "category": "Friendship Styles: "},
            { "axis": "Xyla Category S12", "value": 0, "category": "Friendship Styles: "}
          ]
        ]
      );
            
  const [resultscombined, setresultscombined] = useState(
    [
      [
        { "axis": "Xyla Category S1", "value": 5, "category": "Feeling to Thinking" },
        { "axis": "Xyla Category S2", "value": 5, "category": "Friendship Roots: "},
        { "axis": "Xyla Category S3", "value": 5, "category": "Friendship Roots: "},
        { "axis": "Xyla Category S4", "value": 5, "category": "Friendship Roots: "},
        { "axis": "Xyla Category S5", "value": 5, "category": "Friendship Roots: "},
        { "axis": "Xyla Category S6", "value": 5, "category": "Friendship Roots: "},
        { "axis": "Xyla Category S7", "value": 5, "category": "Friendship Styles: "},
        { "axis": "Xyla Category S8", "value": 5, "category": "Friendship Styles: "},
        { "axis": "Xyla Category S9", "value": 5,"category": "Friendship Styles: "},
        { "axis": "Xyla Category S10", "value": 5, "category": "Friendship Styles: "},
        { "axis": "Xyla Category S11", "value": 5, "category": "Friendship Styles: "},
        { "axis": "Xyla Category S12", "value": 5, "category": "Friendship Styles: "}
      ]
    ]
  );


  const [xylacolor, setxylacolor] = useState("#");

  const [xylacolor2, setxylacolor2] = useState(
    [
      [
        { "color": "#"}        
      ]
    ]
  )

  const [comparisonTraining, setcomparisonTraining] = useState("");

  const [mailboxiconStatus, setmailboxiconStatus] = useState("no");


  return (
    <XylaContext.Provider
      value={{
        s1,
        s2,
        s3,
        s4,
        s5,
        s6,
        s7,
        s8,
        s9,
        s10,
        s11,
        s12,
        xylacolor,
        xylacolor2, 
        resultsingle,
        resultscombined,
        comparisonTraining,
        mailboxiconStatus,
        sets1,
        sets2,
        sets3,
        sets4,
        sets5,
        sets6,
        sets7,
        sets8,
        sets9,
        sets10,
        sets11,
        sets12,
        setxylacolor,
        setxylacolor2, 
        setresultsingle,
        setresultscombined,
        setcomparisonTraining,
        setmailboxiconStatus,
      }}
    >
      {children}
    </XylaContext.Provider>
  );
};