import React, { useState, useEffect, useContext, useRef } from 'react';

import * as firebase from "firebase/app";
import "../../firebase";

import { AddCircleOutlineRounded, DeleteOutlineRounded, Edit } from '@material-ui/icons';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Button, Chip, Avatar, TextField, RadioGroup, Container, IconButton, List, Tooltip, ListItem, ListItemSecondaryAction, ListItemText, Dialog, DialogContent, DialogActions } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//these two tables below are the big ones -> originally tried mui-datatable, looks like material-table
//has more support
import MaterialTable from "material-table";
import { MaterialTableProps } from "material-table";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { forwardRef } from 'react';
import RowData from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit2 from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LastPage from '@material-ui/icons/LastPage';
import Lock from "@material-ui/icons/Lock";
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import DeleteIcon from '@material-ui/icons/Delete';

import { XylaContext } from '../../contexts/xylaContext';




const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit2 {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js
// https://stackoverflow.com/questions/62596261/cant-turn-on-switch-inside-material-table
// NEED TO CHECK OUT COMPONENT OVERRIDING -> https://material-table.com/#/docs/features/component-overriding 
//  https://medium.com/@domino051/cascading-dropdown-material-table-e9025a424715
// https://stackoverflow.com/questions/61868262/getting-async-data-in-a-react-material-table-component
// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js
// 



export default function FriendTable() {



    const [todos, setTodos] = useState([]);
    const [input, setInput] = useState('');
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState('');
    const [toUpdateId, setToUpdateId] = useState('');
    const [data, setData] = useState([]);
    const [mailactionRequired, setmailactionRequired] = useState([]);


    const xylacontextdata = useContext(XylaContext);



    //for error handling
    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    const db = firebase.firestore();

    const currentUID = firebase.auth().currentUser.uid;
    console.log('senderUID :', currentUID)



    const loadMailboxstatus = () => {
      const db = firebase.firestore();
      const currentUID = firebase.auth().currentUser.uid;
      console.log('userID: ', currentUID)
      var docRef = db.collection("users").doc(currentUID);
      docRef.get().then((doc) => {
          if (doc.exists) {
              setmailactionRequired(doc.data().friendactionRequired);
              xylacontextdata.setmailboxiconStatus(doc.data().friendactionRequired);
              console.log('testing - permission mailicon reload VALUE STATUS: ', doc.data().friendactionRequired)
          } else {
              console.log('testing - permission mailicon reload - nothing there')
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
      console.log('testing - permission mailicon reload FINISHED')
    }




    const loadDatafunction = () => {
      console.log('useEffect Hook!!!');

      // https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search
      // https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search
      // const senderUID = firebase.auth().currentUser.uid;

      //previous: receiver, '=='





      
      db.collection('friends').where("uid_group", 'array-contains', currentUID).orderBy('timestamp_computer', 'desc').onSnapshot(snapshot => {
      
        console.log('Firebase Snap!');
      
      // setTodos(snapshot.docs.map(doc => {
      //     return {
      //     id: doc.id,
      //     receiver: doc.data().receiver,
      //     receiver_email: doc.data().receiver_email,
      //     sender: doc.data().sender,
      //     sender_email: doc.data().sender_email,
      //     status: doc.data().status,
      //     datatime: doc.data().timestamp_human
      //     }
      // }))

      const friendList = snapshot.docs.map(doc => {
        return {
            id: doc.id,
            receiver: doc.data().receiver,
            receiver_email: doc.data().receiver_email,
            sender: doc.data().sender,
            sender_email: doc.data().sender_email,
            status: doc.data().status,
            datatime: doc.data().timestamp_human,
            uid_group: doc.data().uid_group,
                } 
      })

      console.log('friendList: ',friendList)

      const receiver_list = friendList.map(element => [element.id, element.receiver_email, element.sender, element.status, element.sender_email]);
      const receiver_list_mod = receiver_list.map(function(item) {
          return { id: item[0], friendlistEmail: item[1], sender: item[2], status: item[3], sender_email: item[4] };
      })

      const sender_list = friendList.map(element => [element.id, element.sender_email,  element.sender, element.status, element.sender_email]);
      const sender_list_mod = sender_list.map(function(item) {
          return { id: item[0], friendlistEmail: item[1], sender: item[2], status: item[3], sender_email: item[4] };
      })

      const friend_list_clean = receiver_list_mod.concat(sender_list_mod);  
              
      let ids = friend_list_clean.map(o => o.friendlistEmail)
      let friend_list_clean2 = friend_list_clean.filter(({friendlistEmail}, index) => !ids.includes(friendlistEmail, index + 1))

      const currentEmail = firebase.auth().currentUser.email;

      const friend_list_clean3 = friend_list_clean2.filter(element => element.friendlistEmail != currentEmail);

      const friend_list_clean4 = friend_list_clean3

      console.log('friend_list_clean3: ',friend_list_clean3)

      var length = friend_list_clean4.length;   
      for (var i = 0; i < length; i++) {
        if (friend_list_clean4[i].sender_email === firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Pending') {
          friend_list_clean4[i].displayField = 'Waiting on friend';
          friend_list_clean4[i].displayField2 = 'Waiting on friend';
        } else if (friend_list_clean4[i].sender_email != firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Pending') {
          friend_list_clean4[i].displayField = 'Approve / Ignore';
          friend_list_clean4[i].displayField2 = 'Approved';
        } else if (friend_list_clean4[i].status === 'Approved') {
          friend_list_clean4[i].displayField = 'No action required';
          friend_list_clean4[i].displayField2 = 'Approvied';
        } else if (friend_list_clean4[i].status === 'Denied') {
          friend_list_clean4[i].displayField = 'Waiting on friend';
          friend_list_clean4[i].displayField2 = 'Denied';
        } else if (friend_list_clean4[i].sender_email != firebase.auth().currentUser.email && friend_list_clean4[i].status === 'Ignore') {
          friend_list_clean4[i].displayField = 'Ignore';
          friend_list_clean4[i].displayField2 = 'Ignore';
        } else if (friend_list_clean4[i].status === 'Ignore') {
        friend_list_clean4[i].displayField = 'Waiting on friend';
        friend_list_clean4[i].displayField2 = 'Ignore';
      } 
      }


      

      
      console.log('friend_list_clean4: ', friend_list_clean4);

      // SETTING UP THE STATE TO SHOW IF THE RED ICON OVER MAIL BOX SHOULD BE DISPLAYED 
      if (friend_list_clean4.filter(function(e) { return e.displayField === 'Approvide / Decline'; }).length > 0) {
        console.log('Mailbox Icon: it contains a pending value that should display the red icon over mail box!')
        db.collection('users').doc(currentUID).update({
          friendactionRequired: 'yes'
        });
      } else {
        console.log('Mailbox Icon: it does not contain a value that warrants the icon to be shown!')
        db.collection('users').doc(currentUID).update({
          friendactionRequired: 'no'
        });
      }



      setTodos(friend_list_clean4)
      setData(friend_list_clean4)

      // setTodos(todos => [...todos, friend_list_clean4])  
      // setData(data => [...data, friend_list_clean4])  
      })





      //PENDING FRIENDS - friends that are not yet users / but the request was sent 
      db.collection('friends_future').where("uid_group", 'array-contains', currentUID)
        .where("status", '==', "Pending").where("receiver_activated", '==', "false")
        .orderBy('timestamp_computer', 'desc').onSnapshot(snapshot => {
        console.log('Firebase Snap 1232132132!');
        const futurefriendList = snapshot.docs.map(doc => {
          return {
              id: doc.id,
              receiver: doc.data().receiver,
              receiver_email: doc.data().receiver_email,
              receiver_activated: doc.data().receiver_activated,
              sender: doc.data().sender,
              sender_email: doc.data().sender_email,
              status: doc.data().status,
              datatime: doc.data().timestamp_human,
              uid_group: doc.data().uid_group,
                  } 
        })
        console.log('sdfdsf345rsdfgsdfd: ',futurefriendList)

        const receiver_listfuture = futurefriendList.map(element => [element.id, element.receiver_email, element.sender, element.status, element.sender_email]);
        const receiver_list_modfuture = receiver_listfuture.map(function(item) {
            return { id: item[0], friendlistEmail: item[1], sender: item[2], status: item[3], sender_email: item[4] };
        })

        const sender_listfuture = futurefriendList.map(element => [element.id, element.sender_email,  element.sender, element.status, element.sender_email]);
        const sender_list_modfuture = sender_listfuture.map(function(item) {
            return { id: item[0], friendlistEmail: item[1], sender: item[2], status: item[3], sender_email: item[4] };
        })

        const friend_list_cleanfuture = receiver_list_modfuture.concat(sender_list_modfuture);  
                
        let ids_future = friend_list_cleanfuture.map(o => o.friendlistEmail)
        let friend_list_clean2future = friend_list_cleanfuture.filter(({friendlistEmail}, index) => !ids_future.includes(friendlistEmail, index + 1))

        const currentEmailfuture = firebase.auth().currentUser.email;

        const friend_list_clean3future = friend_list_clean2future.filter(element => element.friendlistEmail != currentEmailfuture);

        const friend_list_clean4future = friend_list_clean3future

        console.log('friend_list_clean3future: ',friend_list_clean3future)

        var length = friend_list_clean4future.length;   
        
        for (var i = 0; i < length; i++) {
          if (friend_list_clean4future[i].sender_email === firebase.auth().currentUser.email && friend_list_clean4future[i].status === 'Pending') {
            friend_list_clean4future[i].displayField = 'Waiting on friend';
          } else if (friend_list_clean4future[i].sender_email != firebase.auth().currentUser.email && friend_list_clean4future[i].status === 'Pending') {
            friend_list_clean4future[i].displayField = 'Approve / Ignore';
          } else if (friend_list_clean4future[i].status === 'Approved') {
            friend_list_clean4future[i].displayField = 'No action required';
          } else if (friend_list_clean4future[i].status === 'Denied') {
            friend_list_clean4future[i].displayField = 'Waiting on friend';
          } else if (friend_list_clean4future[i].sender_email != firebase.auth().currentUser.email && friend_list_clean4future[i].status === 'Ignore') {
            friend_list_clean4future[i].displayField = 'Ignore';
            friend_list_clean4future[i].displayField2 = 'Ignore';
          } else if (friend_list_clean4future[i].status === 'Ignore') {
            friend_list_clean4future[i].displayField = 'Waiting on friend';
          } 
        }


    
        



        console.log('friend_list_clean4future: ', friend_list_clean4future);

        // setTodos(friend_list_clean4future)
        // setData(friend_list_clean4future)

        setTodos(todos => [...todos, ...friend_list_clean4future])  
        setData(data => [...data, ...friend_list_clean4future])  



        loadMailboxstatus();







      })

    }





    useEffect(() => {

        //scroll to top of page
        window.scrollTo(0, 0); 

        loadDatafunction();
    }, []);




    
    const deleteTodo = (id) => {
        db.collection('friends').doc(id).delete().then(res => {
          console.log('Deleted!', res);
        });
      }
    
    const openUpdateDialog = (todo) => {
        setOpen(true);
        setToUpdateId(todo.id);
        setUpdate(todo.status);
      }

    const openUpdateDialog2 = (id, status) => {
        setOpen(true);
        setToUpdateId(id);
        setUpdate(status);
      }

    const editTodo = () => {
        db.collection('friends').doc(toUpdateId).update({
          status: update
        });
        setOpen(false);
      }

    
    const handleClose = () => {
        setOpen(false);
      };

    
 
    const dyanmicLookup = {
      
    }


    //   const columns = ["id", "sender"];

      const columns_pending = [
 
        {
            title: "Friend's Email",
            field: "friendlistEmail",
            editable: 'never',
            render: data => (
              <Chip
                label={data.friendlistEmail}
                // color="primary"
                color='default' 
                style={{backgroundColor:'#EFEFEF'}}
                avatar={<Avatar  />}
              />
            )
        },

      //   {
      //     title: "Requester",
      //     field: "sender",
      //     editable: 'never',
      //     render: data => (
      //       <Chip
      //         label={data.sender_email}
      //         // color="primary"
      //         color='default' 
      //         style={{backgroundColor:'#EFEFEF'}}
      //         avatar={<Avatar  />}
      //       />
      //     )
      // },

      {
        title: "Action Required?",
        field: "displayField",
        editable: 'never',
        render: data => (
          <Chip
            label={data.displayField}
            // color="primary"
            color='default' 
            style={ data.status === 'Approved' ? {backgroundColor: '#78e08f'} : data.displayField === 'Approve / Ignore' ? {backgroundColor: '#E8E8E8', color: 'red', fontStyle: 'italic'} : {backgroundColor: '#f7d794'}}
            // style={{backgroundColor:'#EFEFEF'}}
            // avatar={<Avatar  />}
          />
        )
      },


      {
        title: "Status",
        field: "status",
        defaultSort: "desc",
        lookup: { 'Approved': 'Approved', 'Ignore' : 'Ignore', 'Pending': 'Pending' },
        
        // lookup: (e, rowData) => {
        //   if (rowData.displayField === 'Waiting on you') {
        //     return {'Approved': 'Approved', 'Denied': 'Denied', 'Pending': 'Pending'};
        //   } else {
        //     return {'Approved': 'Approved', 'Denied': 'Denied', 'Pending': 'Pending'};
        //   } ; 
        // },

        // cellStyle: {color: "red"}

        cellStyle: (e, rowData) => {
          if (rowData.status == 'Pending' && rowData.displayField == 'Approve / Ignore') {
            return { color: "red", fontStyle: 'italic' };
          }
        },
      },





       ]

    //   const data = todos


      
      
      
      const options = {
        filterType: 'checkbox',
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
      };




      console.log('consolelogcheck', data)

    
    return (


        <div>


          <Container maxWidth="md">

          {/*     
                    <Dialog open={open} onClose={handleClose}>
                      <DialogContent>
                        <Select
                          autoFocus
                          margin="normal"
                          label="Update Todo"
                          type="text"
                          fullWidth
                          name="updateTodo"
                          value={update}
                          onChange={event => setUpdate(event.target.value)}
                        >
                            <MenuItem value='Approved'>Approved - They CAN view my Xyla</MenuItem>
                            <MenuItem value='Denied'>Denied - They can NOT view my Xyla right now</MenuItem>
                        </Select>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={editTodo} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog> */}
              
              
                      <br />  <br /> <br /> <br /> <br /> <br /> 


                    {/* THIS VERSION BELOW WORKS - DATA WITHOUT MAPPING/WRAPPING  */}

                    {/* <MUIDataTable 
                      title={"Friend List"} 
                      data={todos.map(todo => {
                          return [
                              todo.id,
                              todo.status,
                          ]
                        })}
                      columns={columns} 
                      options={options} 
                      /> */}

                      <MaterialTable
                      title=" "
                      icons={tableIcons}
                      
                      // columns= { rowData => rowData.status === 'Approved' ? (columns_pending) : (columns_notpending) }
                      columns= {columns_pending}
                      

                      localization={{
                          pagination: {
                          },
                          toolbar: {
                          },
                          header: {
                            actions: 'Actions'
                          },
                          body: {
                            editRow: { deleteText: 'Are you sure?'}
                          }
                      }}

                      data={data}

                      // actions={[
                      //   {
                      //     icon: 'save',
                      //     tooltip: 'edit stuff',
                      //   }
                      // ]}

                      editable={{
                          // onRowAdd: newData =>
                          //   new Promise((resolve, reject) => {
                          //     setTimeout(() => {
                          //       setData([...data, newData]);
                          //       resolve();
                          //     }, 1000)
                          //   }),
                          // oldData.tableData.id === currentUID ? : undefined,  
                          


                          isEditable: rowData => (rowData.sender != currentUID),
                          isDeletable: rowData => (rowData.sender === currentUID),

                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              // setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData; // this is the updated rowID 
                                const statusUpdate = newData.status; // this is the updated status 
                                const idUpdate = newData.id;
                                console.log('newDatafieldupdate: ', statusUpdate)
                                setData([...dataUpdate]);

                                db.collection('friends').doc(idUpdate).update({
                                    status: statusUpdate
                                  });

                                resolve();
                              // }, 1000) 
                              
                            }),

                          onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {                      // check out this - potentially remove 
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                const firebaseIndex = oldData.id;
                                console.log('RowIDtoDelete: ', firebaseIndex)
                                
                                // NEED TO ADD IN THE BIT HERE NOW TO ACCOUNT FOR DELETING A PERSON THAT WENT THROUGH
                                // FRIEND REQUEST ROUTE -- e.g., function in referallID could potentially re-instate 
                                // request automatically 
                                // Need to come back here and look at the ReferralCapture.js code to bring over potentially
                                // lines between 26-40 - somewhere around there 
                                // debugger;
                                console.log('checking for stuff to happen')
                                
                                const hashId = oldData.tableData.id;
                                const hashId2 = oldData.id;
                                console.log({'hashId2': hashId2, 'hashId': hashId})
                                const numericIndex = dataDelete.findIndex( d => d.id === hashId2 );

                                dataDelete.splice(numericIndex, 1);

                                console.log('setData to delete', dataDelete)

                                setData([...dataDelete]);

                                // in transcaction -- also look for .delete() in other parts of the code as well 
                                db.collection('friends').doc(firebaseIndex).delete().then(res => {
                                  console.log('Deleted!', res);
                                });
                                
                                //
                                db.collection('friends_future').doc(firebaseIndex).delete().then(res => {
                                  console.log('Deleted!', res);
                                });
                                
                                resolve();

                         
                                
                              }, 1000)
                            }),
                        }}
                      />

                  </Container >




        </div>




      );



}    

