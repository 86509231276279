import * as firebase from "firebase/app";
import "../../firebase";
import jsonQuery from "json-query";


const AdminDataLoad = () => {



    
// https://googleapis.dev/nodejs/firestore/latest/QuerySnapshot.html


    // WORKING 
    // const db = firebase.firestore();
    // const query = db.collection("users");
    // query.get().then(querySnapshot => {
    //     console.log(`Found ${querySnapshot.size} documents.`);
    //     window.localStorage.setItem('User_Count', querySnapshot.size);
    //   })
    // };
    
    const db = firebase.firestore();
    const query = db.collection("users");
    
    const mynewfunction = () => {

        if (query != null) {
            
            console.log('data found')
            
            query.get().then(querySnapshot => {
                console.log(`Found ${querySnapshot.size} documents.`);
            })
           
        }
        else {
            console.log('no collection found / data error')
        }
    }

    mynewfunction();

}






    // const db = firebase.firestore();
    // const docRef = db.collection("users");
    // docRef.get().then((doc) => {
    //     if (doc.exists) {
    //         let data = doc.data();
    //         console.log("Document data:", data);
	// 		// var tempvar = jsonQuery('surveyscored', {data: data}).value
	// 		// const xylaS1 = ((JSON.parse(tempvar)).s1[0])/10			
	// 		window.localStorage.setItem('ExampleTest123', JSON.stringify(data));

			
    //     } else {
    //         console.log("No such document!");
    //     }
    // }).catch(function (error) {
    //     console.log("Error getting document:", error);
    // });  };

export default AdminDataLoad;