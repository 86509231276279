import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RadarChart from './RadarChart';
import './lib/styles/vizuly.css';

class VizulyRadarChart extends Component {
	static propTypes = {
		props: PropTypes.array,
		styles: PropTypes.array,
		events: PropTypes.array,
		watchers: PropTypes.array
	};
	
	constructor(props) {
		super(props);
		
		//Create a unique uid for the component used on root element id
		this.refId = 'react_xxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
			return v.toString(16);
		});
	}
	
	componentDidMount() {
		const el = document.getElementById(this.refId)
		this.viz = RadarChart(el);
		this.updateComponent();
	}
	
	componentDidUpdate() {
		this.updateComponent();
	}
	
	updateComponent() {
		var that = this;
		this.props.events.forEach(function (d) {
			var key = Object.keys(d)[0];
			that.viz.on(key, d[key]);
		})
		
		this.props.watchers.forEach(function (d) {
			var key = Object.keys(d)[0];
			that.viz.onChange(key, d[key]);
		})
		
		this.props.props.forEach(function (d) {
			var key = Object.keys(d)[0];
			that.viz[key](d[key]);
		})
		
		this.props.styles.forEach(function (d) {
			var key = Object.keys(d)[0];
			that.viz.style(key, d[key]);
		});

		this.viz.update();
	}
	
	componentWillUnmount() {
		if (this.viz && this.viz.destroy) {
			this.viz.destroy();
		}
	}
	
	render() {
		return (
		 <div id={this.refId} className={this.props.className}/>
		);
	}
}

VizulyRadarChart.defaultProps = {
	props: [{'width': 600}, {'height': 600}],
	styles: [],
	events: [],
	watchers: []
};

export default VizulyRadarChart;
